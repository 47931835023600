import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import SettingsTextInput from "../../Forms/SettingsTextInput";
import PhoneInput from "react-phone-number-input";
import ApplicationHeaderUC from "../ApplicationHeaderUC";
import SubmitButton from "../../Forms/SubmitButton";

export default function ApplicationFormUCStep1Short() {
    const [formDone, setFormDone] = useState(false);

    return (
        <div className="uc uc_short_bg px-8">
            <div className="relative">
                <header className="max-w-5xl mx-auto pt-10 px-2 lg:px-0">
                    <div className="md:flex items-end justify-between">
                        <div>
                            <img className="h-24 mb-4" src="/images/logo_uc_white.png" alt="Urban Circle" />
                            <div className="py-6 lg:py-0 md:w-96 text-white text-4xl uppercase font-bold mb-16">
                                <h1>Student Accommodation Simplified</h1>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-end justify-between">
                        <h2 className="bg-orange-500 rounded-full inline-block px-10 py-3 text-white">APPLY NOW</h2>

                        <div>
                            <a
                                target="_blank"
                                href="https://api.whatsapp.com/send?phone=270871632011"
                                className="h-12 rounded-full text-white px-4 flex justify-between items-center font-bold"
                                style={{ background: "#62D43F" }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="25.364" height="25.364" viewBox="0 0 25.364 25.364" className="mr-5">
                                    <path
                                        id="Icon_simple-whatsapp"
                                        data-name="Icon simple-whatsapp"
                                        d="M18.492,15.2c-.318-.159-1.867-.916-2.156-1.021s-.5-.159-.711.159-.815,1.019-1,1.228-.369.222-.683.079a8.577,8.577,0,0,1-2.54-1.569,9.588,9.588,0,0,1-1.754-2.188c-.184-.317-.02-.491.137-.65.144-.143.318-.365.477-.553a2.679,2.679,0,0,0,.314-.524.581.581,0,0,0-.026-.554c-.079-.159-.71-1.712-.974-2.331s-.515-.539-.71-.539c-.182-.016-.392-.016-.6-.016a1.191,1.191,0,0,0-.842.379,3.528,3.528,0,0,0-1.1,2.616,6.184,6.184,0,0,0,1.288,3.25A14.092,14.092,0,0,0,13,17.705c.755.317,1.342.507,1.8.665a4.372,4.372,0,0,0,1.987.128,3.252,3.252,0,0,0,2.131-1.507,2.616,2.616,0,0,0,.19-1.506c-.078-.143-.285-.222-.6-.365m-5.756,7.866H12.73a10.572,10.572,0,0,1-5.342-1.458l-.38-.226-3.963,1.03L4.106,18.48l-.253-.4A10.482,10.482,0,0,1,20.178,5.153a10.344,10.344,0,0,1,3.074,7.387,10.5,10.5,0,0,1-10.5,10.447M21.686,3.645A12.832,12.832,0,0,0,12.73,0,12.565,12.565,0,0,0,1.792,18.852L0,25.364l6.7-1.746a12.748,12.748,0,0,0,6.035,1.529h.006A12.619,12.619,0,0,0,25.364,12.575,12.465,12.465,0,0,0,21.67,3.686"
                                        fill="#fff"
                                    />
                                </svg>
                                <span className="mr-3">NEED HELP?</span>
                            </a>
                        </div>
                    </div>
                </header>
            </div>

            <main>
                <div className="mx-auto max-w-5xl py-6 rounded-lg">
                    <div className="bg-[#2da9a6] h-2 w-36 mb-4"></div>

                    {formDone == true ? (
                        <>
                            <p className="text-white mt-4 text-xl text-center">
                                Thank You!
                                <br />
                                Please check your Whatsapp for the next step.
                            </p>
                            <p className="text-white mt-4 font-bold text-xl text-center">You are one step closer to joining the inner circle!</p>
                        </>
                    ) : (
                        <Formik
                            initialValues={{ name: "", surname: "", email: "", cell: "", uc_short_msg: true }}
                            enableReinitialize
                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                setSubmitting(true);
                                axios({
                                    method: "post",
                                    url: `${process.env.REACT_APP_API_URL}uc_step1_short`,
                                    data: values,
                                })
                                    .then((response) => {
                                        alert(response.data.message);
                                        if (response.data == "User already exists" || response.data == "The email has already been taken.") {
                                            setErrors({ cell: "User already exists, please contact support." });
                                        } else {
                                            setFormDone(true);
                                        }
                                        setSubmitting(false);
                                    })
                                    .catch((error) => {
                                        setErrors({ cell: "User already exists, please contact support." });
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                <Form onSubmit={handleSubmit}>
                                    <p className="mb-8 text-white text-2xl font-bold">Please fill in your details</p>
                                    <div className="md:grid grid-cols-2 gap-4">
                                        <div>
                                            <label for="email" class="text-sm font-medium text-white">
                                                Name
                                            </label>
                                            <Field name="name" type="text" as={SettingsTextInput} label="" required maxLength={50} />
                                        </div>

                                        <div>
                                            <label for="email" class="text-sm font-medium text-white">
                                                Surname
                                            </label>
                                            <Field name="surname" type="text" as={SettingsTextInput} label="" required maxLength={50} />
                                        </div>

                                        <div>
                                            <label for="email" class="text-sm font-medium text-white">
                                                Email Address
                                            </label>
                                            <Field name="email" type="email" as={SettingsTextInput} label="" maxLength={100} />
                                        </div>

                                        <div>
                                            <label for="email" class="text-sm font-medium text-white">
                                                Whatsapp Number
                                            </label>
                                            <PhoneInput
                                                defaultCountry="ZA"
                                                countryCallingCodeEditable={false}
                                                onChange={(val) => {
                                                    setFieldValue("cell", val);
                                                }}
                                                onBlur={setFieldTouched}
                                                name="cell"
                                                value={values.cell}
                                                maxLength={12}
                                            />
                                            <p className="text-red-400 text-sm font-light">{errors.cell}</p>
                                        </div>
                                    </div>

                                    <div className="text-center mt-8">
                                        {!isSubmitting ? (
                                            <SubmitButton
                                                name="Save"
                                                disabled={!isValid || isSubmitting || !dirty}
                                                isSubmitting={isSubmitting}
                                                text="Submit Application"
                                                style={{
                                                    fontWeight: 700,
                                                    borderRadius: 20,
                                                    // marginBottom: 20,
                                                    // width: "100%",
                                                }}
                                            />
                                        ) : (
                                            <p>Please wait...</p>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </main>
        </div>
    );
}
