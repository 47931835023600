import React, { useCallback, useState, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function BookingFileUpload({ ...props }) {
    const [files, setFiles] = useState([]);

    const onDrop = React.useCallback((acceptedFiles) => {
        setFiles((prev) => [...prev, ...acceptedFiles]);
    }, []);

    useEffect(() => {
        props.setFieldValue("files", files);
        props.setFieldValue("template", 0);
    }, [files]);

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: props.max_files,
        onDrop,
    });

    return (
        <div>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <button
                    type="button"
                    className="relative inline-block text-white bg-primary px-2  py-1 rounded-xl hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                >
                    <FontAwesomeIcon className="h-4 w-4" aria-hidden="true" icon={regular("paperclip")} />
                </button>
            </div>
            <aside className="-ml-12">
                <ul>
                    {props.values.length > 0 &&
                        props.values.map((file, i) => (
                            <li key={file.name} className="text-white flex">
                                {file.name} - {file.size} bytes
                                <FontAwesomeIcon
                                    icon={regular("times")}
                                    className="ml-2 mt-2 text-red-500 cursor-pointer"
                                    title="Remove"
                                    onClick={() => {
                                        let newfiles = [];

                                        if (files.length > 1) {
                                            newfiles = files;
                                            newfiles.splice(i, 1);
                                        }

                                        setFiles(newfiles);
                                    }}
                                >
                                    DeleteButton
                                </FontAwesomeIcon>
                            </li>
                        ))}
                </ul>
            </aside>
        </div>
    );
}
