import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import NotificationToast from "../Helpers/NotificationToast";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faFile, faFileCsv, faFileExcel, faFilePdf, faGif, faImage, faMicrophone } from "@fortawesome/pro-solid-svg-icons";
import { getBooking } from "../../store/booking/bookingActions";
import { faFileZipper, faLoader, faVideo } from "@fortawesome/pro-regular-svg-icons";
import { closeModal, openModal } from "../../store/modal/modalReducer";
import { toast } from "react-toastify";

export default function FileDisplaySimple({ file, callback }) {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(true);
    let icon = faFile;

    switch (file.extension) {
        case "pdf":
            icon = faFilePdf;
            break;
        case "csv":
            icon = faFileCsv;
            break;
        case "xsl":
        case "xlsx":
            icon = faFileExcel;
            break;
        case "jpg":
        case "png":
        case "jpeg":
            icon = faImage;
            break;
        case "gif":
            icon = faGif;
            break;
        case "zip":
        case "tar":
            icon = faFileZipper;
        case "mp4":
            icon = faVideo;
            break;
        case "mp3":
            icon = faMicrophone;
            break;
        default:
            icon = faFile;
            break;
    }

    // async function delete_file ( id )
    // {
    //     setLoading( true );
    //     await axios( {
    //         method: "delete",
    //         url: `${ process.env.REACT_APP_API_URL }delete_file/${ id }`,
    //     } ).finally( setVisible( false ) );
    // }

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    if (!visible) return null;
    return (
        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            {loading ? (
                <FontAwesomeIcon icon={faLoader} className="mx-auto text-gray-600 text-lg animate-spin" />
            ) : (
                <div className="w-0 flex-1 flex items-center">
                    <FontAwesomeIcon className="flex-shrink-0 h-5 w-5 text-gray-600" icon={icon} aria-hidden="true" />
                    <p className="ml-2 flex-1 w-0 truncate text-gray-600 bg-transparent">{file.name}</p>

                    {/* <div className="ml-4 flex-shrink-0 space-x-2">
                        <FontAwesomeIcon
                            icon={ regular( "trash" ) }
                            onClick={ () => delete_file( file.id ) }
                            className="text-gray-400 hover:text-gray-800 cursor-pointer"
                            title="Delete File"
                        />
                    </div> */}
                </div>
            )}
        </li>
    );
}
