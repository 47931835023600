import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useHistory } from "react-router-dom";
import { openModal } from "../../store/modal/modalReducer";

import { toast } from "react-toastify";
// import { doc } from "firebase/firestore";

import { format } from "date-fns";

export default function GridBookingAppointments({ setDoc, db }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [appointmentList, setAppointmentList] = useState([]);
    const { current_booking } = useSelector((state) => state.booking);
    const { current_staff } = useSelector((state) => state.auth);
    const [clickOnce, setClickOnce] = useState(true);

    useEffect(async () => {
        if (clickOnce === true) {
            setClickOnce(false);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}appointment_list`,
                data: { booking_reference: current_booking.booking_reference },
            })
                .then((response) => {
                    setAppointmentList(response.data);
                })
                .catch(() => {
                    setAppointmentList([]);
                });
        }
    }, []);

    return (
        <div className="overflow-y-scroll scroll-smooth h-96">
            <p>List of Appointments</p>

            {appointmentList &&
                appointmentList.length > 0 &&
                appointmentList.map((appointment) => (
                    <div
                        className="border-2 w-full rounded px-1 py-1 my-2 cursor-pointer hover:-translate-y-0.5 hover:bg-dark_background hover:text-white"
                        onClick={(a) =>
                            dispatch(
                                openModal({
                                    modal_type: "ModalViewAppointment",
                                    modal_props: {
                                        booking_id: appointment.id,
                                    },
                                })
                            )
                        }
                    >
                        <p>
                            {appointment.appointment.name} - {appointment.status === 1 ? "Requested" : appointment.status === 2 ? "Confirmed" : appointment.status === 0 ? "Cancelled" : "Unknown"}
                        </p>
                        <p>{format(new Date(appointment.booked_date), "ccc, dd MMMM yyyy")} </p>
                        <p>
                            {format(new Date(appointment.booked_date + " " + appointment.booked_time), "HH:mm")} - {format(new Date(appointment.booked_date + " " + appointment.time_to), "HH:mm")}
                        </p>
                    </div>
                ))}
        </div>
    );
}
