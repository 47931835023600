import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ApplicationFormUCStep4Frm from "./UC/step4";
import ApplicationHeaderUC from "./ApplicationHeaderUC";

export default function ApplicationFormUCStep4() {
    const { url, property_id, type, year, gender, unit, bed, waitbed } = useParams();

    return (
        <div className="uc">
            <ApplicationHeaderUC />
            <main className="-mt-32">
                <div>
                    <div className="bg-white min-h-screen shadow-md rounded-tl-3xl white_block_left">
                        <div className="h-20 flex sticky top-0 z-30">
                            <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                <div className="text-white sm:text-xs md:text-2xl font-bold uppercase">APPLICATION</div>

                                <Link to={"/step3/" + url + "/" + property_id + "/" + type + "/" + year + "/" + gender + "/" + unit + "/0"}>
                                    <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Group_16" data-name="Group 16" transform="translate(-728 -233)">
                                            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(728 233)" fill="none" stroke="#fff" strokeWidth="1">
                                                <circle cx="22" cy="22" r="22" stroke="none" />
                                                <circle cx="22" cy="22" r="21.5" fill="none" />
                                            </g>
                                            <path
                                                id="Icon_ionic-ios-arrow-back"
                                                data-name="Icon ionic-ios-arrow-back"
                                                d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
                                                transform="translate(729.75 236.997)"
                                                fill="#fff"
                                            />
                                        </g>
                                    </svg>
                                </Link>
                            </div>
                            <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                        </div>

                        <div className="mx-auto bg-white max-w-7xl">
                            <ApplicationFormUCStep4Frm url={url} property_id={property_id} type={type} year={year} gender={gender} unit={unit} bed={bed} waitbed={waitbed} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
