import React, { Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";

import moment from "moment";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import SettingsDateTimePicker from "../Forms/SettingsDateTimePicker";
import { getBooking } from "../../store/booking/bookingActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ModalSnooze(props) {
  var four_hours = moment().add(4, "hours");
  var tomorrow = moment().add(24, "hours");
  var next_monday = moment().startOf("isoWeek").add(1, "week");
  var next_week = moment().add(1, "week");

  const snooze_times = [
    {
      value: 1,
      name: "4 Hours",
      description: "Snooze until " + moment(four_hours).format("LLLL"),
    },
    {
      value: 2,
      name: "Tomorrow",
      description: "Snooze until " + moment(tomorrow).format("LLLL"),
    },
    {
      value: 3,
      name: "Start of Next Week",
      description: "Snooze until " + moment(next_monday).format("LLLL"),
    },
    {
      value: 4,
      name: "One Week",
      description: "Snooze until " + moment(next_week).format("LLLL"),
    },
    {
      value: 5,
      name: "Custom",
      description: "Snooze until a custom time",
    },
  ];

  if (props.snooze_until) {
    var remove_snooze = new Object();
    remove_snooze.value = -1;
    remove_snooze.name = "Remove Snooze";
    remove_snooze.description =
      "Remove current snooze of " + moment(props.snooze_until).format("LLLL");
    snooze_times.push(remove_snooze);
  }
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(snooze_times[0]);
  const [open] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={regular("times")}
                    />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      This chat is currently{" "}
                      {props.snooze_until ? (
                        <span>
                          snoozed until{" "}
                          <p className="font-light ">
                            {moment(props.snooze_until).format("LLLL")}
                          </p>
                        </span>
                      ) : (
                        <span>not snoozed</span>
                      )}
                    </Dialog.Title>

                    <Formik
                      initialValues={{
                        snooze_until: 0,
                        snooze_until_custom: 0,
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.snooze_until) {
                          errors.snooze_until = "Required";
                        }
                        if (
                          values.snooze_until === 5 &&
                          !values.snooze_until_custom
                        ) {
                          errors.snooze_until_custom = "Required";
                        }
                        return errors;
                      }}
                      enableReinitialize="true"
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        var snooze_timestamp;
                        switch (values.snooze_until) {
                          case 1:
                            snooze_timestamp = moment().add(4, "hours");
                            break;
                          case 2:
                            snooze_timestamp = moment().add(24, "hours");
                            break;
                          case 3:
                            snooze_timestamp = moment()
                              .startOf("isoWeek")
                              .add(1, "week");
                            break;
                          case 4:
                            snooze_timestamp = moment().add(1, "week");
                            break;
                          case 5:
                            snooze_timestamp = values.snooze_until_custom;
                            break;
                          case -1:
                            snooze_timestamp = null;
                            break;
                        }

                        setSubmitting(true);
                        let submitData = {
                          snooze_until: snooze_timestamp,
                          type: props.type,
                          tag_id: props.tag_id,
                          current_staff_id: props.current_staff_id,
                        };

                        await axios({
                          method: "put",
                          url: `${process.env.REACT_APP_API_URL}snooze/${props.id}`,
                          data: submitData,
                        })
                          .then(() => {
                            toast(
                              <NotificationToast
                                title={"Booking Snoozed"}
                                message={
                                  "Snoozed until " +
                                  moment(snooze_timestamp).format("LLLL")
                                }
                              />
                            );
                          })
                          .catch((error) => {
                            console.log(error);
                            console.log(error.response);
                          })
                          .finally(() => {
                            setSubmitting(false);
                            resetForm();
                            dispatch(getBooking());
                            dispatch(closeModal());
                          });
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="space-y-8 divide-y divide-gray-200 m-4"
                        >
                          <RadioGroup
                            value={selected}
                            name="snooze_until"
                            onChange={(value) => {
                              setSelected(value);
                              setFieldValue("snooze_until", value);
                            }}
                          >
                            <RadioGroup.Label className="sr-only">
                              Snooze Until
                            </RadioGroup.Label>
                            <div className="bg-white rounded-md -space-y-px">
                              {snooze_times.map((duration, duration_index) => (
                                <RadioGroup.Option
                                  key={duration.value}
                                  value={duration.value}
                                  className={({ checked }) =>
                                    classNames(
                                      duration_index === 0
                                        ? "rounded-tl-md rounded-tr-md"
                                        : "",
                                      duration_index === snooze_times.length - 1
                                        ? "rounded-bl-md rounded-br-md"
                                        : "",
                                      checked
                                        ? "bg-gray-100 border-text_divider "
                                        : "border-gray-200",
                                      "relative border p-4 flex cursor-pointer focus:outline-none"
                                    )
                                  }
                                >
                                  {({ active, checked }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          checked
                                            ? "bg-primary border-transparent"
                                            : "bg-white border-gray-300",
                                          active
                                            ? "ring-2 ring-offset-2 ring-primary"
                                            : "",
                                          "h-4 w-4 mt-0.5 cursor-pointer shrink-0 rounded-full border flex items-center justify-center"
                                        )}
                                        aria-hidden="true"
                                      >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                      </span>
                                      <span className="ml-3 flex flex-col">
                                        <RadioGroup.Label
                                          as="span"
                                          className={classNames(
                                            checked
                                              ? "text-dark_background"
                                              : "text-gray-900",
                                            "block text-sm"
                                          )}
                                        >
                                          {duration.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as="span"
                                          className={classNames(
                                            checked
                                              ? "text-primary"
                                              : "text-gray-500",
                                            "block text-sm font-light"
                                          )}
                                        >
                                          {duration.description}
                                        </RadioGroup.Description>
                                      </span>
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                          {values.snooze_until === 5 && (
                            <Field
                              as={SettingsDateTimePicker}
                              name="snooze_until_custom"
                              label="Snooze Until"
                              time_select={true}
                            />
                          )}
                          <div>
                            {errors.length > 0 && errors.map((e) => <p>e</p>)}
                          </div>
                          <SubmitButton
                            name="Save"
                            disabled={!isValid || isSubmitting || !dirty}
                            isSubmitting={isSubmitting}
                            text="Snooze"
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
