import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import ApplicationFormUCStep4Frm from "./UC/step4";
import ApplicationHeaderUC from "./ApplicationHeaderUC";

export default function ApplicationFormUCStep4() {
    const { url } = useParams();
    const [property_id, setProperty_id] = useState(0);
    const [type, setType] = useState(0);
    const [year, setYear] = useState(0);
    const [gender, setGender] = useState(0);
    const [unit, setUnit] = useState(0);
    const [bed, setBed] = useState(0);

    useEffect(async () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
        })
            .then((response) => {
                const current_booking = response.data;

                setProperty_id(current_booking?.property_id ? current_booking?.property_id : 0);
                setType(current_booking?.unit_type?.id ? current_booking?.unit_type?.id : 0);
                setUnit(current_booking?.unit_id ? current_booking?.unit_id : 0);
                setBed(current_booking?.bed_id ? current_booking?.bed_id : 0);
                setGender(current_booking?.user?.profile?.gender ? current_booking.user.profile.gender : 0);
                setYear(current_booking?.user?.profile?.study_year ? current_booking.user.profile.study_year : 0);
            })
            .catch((error) => {
                console.log(error);
                // window.location.href = "/404";
            });
    }, []);

    return (
        <div className="uc">
            <ApplicationHeaderUC />
            <main className="-mt-32">
                <div>
                    <div className="bg-white min-h-screen shadow-md rounded-tl-3xl white_block_left">
                        <div className="h-20 flex">
                            <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                <div className="text-white sm:text-xs md:text-2xl font-bold uppercase">APPLICATION</div>

                                <Link to={"/step3/" + url + "/" + property_id + "/" + type + "/" + year + "/" + gender + "/" + unit + "/0"}>
                                    <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Group_16" data-name="Group 16" transform="translate(-728 -233)">
                                            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(728 233)" fill="none" stroke="#fff" strokeWidth="1">
                                                <circle cx="22" cy="22" r="22" stroke="none" />
                                                <circle cx="22" cy="22" r="21.5" fill="none" />
                                            </g>
                                            <path
                                                id="Icon_ionic-ios-arrow-back"
                                                data-name="Icon ionic-ios-arrow-back"
                                                d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
                                                transform="translate(729.75 236.997)"
                                                fill="#fff"
                                            />
                                        </g>
                                    </svg>
                                </Link>
                            </div>
                            <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                        </div>

                        <div className="mx-auto bg-white max-w-7xl">
                            {property_id && property_id > 0 ? (
                                <ApplicationFormUCStep4Frm url={url} property_id={property_id} type={type} year={year} gender={gender} unit={unit} bed={bed} />
                            ) : (
                                <div className="text-center mt-6">
                                    <Link
                                        className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                        to={"/step1/" + url}
                                    >
                                        Please click here to choose a property
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
