import { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Menu, Transition } from "@headlessui/react";
import { useState } from "react";

export default function BookingTemplate({ handleSubmit, ...props }) {
    // const { templates, whatsapp_templates, template_categories } = useSelector((state) => state.templates);
    const [templates, SetTemplates] = useState([]);
    const [whatsapp_templates, SetWhatsapp_Templates] = useState([]);
    const [templateCategories, SetTemplateCategories] = useState([]);
    const [years, setYears] = useState(0);

    useEffect(() => {

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get_years`,
          })
            .then((response) => {
              let dbYears = response.data;
              dbYears.push({ label: "All years", value: 0 });
              dbYears.sort((a, b) => a.value - b.value);
              setYears(dbYears);
            })
            .catch((error) => {
              alert("year load failed");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}templates`,
        }).then((response) => {
            SetTemplates(response.data);
            SetTemplateCategories(Object.keys(response.data));
        });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}whatsapp_templates`,
        }).then((response) => {
            SetWhatsapp_Templates(response.data);
        });
    }, [SetTemplates, SetWhatsapp_Templates]);

    return (
        <Menu
            as="div"
            className="z-10 relative inline-block text-left bg-primary px-2 -mt-6 self-center py-1 rounded-xl hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
        >
            <div>
                <Menu.Button className="rounded-full flex items-center text-white hover:cursor-pointer ">
                    <span className="sr-only">Open options</span>
                    <FontAwesomeIcon className="h-5 w-5" aria-hidden="true" icon={regular("table-layout")} />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-80 whitespace-nowrap h-80 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {props.platform === "whatsapp" && (
                        <div>
                            <p className="text-center p-4 bg-dark_background text-white focus:outline-none">System Approved Templates</p>

                            {years && years.length > 0 && years.map((year) => {
                                return(
                                    <>
                                        <p className="text-center p-4 bg-dark_background text-white focus:outline-none">{year.label}</p>

                                        {whatsapp_templates.length > 0 &&
                                            whatsapp_templates.map((template) => {
                                                if(year.value === template.year_id){

                                                    return (
                                                        <Menu.Item key={template.id}>
                                                            <button
                                                                type="submit"
                                                                className="whitespace-nowrap w-min hover:bg-gray-200 hover:border-gray-200 block px-4 py-2 text-sm"
                                                                onClick={() => {
                                                                    props.setFieldValue("template", template.id);
                                                                    props.setFieldValue("platform", "whatsapp");
                                                                    props.setFieldValue("message", template.message);
                                                                    props.setFieldValue("template_name", template.title);
                                                                }}
                                                            >
                                                                <p className="text-left font-medium">{template.title}</p>
                                                                <p className=" w-full text-gray-500 ">{template.message}</p>
                                                            </button>
                                                        </Menu.Item>
                                                    );
                                                }
                                            })}
                                    </>
                                )
                            })}

                        </div>
                    )}
                    {/* TODO - Fix this */}
                    {templateCategories &&
                        templateCategories.map((title) => {
                            return (
                                <div key={`template_` + title}>
                                    <p className="text-center p-4 bg-dark_background text-white focus:outline-none">{title}</p>
                                    {templates[title].map((template) =>
                                        template["message_" + props.platform] && template["message_" + props.platform] !== "null" && template["message_" + props.platform] !== "" ? (
                                            <Menu.Item key={template.id}>
                                                <a
                                                    href="#"
                                                    className="whitespace-nowrap w-min hover:bg-blue-200 hover:border-blue-200 block px-4 py-2 text-sm"
                                                    onClick={() => {
                                                        props.setFieldValue("message", template["message_" + props.platform]);
                                                        props.setFieldValue("files", template.files ? JSON.parse(template.files) : []);
                                                        props.setFieldValue("template", template.id);
                                                        props.setFieldValue("template_name", template.title);
                                                    }}
                                                >
                                                    <p className="font-medium">{template.title}</p>
                                                    <p className=" w-full text-gray-500 ">{template["message_" + props.platform]}</p>
                                                </a>
                                            </Menu.Item>
                                        ) : (
                                            <div key={template.id}></div>
                                        )
                                    )}
                                </div>
                            );
                        })}
                    <Menu.Item>
                        <div className="py-4">
                            <hr />
                            <a href="/settings" target="_blank" className="p-4 text-primary hover:text-primary_hover focus:outline-none">
                                <FontAwesomeIcon icon={regular("pen-to-square")} /> Edit Normal Templates
                            </a>
                        </div>
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
