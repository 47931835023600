import { useState, useEffect } from "react";
import { usePopper } from "react-popper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Popover } from "@headlessui/react";

export default function BookingwaBank({ handleSubmit, platform, ...props }) {
    const default_options = [
        {
            value: "stanley_goldmashu",
            label: "Stanley – Goldmashu",
            link: "stanley_goldmashu",
            siteurl: "https://api.urbancircle.co.za/bank_docs/Goldmashu.pdf",
        },
        {
            value: "drivelines_mabotrent_1",
            label: "Drivelines – Mabotrent 1",
            link: "drivelines_mabotrent_1",
            siteurl: "https://api.urbancircle.co.za/bank_docs/Mabotrent1.pdf",
        },
        {
            value: "nota_bene_mabotrent_2",
            label: "Nota Bene  - Mabotrent 2",
            link: "nota_bene_mabotrent_2",
            siteurl: "https://api.urbancircle.co.za/bank_docs/Mabotrent2.pdf",
        },
        {
            value: "eastside_petraglo",
            label: "Eastside – Petraglo",
            link: "eastside_petraglo",
            siteurl: "https://api.urbancircle.co.za/bank_docs/Petraglo.pdf",
        },
        {
            value: "w_a_petraglo",
            label: "W+A – Petraglo",
            link: "w_a_petraglo",
            siteurl: "https://api.urbancircle.co.za/bank_docs/Petraglo.pdf",
        },
        {
            value: "highbury_lisacraft",
            label: "Highbury – Lisacraft",
            link: "highbury_lisacraft",
            siteurl: "https://api.urbancircle.co.za/bank_docs/Lisacraft.pdf",
        },
        {
            value: "sovereign_noskop_1",
            label: "Sovereign – Noskop 1",
            link: "sovereign_noskop_1",
            siteurl: "https://api.urbancircle.co.za/bank_docs/Noskop1.pdf",
        },
    ];

    let [referenceElement, setReferenceElement] = useState();
    let [popperElement, setPopperElement] = useState();
    let [options, setOptions] = useState(default_options);
    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "top",
    });

    return (
        <Popover>
            <Popover.Button
                ref={setReferenceElement}
                className="ml-4 relative inline-block text-white bg-primary px-2  py-1 rounded-xl hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
            >
                <FontAwesomeIcon className="h-4 w-4" aria-hidden="true" icon={regular("building-columns")} />
            </Popover.Button>

            <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper} className="bg-white rounded-lg pb-4  z-20">
                <h1 className="bg-dark_background text-white p-2 rounded-t-lg">Bank Confirmation Letters</h1>
                <div className="flex flex-col items-start px-4 mt-2">
                    {options?.map((o) => (
                        <button key={"bpopt_" + o.value} type="button" className="text-gray-600 hover:text-primary" onClick={() => props.setFieldValue("message", o.siteurl)}>
                            {o.label}
                        </button>
                    ))}
                </div>
            </Popover.Panel>
        </Popover>
    );
}
