import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";

import BookingPlatformButton from "../Booking/BookingPlatformButton";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import BookingTemplate from "../Booking/BookingTemplate";
import BookingWysiwyg from "../Booking/BookingWysiwyg";
import CustomButton from "../Helpers/CustomButton";
import { Formik, Form } from "formik";
import EmojiPicker from "../Forms/EmojiPicker";
import * as Yup from "yup";
import BookingTextArea from "../Booking/BookingTextArea";
import BookingPlaceholder from "../Booking/BookingPlaceholder";
import SubmitButton from "../Forms/SubmitButton";
import NotificationToast from "../Helpers/NotificationToast";

export default function ModalBM(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { staff, current_staff } = useSelector((state) => state.auth);
  const { current_booking } = useSelector((state) => state.booking);
  const [buildingManagers, setBuildingManagers] = useState([]);
  const [selectedBM, setSelectedBM] = useState("");
  const [selectedBMDetails, setSelectedBMDetails] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [allowGenerate, setAllowGenerate] = useState(true);
  const [moveOutUrl, setMoveOutUrl] = useState("");

  useEffect(async () => {
    if (buildingManagers.length === 0) {
      let staffList = [];
      staff.forEach(function (value) {
        if (value.building_manager == 1) {
          staffList.push({
            value: value.id,
            label: value.name + " " + value.surname,
          });
        }
      });
      setBuildingManagers(staffList);
    }

    if (selectedBM !== "") {
      staff.forEach(function (value) {
        if (value.id == selectedBM) {
          setSelectedBMDetails(value);
        }
      });
    }

    if (allowGenerate === true) {
      setAllowGenerate(false);
      let move_out = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}move_out_link`,
        data: { booking_id: current_booking.id },
      });

      setMoveOutUrl(move_out.data);
    }
  }, [selectedBM]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      Post move out form
                      {props.heading}
                    </Dialog.Title>
                    <div className="mt-2 text-gray-500">{props.body}</div>
                  </div>
                </div>
                <div className="mt-2 sm:mt-4">
                  <div className="ml-2">
                    <br />

                    <Formik
                      enableReinitialize
                      initialValues={{
                        message:
                          "<b>Move out form for Building Manager</b><br/><p><a href='" +
                          process.env.REACT_APP_LARAVEL_URL +
                          "move-out-form/" +
                          moveOutUrl +
                          "'>" +
                          process.env.REACT_APP_LARAVEL_URL +
                          "move-out-form/" +
                          moveOutUrl +
                          "</a></p>",
                        platform: "note",
                        from: current_staff?.id,
                        to: "",
                        files: [],
                        template: 0,
                        booking: current_booking.id,
                      }}
                      validateOnChange={false}
                      validateOnBlur={false}
                      validationSchema={Yup.object().shape({
                        platform: Yup.string().required(
                          "Message platform is not selected"
                        ),
                        message: Yup.string().when("platform", {
                          is: (value) => value && value != "whatsapp",
                          then: Yup.string().required("Message is empty"),
                        }),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        const the_message = values.message;
                        var template = values.template;
                        let replaced_message = values.message;

                        let regards = "The Qliving Team";
                        if (process.env.REACT_APP_COLOUR === "UC") {
                          regards = "The Urban Circle Team";
                        } else if (process.env.REACT_APP_COLOUR === "QP") {
                          regards = "The Quorum Properties Team";
                        }

                        replaced_message = the_message.replace(
                          "[name]",
                          current_booking?.user?.name
                        );
                        replaced_message = replaced_message.replace(
                          "[me]",
                          current_staff?.name
                        );
                        replaced_message = replaced_message.replace(
                          "[regards]",
                          "Kind Regards, " + regards
                        );
                        replaced_message = replaced_message.replace(
                          "[surname]",
                          current_booking?.user?.surname
                        );
                        replaced_message = replaced_message.replace(
                          "[email]",
                          current_booking?.user?.email
                        );
                        replaced_message = replaced_message.replace(
                          "[booking_reference]",
                          current_booking?.booking_reference
                        );
                        replaced_message = replaced_message.replace(
                          "[edit_profile]",
                          window.location.origin +
                            "/edit_application/" +
                            current_booking?.edit_link !==
                            null
                            ? current_booking?.edit_link?.edit_url
                            : current_booking.user.edit_url
                        );
                        replaced_message = replaced_message.replace(
                          "[building name]",
                          current_booking?.property?.name
                        );

                        replaced_message = replaced_message.replace(
                          "[payfast_link]",
                          window.location.origin +
                            "/payfast/" +
                            current_booking?.edit_link !==
                            null
                            ? current_booking?.edit_link?.edit_url
                            : current_booking.user.edit_url
                        );
                        replaced_message = replaced_message.replace(
                          "[bank_details]",
                          window.location.origin + "/bank-details/"
                        );

                        replaced_message = replaced_message.replace(
                          "[Move_In_Inspection]",
                          current_booking?.property_id == 1
                            ? window.location.origin +
                                "/appointment/Move_In_Inspection/17/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 2
                            ? window.location.origin +
                                "/appointment/Move_In_Inspection/16/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 4
                            ? window.location.origin +
                                "/appointment/Move_In_Inspection/15/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 3 ||
                              current_booking?.property_id == 6 ||
                              current_booking?.property_id == 7
                            ? window.location.origin +
                                "/appointment/Move_In_Inspection/18/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : ""
                        );

                        replaced_message = replaced_message.replace(
                          "[Move_Out_Inspection]",
                          current_booking?.property_id == 1
                            ? window.location.origin +
                                "/appointment/Move_Out_Inspection/13/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 2
                            ? window.location.origin +
                                "/appointment/Move_Out_Inspection/12/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 4
                            ? window.location.origin +
                                "/appointment/Move_Out_Inspection/11/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 3 ||
                              current_booking?.property_id == 6 ||
                              current_booking?.property_id == 7
                            ? window.location.origin +
                                "/appointment/Move_Out_Inspection/14/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : ""
                        );

                        replaced_message = replaced_message.replace(
                          "[Viewing]",
                          current_booking?.property_id == 1
                            ? window.location.origin +
                                "/appointment/Viewing/7/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 2
                            ? window.location.origin +
                                "/appointment/Viewing/9/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 4
                            ? window.location.origin +
                                "/appointment/Viewing/8/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 3 ||
                              current_booking?.property_id == 6 ||
                              current_booking?.property_id == 7
                            ? window.location.origin +
                                "/appointment/Viewing/10/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : ""
                        );

                        replaced_message = replaced_message.replace(
                          "[MaintenanceCall]",
                          current_booking?.property_id == 1
                            ? window.location.origin +
                                "/appointment/MaintenanceCall/6/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 2
                            ? window.location.origin +
                                "/appointment/MaintenanceCall/5/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 4
                            ? window.location.origin +
                                "/appointment/MaintenanceCall/4/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 3 ||
                              current_booking?.property_id == 6 ||
                              current_booking?.property_id == 7
                            ? window.location.origin +
                                "/appointment/MaintenanceCall/3/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : ""
                        );

                        // replaced_message = replaced_message.replace("[move_in_inspection]", current_booking?.property_id == 1 ? "https://calendly.com/theox-1/move-in-inspection" : current_booking?.property_id == 2  ? "https://calendly.com/forestviews/30min-1" : current_booking?.property_id == 4  ? "https://calendly.com/delvedeeper/move-in-inspection" : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7 ? "https://calendly.com/the-unison/move-in-inspection" : "");

                        // replaced_message = replaced_message.replace("[move_out_inspection]",  current_booking?.property_id == 1 ? "https://calendly.com/theox-1/move-out-inspection" : current_booking?.property_id == 2 ? "https://calendly.com/forestviews/move-out-inspection" : current_booking?.property_id == 4 ? "https://calendly.com/delvedeeper/move-out-inspection" : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7 ? "https://calendly.com/the-unison/move-out" : "");

                        // replaced_message = replaced_message.replace("[book_a_viewing]", current_booking?.property_id == 1 ? "https://calendly.com/theox-1/viewing-at-the-ox" : current_booking?.property_id == 2 ? "https://calendly.com/forestviews/book-a-viewing" : current_booking?.property_id == 4 ? "https://calendly.com/delvedeeper/viewings" : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7 ? "https://calendly.com/the-unison/30min" : "");

                        var form_data = new FormData();

                        var timestamp = new Date();
                        var platform = values.platform;
                        var from = current_staff?.id;
                        var to = values.to;
                        var user_id = current_booking.user.id;

                        form_data.append("timestamp", timestamp);
                        form_data.append("message", replaced_message);
                        form_data.append(
                          "conversation_id",
                          current_booking.user.conversation_id
                        );
                        form_data.append("platform", platform);
                        form_data.append("from", from);
                        form_data.append("booking", current_booking.id);
                        form_data.append("to", to);
                        form_data.append("user_id", user_id);
                        form_data.append("staff_id", current_staff.id);
                        form_data.append("template", template);
                        form_data.append("to_bm", 1);
                        // form_data.append("bm_id", selectedBM);

                        await axios
                          .post(
                            `${process.env.REACT_APP_API_URL}send`,
                            form_data
                          )
                          .then((response) => {
                            if (template) {
                              let regards = "The Qliving Team";
                              if (process.env.REACT_APP_COLOUR === "UC") {
                                regards = "The Urban Circle Team";
                              } else if (
                                process.env.REACT_APP_COLOUR === "QP"
                              ) {
                                regards = "The Quorum Properties Team";
                              }

                              replaced_message = the_message.replace(
                                "[name]",
                                current_booking.user.name
                              );
                              replaced_message = replaced_message.replace(
                                "[me]",
                                current_staff.name
                              );
                              replaced_message = replaced_message.replace(
                                "[regards]",
                                "Kind Regards, " + regards
                              );
                              replaced_message = replaced_message.replace(
                                "[surname]",
                                current_booking.user.surname
                              );
                              replaced_message = replaced_message.replace(
                                "[email]",
                                current_booking.user.email
                              );
                              replaced_message = replaced_message.replace(
                                "[booking_reference]",
                                current_booking?.booking_reference
                              );
                              replaced_message = replaced_message.replace(
                                "[edit_profile]",
                                window.location.origin +
                                  "/edit_application/" +
                                  current_booking?.edit_link !==
                                  null
                                  ? current_booking?.edit_link?.edit_url
                                  : current_booking.user.edit_url
                              );
                            }

                            toast(
                              <NotificationToast
                                title={values.platform + " successful"}
                                message={"Your message has been sent."}
                              />
                            );
                            resetForm();
                          })
                          .catch((error) => {
                            console.log(error);
                            toast(
                              <NotificationToast
                                title="Something went wrong"
                                message={JSON.stringify(error.response.data)}
                                type="error"
                              />,
                              {
                                autoClose: false,
                              }
                            );
                          });

                        setSubmitting(false);
                        dispatch(closeModal());
                      }}
                    >
                      {({
                        values,
                        errors,
                        setFieldValue,
                        handleSubmit,
                        isValid,
                        isSubmitting,
                        dirty,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="rounded-3xl w-full bottom-0"
                        >
                          <div className="flex justify-between pr-6 bg-dark_background pt-8">
                            <div className="flex pl-6 space-x-4 h-8">
                              <BookingPlatformButton
                                valuePlatform={values.platform}
                                buttonPlatform={"note"}
                                icon={regular("note")}
                                message={"Note"}
                                from={current_staff?.id}
                                to={""}
                                setFieldValue={setFieldValue}
                              />
                            </div>

                            <BookingTemplate
                              platform={values.platform}
                              setFieldValue={setFieldValue}
                              handleSubmit={handleSubmit}
                            />
                          </div>
                          {values.platform === "" && (
                            <div className="px-6 py-4 block w-full sm:text-sm !outline-none resize-none bg-light_background h-full">
                              Please choose a Platform to send from
                            </div>
                          )}

                          {values.platform === "note" && (
                            <BookingWysiwyg
                              name="message"
                              id="message"
                              initialValues={values}
                              setFieldValue={setFieldValue}
                              staff={staff}
                              value={values.message}
                            />
                          )}

                          {errors.message && (
                            <div className="px-6 inline-flex bg-light_background w-full">
                              <p>&nbsp;</p>
                              {errors.message && (
                                <p className="text-sm text-red-400 mr-4">
                                  {errors.message}
                                </p>
                              )}
                            </div>
                          )}

                          <div className="px-6 py-4 ml-auto flex justify-between items-center space-x-5 bg-dark_background rounded-b-3xl">
                            <div className="flex">
                              <EmojiPicker
                                value={values.message}
                                source="booking"
                                setFieldValue={setFieldValue}
                              />
                              <BookingPlaceholder
                                setFieldValue={setFieldValue}
                                value={values.message}
                                platform={values.platform}
                              />
                            </div>
                            <div className="pr-4 ">
                              {values.platform !== "" && (
                                <SubmitButton
                                  name="Submit"
                                  disabled={!isValid || isSubmitting || !dirty}
                                  isSubmitting={isSubmitting}
                                  text="Post"
                                />
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
