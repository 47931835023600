import React, { useEffect, Fragment, useState } from "react";
import { closeModal, openModal } from "../../store/modal/modalReducer";
import axios from "axios";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextarea from "../Forms/SettingsTextarea";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import { toast } from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import { getBooking } from "../../store/booking/bookingActions";
import { SvgLoader, SvgProxy } from "react-svgmt";
import moment from "moment";

export default function ModalUnitAssignment(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [loading, setLoading] = useState(false);
  const { current_booking } = useSelector((state) => state.booking);
  const [chosenUnit, setChosenUnit] = useState(0);
  const [reservedClick, setReservedClick] = useState(false);
  const [unitLoading, setUnitLoading] = useState(false);
  const { properties } = useSelector((state) => state.properties);
  const { current_staff } = useSelector((state) => state.auth);

  const [map, setMap] = useState(
    props.current_booking?.floor_id > 0 ? props.current_booking?.floor_id : ""
  );
  const [chosenProperty, setChosenProperty] = useState(
    props.current_booking.property_id > 0
      ? props.current_booking.property_id
      : 0
  );
  const [moved_in_blocked, setMoved_in_blocked] = useState("");
  const [blocked, setBlocked] = useState("");
  const [unitTypes, setUnitTypes] = useState([]);
  const [floors, setFloors] = useState([]);
  const [loadOnce, setLoadOnce] = useState(true);
  const [unitName, setUnitName] = useState("");
  const [oldUnitName, setOldUnitName] = useState("");

  const [bookingsActive, setBookingsActive] = useState([]);
  const [waitlist, setWaitlist] = useState([]);

  var month_strt = moment().add(1, "M").format("MM");
  var year_strt = moment().format("Y");
  var lease_start_string = year_strt + "-" + month_strt + "-01";
  var month_end = moment().format("MM");
  var year_end = moment().add(1, "Y").format("Y");
  var lease_end_string = moment(new Date(year_end, month_end, 0)).format(
    "Y-MM-D"
  );

  if (process.env.REACT_APP_COLOUR === "UC") {
    lease_start_string = "2024-02-01";
    lease_end_string = "2024-11-30";
  }

  async function getUnitInfo(id) {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_unit_info/${id}`,
    })
      .then((response) => {
        setUnitName(response.data);
      })
      .catch((error) => {
        alert("unit load failed");
      });

    if (
      props.current_booking?.unit_id !== null &&
      props.current_booking?.unit_id > 0
    ) {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_unit_info/${props.current_booking.unit_id}`,
      })
        .then((response) => {
          setOldUnitName(response.data);
        })
        .catch((error) => {
          alert("unit load failed");
        });
    }

    if (props.current_booking?.waiting_unit_id > 0) {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_unit_info/${props.current_booking.waiting_unit_id}`,
      })
        .then((response) => {
          setOldUnitName(response.data);
        })
        .catch((error) => {
          alert("unit load failed");
        });
    }

    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_unit_bookings/${id}`,
    })
      .then((response) => {
        setBookingsActive(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_unit_bookings_waitlist/${id}`,
    })
      .then((response) => {
        setWaitlist(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border:
        state.isFocused && "none" ? "1px solid #9F7D38" : "1px solid #d1d5db",
      cursor: "pointer",
      "&:hover": {
        border: state.isFocused ? "1px solid #9F7D38" : "1px solid #d1d5db",
        cursor: "pointer",
      },
      borderRadius: "10px",
      padding: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      padding: 0,
      border: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#e7ebe5",
    }),
  };

  useEffect(async () => {
    if (map > 0) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_floor_unit_bookings/${map}/1`,
      })
        .then((response) => {
          let var_blocked = ".blocked";
          if (response.data !== "") {
            var_blocked += "," + response.data;
          }

          setBlocked(var_blocked);
        })
        .catch((error) => {
          alert("properties failed");
        });

      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_floor_unit_bookings/${map}/5`,
      })
        .then((response) => {
          let var_blocked = ".movedIn";
          // if (response.data !== "") {
          //   var_blocked += "," + response.data;
          // }

          setMoved_in_blocked(var_blocked);
        })
        .catch((error) => {
          alert("properties failed");
        });
    }
  }, [map]);

  if (loadOnce === true) {
    setLoadOnce(false);
    getUnitTypes(props.current_booking.property_id);
    getFloorTypes(props.current_booking.property_id);
  }

  async function getUnitTypes(id) {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
    }).then((response) => {
      setUnitTypes(response.data);
    });
  }

  async function getFloorTypes(id) {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_property_floors/${id}/${props.current_booking?.user?.profile?.gender}`,
    })
      .then((response) => {
        setFloors(response.data.floors);
      })
      .catch((error) => {
        alert("properties failed");
      });
  }

  async function delinkUnit() {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}delink_user_unit`,
      data: {
        booking_id: props.current_booking.id,
        user: current_staff,
        move_location: 0,
        type: props.type,
        tag_id: props.tag_id,
      },
    })
      .then((response) => {
        dispatch(getBooking(props.current_booking.id));
        setChosenUnit(0);
        dispatch(closeModal());
      })
      .catch((error) => {
        alert("properties failed");
      });
  }

  async function set_new_unit(unit_id) {
    setUnitLoading(true);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}change_user_unit`,
      data: {
        booking_id: props.current_booking.id,
        unit_id: parseInt(unit_id),
        user: current_staff,
        type: props.type,
        tag_id: props.tag_id,
      },
    })
      .then((response) => {
        dispatch(getBooking(props.current_booking.id));
        setChosenUnit(0);
        dispatch(closeModal());
      })
      .catch((error) => {
        alert("properties failed");
      });
    setUnitLoading(false);
  }

  async function set_new_primary_unit(primary_unit_id) {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}change_user_primary_unit`,
      data: {
        booking_id: props.current_booking.id,
        primary_unit_id: parseInt(primary_unit_id),
        user: current_staff,
      },
    })
      .then((response) => {
        dispatch(getBooking(props.current_booking.id));
        setChosenUnit(0);
        dispatch(closeModal());
      })
      .catch((error) => {
        console.log(error);
        alert("properties failed");
      });
  }

  async function set_new_waiting_unit(waiting_unit_id) {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}change_user_waiting_unit`,
      data: {
        booking_id: props.current_booking.id,
        waiting_unit_id: parseInt(waiting_unit_id),
        user: current_staff,
      },
    })
      .then((response) => {
        dispatch(getBooking(props.current_booking.id));
        setChosenUnit(0);
        dispatch(closeModal());
      })
      .catch((error) => {
        console.log(error);
        alert("properties failed");
      });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <CustomButton
                    type="secondary"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    Close
                  </CustomButton>
                </div>
                <div className="w-full">
                  <div className="flex justify-between mb-4 items-center">
                    <div>
                      <h2 className="font-bold text-lg">
                        {props.current_booking.user.name}{" "}
                        {props.current_booking.user.surname}
                      </h2>
                    </div>

                    {props.current_booking?.unit_id > 0 && (
                      <button
                        className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                        onClick={() => delinkUnit()}
                      >
                        DE-LINK UNIT
                      </button>
                    )}

                    <button
                      className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                      onClick={() => dispatch(closeModal())}
                    >
                      CANCEL
                    </button>
                  </div>

                  {chosenUnit > 0 && !reservedClick ? (
                    <>
                      {props.current_booking.waiting_unit_id > 0 && (
                        <div>
                          <span>
                            This will remove the user from the waiting list for{" "}
                            {oldUnitName?.property?.name} {oldUnitName?.name}
                          </span>
                        </div>
                      )}
                      <div>
                        Change to {unitName?.property?.name} {unitName?.name}
                      </div>
                      <div className="space-x-2 mt-4">
                        <button
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                          onClick={() => set_new_unit(chosenUnit)}
                        >
                          {unitLoading === true ? (
                            <>
                              <FontAwesomeIcon
                                className="w-4 h-4 animate-spin"
                                icon={regular("loader")}
                              />
                              <span className="w-full">
                                {props.loadingtext
                                  ? props.loadingtext
                                  : "Loading"}
                              </span>
                            </>
                          ) : (
                            "Yes"
                          )}
                        </button>
                        <button
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                          onClick={() => setChosenUnit(0)}
                        >
                          No
                        </button>

                        {props.current_booking.waiting_unit_id ===
                        parseFloat(chosenUnit) ? (
                          <>
                            <button
                              className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                              onClick={() => set_new_primary_unit(chosenUnit)}
                            >
                              Make Primary
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : chosenUnit > 0 && reservedClick ? (
                    <>
                      {props.current_booking.waiting_unit_id > 0 && (
                        <div>
                          <span>
                            This will remove the user from the waiting list for
                            unit {oldUnitName?.property?.name}{" "}
                            {oldUnitName?.name}
                          </span>
                        </div>
                      )}
                      {props.current_booking.unit_id > 0 && (
                        <div>
                          <span>
                            This will remove the user from{" "}
                            {oldUnitName?.property?.name} {oldUnitName?.name}
                          </span>
                          <span> and add them to the waiting list for</span>{" "}
                          {unitName?.property?.name} {unitName?.name}
                        </div>
                      )}

                      <div>
                        Change to waitlist for {unitName?.property?.name}{" "}
                        {unitName?.name}
                      </div>

                      <div className="space-x-2 mt-4">
                        <button
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                          onClick={() => set_new_waiting_unit(chosenUnit)}
                        >
                          Yes
                        </button>
                        <button
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                          onClick={() => setChosenUnit(0)}
                        >
                          No
                        </button>

                        {props.current_booking.waiting_unit_id ===
                        parseFloat(chosenUnit) ? (
                          <>
                            <button
                              className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                              onClick={() => set_new_primary_unit(chosenUnit)}
                            >
                              Make Primary
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    <Formik
                      initialValues={{
                        property_id: props.current_booking.property_id,
                        unit_type: props.current_booking?.unit_type?.id
                          ? props.current_booking?.unit_type?.id
                          : props.current_booking?.unit_type
                          ? props.current_booking?.unit_type
                          : "",
                        floor_id: props.current_booking.floor_id,
                      }}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setMap(values.floor_id);
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="colour-uc6 rounded-3xl py-7 px-20 flex justify-between items-center mb-4">
                            <div className="flex w-4/5 flex-wrap">
                              <div className="w-1/3 px-3 z-20">
                                <div>
                                  <label
                                    htmlFor="unit_id"
                                    className="text-sm font-medium text-gray-700"
                                  >
                                    BUILDING
                                  </label>
                                  <Select
                                    id="property_id"
                                    name="property_id"
                                    options={properties}
                                    onChange={(val) => {
                                      setFieldValue("property_id", val.value);
                                      setChosenProperty(val.value);
                                      getUnitTypes(val.value);
                                      getFloorTypes(val.value);

                                      setFieldValue("floor_id", 0);
                                      setFieldValue("room_type", 0);
                                    }}
                                    onBlur={setFieldTouched}
                                    value={
                                      properties && properties.length > 0
                                        ? properties.find(
                                            (option) =>
                                              option.value ===
                                              values.property_id
                                          )
                                        : ""
                                    }
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    styles={customStyles}
                                    className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                  />
                                  <p className="text-red-400 text-sm">
                                    {errors.property_id}
                                  </p>
                                </div>
                              </div>

                              <div className="w-1/3 px-3 z-20">
                                <SettingsSelect
                                  label="UNIT TYPE"
                                  name="unit_type"
                                  required
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                  value={values.unit_type}
                                  touched={touched.unit_type}
                                  error={errors.unit_type}
                                  options={unitTypes}
                                />
                              </div>

                              <div className="w-1/3 px-3 z-20">
                                <SettingsSelect
                                  label="FLOOR"
                                  name="floor_id"
                                  required
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                  value={values.floor_id}
                                  touched={touched.floor_id}
                                  error={errors.floor_id}
                                  options={floors}
                                />
                              </div>
                              {/* {(props.current_booking.user?.profile?.gender > 0 && props.current_booking.unit?.floor?.id > 0 && props.current_booking.unit?.floor?.gender != props.current_booking.user?.profile?.gender) &&
                                        <>
                                        <h3 className="text-red-700 font-bold">This user is currently assigned to a bed that doesn't match their gender.</h3>
                                        <p>To reassign them to a matching floor, use the search button to find them a suitable bed.</p>
                                        </>
                                    } */}
                            </div>

                            <div>
                              <button
                                type="submit"
                                className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                              >
                                SEARCH
                              </button>
                            </div>
                            <div>
                              {props.current_booking.waiting_unit_id > 0 && (
                                <h2 className="">This user is on a waitlist</h2>
                              )}
                            </div>
                          </div>

                          {map !== "" &&
                            blocked !== "" &&
                            moved_in_blocked !== "" && (
                              <div
                                style={{ height: "90vh" }}
                                className="frontend_svg"
                              >
                                <SvgLoader
                                  path={
                                    process.env.REACT_APP_LARAVEL_URL +
                                    "api/get_svg/" +
                                    map
                                  }
                                >
                                  <SvgProxy
                                    selector=".mapped"
                                    fill="rgb(227,227,227)"
                                    onClick={(e) => [
                                      setChosenUnit(
                                        e.currentTarget.getAttribute("unitid")
                                      ),
                                      setReservedClick(false),
                                      getUnitInfo(
                                        e.currentTarget.getAttribute("unitid")
                                      ),
                                    ]}
                                  />
                                  <SvgProxy
                                    selector=".room > g > rect.room_inner.highlighted, .room > g > path.room_inner.highlighted, .room
                                                > g > rect.room_container.highlighted, .room > g > path.room_container.highlighted"
                                    fill="#2ceaeb"
                                  />
                                  <SvgProxy selector=".taken" fill="#ff0000" />
                                  <SvgProxy
                                    selector=".resident_reserved"
                                    fill="black"
                                  />

                                  <SvgProxy
                                    selector={blocked}
                                    fill="red"
                                    onClick={(e) => [
                                      setChosenUnit(
                                        e.currentTarget.getAttribute("unitid")
                                      ),
                                      setReservedClick(true),
                                      getUnitInfo(
                                        e.currentTarget.getAttribute("unitid")
                                      ),
                                    ]}
                                  />

                                  <SvgProxy
                                    selector={moved_in_blocked}
                                    fill="deeppink"
                                    onClick={() => alert("blocked")}
                                  />

                                  <SvgProxy
                                    selector={
                                      "#unit_" + props.current_booking?.unit_id
                                    }
                                    fill="green"
                                  />

                                  <SvgProxy
                                    selector={
                                      "#bed_" +
                                      props.current_booking.waiting_bed_id
                                    }
                                    fill="purple"
                                  />
                                </SvgLoader>
                              </div>
                            )}
                        </Form>
                      )}
                    </Formik>
                  )}

                  {chosenUnit &&
                  chosenUnit > 0 &&
                  (bookingsActive.length > 0 || waitlist.length > 0) ? (
                    <>
                      <h2 className="bg-gray-200 mt-5 p-5 text-lg font-medium border-indigo-600 border-x-2 border-t-2">
                        Current Bed Bookings
                      </h2>
                      <div className="flex bg-gray-200 px-5 border-indigo-600 border-x-2 border-b-2 mb-2">
                        <div className="w-1/2">
                          <h2 className="text-lg font-medium text-primary mb-4">
                            Booking History
                          </h2>

                          {bookingsActive?.map((b) => (
                            <div key={b.id}>
                              {b.booking_reference} {b.user?.name}
                              <br />
                              Booked: {b.created_at}
                              <br />
                              Paid:{" "}
                              {b.rates?.length > 0
                                ? b.rates[b.rates.length - 1].paid === 1
                                  ? "Yes"
                                  : "No"
                                : "No"}
                              <br />
                              Lease Start Date:{" "}
                              {b.rates[b.rates.length - 1] &&
                              b.rates[b.rates.length - 1].lease_start
                                ? b.rates[b.rates.length - 1].lease_start
                                : lease_start_string}
                              <br />
                              Lease End Date:{" "}
                              {b.rates[b.rates.length - 1] &&
                              b.rates[b.rates.length - 1].lease_end
                                ? b.rates[b.rates.length - 1].lease_end
                                : lease_end_string}
                              <br />
                              Lease Signed:{" "}
                              {b.rates?.length > 0
                                ? b.rates[b.rates.length - 1]
                                    .signed_lease_at !== ""
                                  ? b.rates[b.rates.length - 1].signed_lease_at
                                  : "No"
                                : "No"}
                              <br />
                              <br />
                            </div>
                          ))}
                        </div>

                        <div className="w-1/2">
                          <h2 className="text-lg font-medium text-primary mb-4">
                            Waiting List
                          </h2>
                          {waitlist?.map((b) => (
                            <div key={b.id}>
                              {b.booking_reference} {b.user?.name}
                              <br />
                              Booked: {b.created_at}
                              <br />
                              Paid:{" "}
                              {b.rates?.length > 0
                                ? b.rates[b.rates.length - 1].paid === 1
                                  ? "Yes"
                                  : "No"
                                : "No"}
                              <br />
                              Lease Start Date:{" "}
                              {b.rates[b.rates.length - 1] &&
                              b.rates[b.rates.length - 1].lease_start
                                ? b.rates[b.rates.length - 1].lease_start
                                : lease_start_string}
                              <br />
                              Lease End Date:{" "}
                              {b.rates[b.rates.length - 1] &&
                              b.rates[b.rates.length - 1].lease_end
                                ? b.rates[b.rates.length - 1].lease_end
                                : lease_end_string}
                              <br />
                              Lease Signed:{" "}
                              {b.rates?.length > 0
                                ? b.rates[b.rates.length - 1]
                                    .signed_lease_at !== ""
                                  ? b.rates[b.rates.length - 1].signed_lease_at
                                  : "No"
                                : "No"}
                              <br />
                              <br />
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
