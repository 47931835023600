import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Formik, Form, Field, FieldArray } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";

import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import { getBooking } from "../../store/booking/bookingActions";

import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ModalCustomTba(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { current_booking } = useSelector((state) => state.booking);
  const [default_tba, setDefault_tba] = useState({
    replace_window_pane: "",
    kitchen_counter_top: "",
    bedroom_cupboards: "",
    kitchen_cupboards: "",
    bathroom_cabinet: "",
    cupboard_handles: "",
    cupboard_hinges: "",
    drawer_slides: "",
    loose_shelves: "",
    shelf_studs: "",
    door_handles: "",
    repair_doors: "",
    extra_move_out: [],
  });

  const { current_staff } = useSelector((state) => state.auth);

  useEffect(async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_tba_pricing/${current_booking.id}`,
    }).then((response) => {
      if (response.data != "") {
        response.data[0].extra_move_out =
          response.data[1].length > 0 ? response.data[1] : [];
        setDefault_tba(response.data[0]);
      }
    });
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={regular("times")}
                    />
                  </button>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    ></Dialog.Title>

                    <Formik
                      initialValues={default_tba}
                      enableReinitialize="true"
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setSubmitting(true);

                        values.booking_id = current_booking.id;
                        values.user_id = current_booking.user.id;

                        await axios({
                          method: "post",
                          url: `${process.env.REACT_APP_API_URL}set_tba_pricing`,
                          data: values,
                        })
                          .then(async (response) => {
                            toast(
                              <NotificationToast
                                title={"Pricing"}
                                message="Pricing Updated"
                              />
                            );
                          })
                          .catch((response) => {
                            toast(
                              <NotificationToast
                                title={"Something went wrong"}
                                message={response.response.data.message}
                                type="error"
                              />
                            );
                          })
                          .finally(() => {
                            setSubmitting(false);
                            dispatch(closeModal());
                          });
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        setValues,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="space-y-8 m-4 w-full "
                        >
                          <h2>Custom TBA Pricing</h2>

                          <div className="flex">
                            <div className="mr-6">
                              <Field
                                name="replace_window_pane"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Replace Window Pane"
                              />
                            </div>
                            <div className="mr-6">
                              <Field
                                name="kitchen_counter_top"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Kitchen Counter Top"
                              />
                            </div>
                            <div className="mr-6">
                              <Field
                                name="bedroom_cupboards"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Bedroom Cupboards"
                              />
                            </div>
                            <div className="mr-6">
                              <Field
                                name="kitchen_cupboards"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Kitchen Cupboards"
                              />
                            </div>
                          </div>
                          <br />
                          <br />

                          <div className="flex">
                            <div className="mr-6">
                              <Field
                                name="bathroom_cabinet"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Bathroom Cabinet"
                              />
                            </div>
                            <div className="mr-6">
                              <Field
                                name="cupboard_handles"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Cupboard handles"
                              />
                            </div>
                            <div className="mr-6">
                              <Field
                                name="cupboard_hinges"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Cupboard hinges"
                              />
                            </div>
                            <div className="mr-6">
                              <Field
                                name="drawer_slides"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Drawer slides"
                              />
                            </div>
                          </div>

                          <br />
                          <br />

                          <div className="flex">
                            <div className="mr-6">
                              <Field
                                name="loose_shelves"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Cupboards Loose Shelves"
                              />
                            </div>
                            <div className="mr-6">
                              <Field
                                name="shelf_studs"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Cupboards Shelf Studs"
                              />
                            </div>
                            <div className="mr-6">
                              <Field
                                name="door_handles"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Cupboards Door handless"
                              />
                            </div>
                            <div className="mr-6">
                              <Field
                                name="repair_doors"
                                prepend="R"
                                type="number"
                                as={SettingsTextInput}
                                label="Cupboards Repair doors"
                              />
                            </div>
                          </div>

                          <hr />
                          <h2 className="font-bold text-center uppercase">
                            Add custom lines to Move out form
                          </h2>

                          {!current_staff.building_manager ? (
                            <button
                              type="button"
                              className="bg-primary w-full text-white py-1 rounded"
                              onClick={() => {
                                values.extra_move_out.push({
                                  name: "",
                                  price: "",
                                });
                                setValues(values);
                              }}
                            >
                              {" "}
                              ADD{" "}
                            </button>
                          ) : (
                            ""
                          )}

                          <FieldArray
                            name="extra_move_out"
                            render={(arrayHelpers) => (
                              <div>
                                {values.extra_move_out &&
                                values.extra_move_out.length > 0
                                  ? values.extra_move_out.map(
                                      (friend, index) => (
                                        <div key={index} className="flex my-2">
                                          <Field
                                            name={`extra_move_out.${index}.name`}
                                            className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                            placeholder="Name"
                                          />
                                          <Field
                                            name={`extra_move_out.${index}.price`}
                                            type="number"
                                            className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                            placeholder="Price Per Unit"
                                          />

                                          {!current_staff.building_manager ? (
                                            <button
                                              type="button"
                                              className="bg-dark_background text-white py-2 px-5 rounded"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              {" "}
                                              DELETE{" "}
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )
                                    )
                                  : ""}
                                <div></div>
                              </div>
                            )}
                          />

                          <SubmitButton
                            name="Save"
                            disabled={!isValid || isSubmitting || !dirty}
                            isSubmitting={isSubmitting}
                            text="Update pricing"
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
