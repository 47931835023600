import React, { useEffect } from "react";
import { useField } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import moment from "moment";

export default function QuickFormDate({ ...props }) {
    const [field, meta, { setValue, setTouched }] = useField(props);

    useEffect(() => {
        if (props.name === "lease_start") {
            if (!props.value) {
                var month = moment().add(1, "M").format("MM");
                var year = moment().format("Y");

                var lease_start_string = year + "-" + month + "-01";

                setValue(lease_start_string);
            }
        }
        if (props.name === "lease_end") {
            if (!props.value) {
                var month = moment().format("MM");
                var year = moment().add(1, "Y").format("Y");

                var lease_end_string = moment(new Date(year, month, 0)).format("Y-MM-D");

                setValue(lease_end_string);
            }
        }
    }, []);

    return (
        <div className="flex flex-col sm:col-span-1">
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
                {props.label}

                {props.nationality === "ZA" && (
                    <FontAwesomeIcon
                        icon={regular("calendar-lines-pen")}
                        className="ml-4 cursor-pointer"
                        title="Calculate date of birth from ID"
                        onClick={() => {
                            var id_number = props.id_number.substring(0, 6);
                            var year = id_number.substring(0, 2);
                            var month = id_number.substring(2, 4);
                            var day = id_number.substring(4, 6);

                            var date_object = new Date(year + "/" + month + "/" + day + " 02:00");
                            setValue(date_object.toISOString());
                        }}
                    />
                )}
            </label>
            <DatePicker
                selected={field.value ? moment(field.value).toDate() : null}
                onChange={(value) => setValue(value)}
                className="z-20 rounded-lg py-2 pl-2 truncate w-full xl:w-32 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border"
                dateFormat={"d MMMM yyyy"}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={props.disabled}
            />

            <p className="text-red-400 text-sm">{props.errors}</p>
        </div>
    );
}
