import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextarea from "../Forms/SettingsTextarea";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";
import { getBooking } from "../../store/booking/bookingActions";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function ModalBrokerApplicants(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [loading, setLoading] = useState(false);
  const [users, setUser] = useState([]);
  const [mainUser, setMainUser] = useState([]);
  const { current_staff, staff } = useSelector((state) => state.auth);
  const [loadingBtns, setLoadingBtns] = useState(false);

  useEffect(async () => {
    setLoading(true);

    if (!loading && props.current_booking.id > 0) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}get_broker_applicants`,
        data: { user_id: props.current_booking.user.id },
      })
        .then((response) => {
          setUser(response.data);
          console.log(response.data);

          setLoading(false);
        })
        .catch((error) => {
          alert("broker users load failed");
        });
    }
  }, [mainUser]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <CustomButton
                    type="secondary"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    Close
                  </CustomButton>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900 mb-2 mt-2"
                    >
                      Broker Applicants Contact Information
                    </Dialog.Title>

                    <div class="grid grid-cols-3 gap-4 mb-5">
                      {users &&
                        users.length > 0 &&
                        users.map((user) => (
                          <>
                            <div className="flex">
                              <div className="col-span-3 flex grow">
                                {user.bookings && user.bookings.length > 0 && (
                                  <>
                                    {
                                      user.bookings[user.bookings.length - 1]
                                        .booking_reference
                                    }{" "}
                                    - {user.name} {user.surname}
                                  </>
                                )}
                                <br />
                                Cell: {user.cell}
                                <br />
                                Email: {user.email}
                              </div>
                              <div className="col-span-3">
                                <Link
                                  to={
                                    user.bookings[user.bookings.length - 1]
                                      .declined
                                      ? "/booking/declined?user_id=" +
                                        user.id +
                                        "&booking_id=" +
                                        user.active_booking
                                      : user.bookings[user.bookings.length - 1]
                                          .tag
                                      ? "/booking/management?tag=" +
                                        user.bookings[user.bookings.length - 1]
                                          .tag +
                                        "&user_id=" +
                                        user.id +
                                        "&booking_id=" +
                                        user.active_booking
                                      : "/booking/" +
                                          user.bookings[
                                            user.bookings.length - 1
                                          ].archived ==
                                        1
                                      ? "archived?user_id=" +
                                        user.id +
                                        "&booking_id=" +
                                        user.active_booking
                                      : user.bookings[
                                          user.bookings.length - 1
                                        ].location
                                          ?.toLowerCase()
                                          .replace(/\s/g, "") +
                                        "?user_id=" +
                                        user.id +
                                        "&booking_id=" +
                                        user.active_booking
                                  }
                                >
                                  <CustomButton
                                    text="View"
                                    styling="w-44 py-2"
                                    onClick={() => dispatch(closeModal())}
                                  />
                                </Link>
                              </div>
                            </div>

                            <div className="col-span-3">
                              <hr />
                            </div>
                          </>
                        ))}
                    </div>

                    <CustomButton
                      type="secondary"
                      text="Close"
                      onClick={() => dispatch(closeModal())}
                    />
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
