import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import * as Yup from "yup";
import { Tab } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsDatePicker from "../Forms/SettingsDatePicker";
import SettingsSwitch from "../Forms/SettingsSwitch";
import SubmitButton from "../Forms/SubmitButton";
import AppLoader from "../Helpers/AppLoader";
import PhoneInput from "react-phone-number-input";

import { faBedEmpty, faBuildingCircleCheck, faMessage } from "@fortawesome/pro-regular-svg-icons";
import { faDollarSign, faUser, faUserGroup } from "@fortawesome/pro-solid-svg-icons";
import { getBooking } from "../../store/booking/bookingActions";

const subNavigation = [
    { name: "Choose a bed", icon: faBedEmpty },
    { name: "Your Booking", icon: faBuildingCircleCheck },
    { name: "Student Details", icon: faUser },
    { name: "Next of Kin", icon: faUserGroup },
    { name: "Responsible for Funding", icon: faDollarSign },
    { name: "Referral Information", icon: faMessage },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function ApplicationFormUCEditReturning({ chosenProperty, chosenUnit, chosenBed }) {
    const dispatch = useDispatch();
    const { url } = useParams();
    const [loading, setLoading] = useState(false);
    const [fileUploadInformation, setFileUploadInformation] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [properties, setProperties] = useState([]);
    const { current_booking } = useSelector((state) => state.booking);
    const [property, setProperty] = useState([]);
    const [lookups, setLookups] = useState([]);

    useEffect(async () => {
        setLoading(true);

        if (!loading && chosenProperty > 0) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}property_info/${chosenProperty}`,
            })
                .then((response) => {
                    setProperty(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    alert("properties failed");
                });
        }

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}uc_lookups_for_students`,
        })
            .then((response) => {
                setLookups(response.data);
            })
            .catch((error) => {
                alert("lookups failed");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                alert("properties failed");
            });

        if (url) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}edit_user/${url}`,
            })
                .then((response) => {
                    const data = response.data.user;
                    if (data.bookings[0].property_id) getUnitTypes(data.bookings[0].property_id);
                    setFileUploadInformation({
                        booking_id: data.active_booking,
                        user_id: data.id,
                        added_by: data.id,
                    });

                    dispatch(getBooking(data.active_booking));

                    setInitialValues({
                        name: data.name,
                        surname: data.surname,
                        email: data.email,
                        cell: "+" + data.cell,

                        study_year: data.profile ? data.profile.study_year : "",

                        title: data.profile ? data.profile.title : "",
                        gender: data.profile ? data.profile.gender : "",
                        date_of_birth: data.profile ? data.profile.date_of_birth : "",
                        nationality: data.profile ? data.profile.nationality : "",
                        id_number: data.profile ? data.profile.id_number : "",
                        student_number: data.profile ? data.profile.student_number : "",
                        ethnicity: data.profile ? data.profile.ethnicity : "",
                        nationality: data.profile ? data.profile.nationality : "",
                        institution_study: data.profile ? data.profile.institution_study : "",
                        institution_campus: data.profile ? data.profile.institution_campus : "",
                        faculty: data.profile ? data.profile.faculty : "",

                        property_id: property.id,

                        kin_title: data.next_of_kin ? data.next_of_kin.title : "",
                        kin_name: data.next_of_kin ? data.next_of_kin.name : "",
                        kin_surname: data.next_of_kin ? data.next_of_kin.surname : "",
                        kin_relationship: data.next_of_kin ? data.next_of_kin.relationship : "",
                        kin_id_number: data.next_of_kin ? data.next_of_kin.id_number : "",
                        kin_contact: data.next_of_kin ? "+" + data.next_of_kin.contact : "",
                        kin_email: data.next_of_kin ? data.next_of_kin.email : "",
                        kin_address: data.next_of_kin ? data.next_of_kin.address : "",
                        kin_suburb: data.next_of_kin ? data.next_of_kin.suburb : "",
                        kin_city: data.next_of_kin ? data.next_of_kin.city : "",
                        kin_postal: data.next_of_kin ? data.next_of_kin.postal : "",
                        kin_tel_contact: data.next_of_kin ? "+" + data.next_of_kin.tel_contact : "",
                        kin_work_contact: data.next_of_kin ? "+" + data.next_of_kin.work_contact : "",

                        parent_name: data.parent ? data.parent.name : "",
                        parent_surname: data.parent ? data.parent.surname : "",
                        parent_cell: data.parent ? (data.parent.cell ? "+" + data.parent.cell : "") : "",
                        parent_id_number: data.parent ? data.parent.id_number : "",
                        parent_email: data.parent ? data.parent.email : "",

                        referral_name: data.premises ? data.premises.name : "",
                        referral_surname: data.premises ? data.premises.surname : "",
                        referral_contact: data.premises ? (data.premises.contact ? "+" + data.premises.contact : "") : "",
                        referral_id_number: data.premises ? data.premises.id_number : "",
                        referral: data.premises ? data.premises.referral : "",
                        referral_other: data.premises ? data.premises.referral_other : "",
                    });
                })
                .catch((error) => {
                    window.location.href = "/404";
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [setProperties, setInitialValues]);

    if (loading === true) return <AppLoader />;

    async function getUnitTypes(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
        }).then((response) => {
            setUnitTypes(response.data);
        });
    }

    return (
        <div>
            <div className="uc">
                <div className="pb-32 elative bg-dark_background">
                    <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                        <div className="flex items-center">
                            <img className="h-24" src="/images/logo_uc.svg" alt="Urban Circle" />
                            <div className="py-6 lg:py-0 mx-auto md:w-96 text-center text-white text-4xl uppercase font-bold">
                                <h1>Edit your booking</h1>
                            </div>
                            <div>
                                <a
                                    target="_blank"
                                    href="https://api.whatsapp.com/send?phone=270871632011"
                                    className="h-12 rounded-full text-white px-5 flex justify-between items-center font-bold"
                                    style={{ background: "#62D43F" }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.364" height="25.364" viewBox="0 0 25.364 25.364" className="mr-5">
                                        <path
                                            id="Icon_simple-whatsapp"
                                            data-name="Icon simple-whatsapp"
                                            d="M18.492,15.2c-.318-.159-1.867-.916-2.156-1.021s-.5-.159-.711.159-.815,1.019-1,1.228-.369.222-.683.079a8.577,8.577,0,0,1-2.54-1.569,9.588,9.588,0,0,1-1.754-2.188c-.184-.317-.02-.491.137-.65.144-.143.318-.365.477-.553a2.679,2.679,0,0,0,.314-.524.581.581,0,0,0-.026-.554c-.079-.159-.71-1.712-.974-2.331s-.515-.539-.71-.539c-.182-.016-.392-.016-.6-.016a1.191,1.191,0,0,0-.842.379,3.528,3.528,0,0,0-1.1,2.616,6.184,6.184,0,0,0,1.288,3.25A14.092,14.092,0,0,0,13,17.705c.755.317,1.342.507,1.8.665a4.372,4.372,0,0,0,1.987.128,3.252,3.252,0,0,0,2.131-1.507,2.616,2.616,0,0,0,.19-1.506c-.078-.143-.285-.222-.6-.365m-5.756,7.866H12.73a10.572,10.572,0,0,1-5.342-1.458l-.38-.226-3.963,1.03L4.106,18.48l-.253-.4A10.482,10.482,0,0,1,20.178,5.153a10.344,10.344,0,0,1,3.074,7.387,10.5,10.5,0,0,1-10.5,10.447M21.686,3.645A12.832,12.832,0,0,0,12.73,0,12.565,12.565,0,0,0,1.792,18.852L0,25.364l6.7-1.746a12.748,12.748,0,0,0,6.035,1.529h.006A12.619,12.619,0,0,0,25.364,12.575,12.465,12.465,0,0,0,21.67,3.686"
                                            fill="#fff"
                                        />
                                    </svg>
                                    <span className="mr-3">NEED HELP?</span>
                                </a>
                            </div>
                        </div>
                    </header>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                enableReinitialize="true"
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={Yup.object().shape({
                    title: Yup.number().required("title"),
                    name: Yup.string().required("name"),
                    surname: Yup.string().required("surname"),
                    email: Yup.string().required("email"),
                    date_of_birth: Yup.string().required("birthday"),
                    ethnicity: Yup.string().required("ethnicity"),
                    nationality: Yup.string().required("nationality"),
                    cell: Yup.string().required("cell"),
                    id_number: Yup.string().required("id_number"),
                    institution_study: Yup.string().required("institution_study"),
                    institution_campus: Yup.string().required("institution_campus"),
                    faculty: Yup.string().required("faculty"),
                    popi: Yup.string().required("confirmation to the POPI Act"),
                    kin_title: Yup.string().required("kin_title"),
                    kin_name: Yup.string().required("kin_name"),
                })}
                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                    setSubmitting(true);

                    let api_method = "post";
                    if (url) {
                        api_method = "put";
                        values.url = url;
                    }

                    //format cell
                    values.cell = values.cell.replace("+", "");
                    values.kin_tel_contact = values.kin_tel_contact.replace("+", "");
                    values.kin_contact = values.kin_contact.replace("+", "");
                    values.kin_work_contact = values.kin_work_contact.replace("+", "");

                    if (values.referral_contact) {
                        values.referral_contact = values.referral_contact.replace("+", "");
                    }

                    if (values.parent_cell) {
                        values.parent_cell = values.parent_cell.replace("+", "");
                    }

                    await axios({
                        method: api_method,
                        url: `${process.env.REACT_APP_API_URL}user`,
                        data: values,
                    })
                        .then((response) => {
                            if (url) {
                                toast(
                                    <NotificationToast
                                        title={"Application Updated"}
                                        message="Your application has succesfully been updated. Keep an eye out on your email and cellphone for future communication"
                                    />
                                );
                            } else {
                                toast(
                                    <NotificationToast
                                        title={"Application Successful. Redirecting to Edit form"}
                                        message="Your application has succesfully been created. Keep an eye out on your email and cellphone for future communication"
                                    />
                                );
                                window.onbeforeunload = function () {};
                                setTimeout(() => {
                                    window.location = window.location.origin + "/edit_application/" + response.data;
                                }, 3000);
                            }

                            //Add the + back for form prettiness
                            values.cell = "+" + values.cell;
                            values.kin_tel_contact = "+" + values.kin_tel_contact;
                            values.kin_contact = "+" + values.kin_contact;
                            values.kin_work_contact = "+" + values.kin_work_contact;

                            if (values.referral_contact) {
                                values.referral_contact = "+" + values.referral_contact;
                            }

                            if (values.parent_cell) {
                                values.parent_cell = "+" + values.parent_cell;
                            }
                        })
                        .catch((response) => {
                            toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                        });
                }}
            >
                {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                    <Form onSubmit={handleSubmit}>
                        <div>
                            <div style={{ marginLeft: "-200px", marginRight: "200px", paddingLeft: "200px", borderTopRightRadius: "50px" }} className="bg-white min-h-screen shadow-md rounded-tl-3xl">
                                <div className="h-20 flex">
                                    <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                        <div className="text-white text-2xl font-bold uppercase">Update your information</div>
                                    </div>
                                    <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                                </div>

                                <div className="mx-auto bg-white max-w-7xl">
                                    <main className="mt-14">
                                        <div className="max-w-7xl mx-auto pb-2 px-4">
                                            <div className="w-full">
                                                <div className="text-right">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        text={url ? "Update Application" : "Submit Application"}
                                                        style={{
                                                            fontWeight: 700,
                                                            borderRadius: 20,
                                                            marginBottom: 20,
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    {Object.keys(errors).length > 0 && (
                                                        <>
                                                            <p className="mt-6 text-red-500 text-lg font-bold">Cannot apply!</p>{" "}
                                                            <p className="my-2 text-red-500 text-sm">Your application has errors that needs to be resolved below:</p>
                                                            <ul role="lis" className="pt-4 px-8 mx-auto w-full text-orange-500 grid grid-cols-2 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                                                                {Object.keys(errors).map((key) => (
                                                                    <li key={key} className="col-span-1 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                        We require your <span className="font-bold italic">{errors[key]}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </>
                                                    )}
                                                </div>

                                                <div className="xl:grid xl:grid-cols-12">
                                                    <Tab.Group>
                                                        <Tab.List
                                                            className="pb-6 lg:pb-24 bg-light_background xl:col-span-2 w-full xl:w-72 border-0"
                                                            style={{ borderTopLeftRadius: "32px", borderBottomLeftRadius: "32px" }}
                                                        >
                                                            {subNavigation.map((item) => (
                                                                <Tab
                                                                    key={item.name}
                                                                    className={({ selected }) =>
                                                                        classNames(
                                                                            "group w-full text-sm flex items-center hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none mb-6 rounded-l-full",
                                                                            selected ? "bg-gray-100 text-primary" : "bg-light_background"
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="bg-primary mr-5 h-16 w-16 rounded-full flex items-center justify-center text-white">
                                                                        <FontAwesomeIcon icon={item.icon} aria-hidden="true" style={{ fontSize: "22px" }} />
                                                                    </div>
                                                                    {item.name}
                                                                </Tab>
                                                            ))}
                                                        </Tab.List>
                                                        <Tab.Panels
                                                            className="xl:ml-20 my-6 xl:my-0 bg-gray-100 xl:col-span-10"
                                                            style={{ borderTopRightRadius: "32px", borderBottomRightRadius: "32px" }}
                                                        >
                                                            <Tab.Panel className="xl:col-span-9 pb-12">
                                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-2 gap-12 xl:px-6 uppercase">Map SVG to show here</div>
                                                            </Tab.Panel>
                                                            <Tab.Panel className="xl:col-span-9 pb-12">
                                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-2 gap-12 xl:px-6 uppercase">
                                                                    <div>
                                                                        <div>BUILDING</div>
                                                                        <div className="mb-5">{property.name}</div>

                                                                        <div>FLOOR</div>
                                                                        <div className="mb-5">xxx</div>

                                                                        <div>Bed</div>
                                                                        <div className="mb-5">xxx</div>
                                                                    </div>

                                                                    <div>
                                                                        <div>MONTHLY RENTAL</div>
                                                                        <div className="mb-5">xxx</div>

                                                                        <div>BOOKING FEE / DEPOSIT</div>
                                                                        <div className="mb-5">xxx</div>

                                                                        <SettingsSelect
                                                                            label="Study Year"
                                                                            name="study_year"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            value={values.study_year}
                                                                            touched={touched.study_year}
                                                                            error={errors.study_year}
                                                                            options={lookups ? lookups[8] : null}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Tab.Panel>

                                                            <Tab.Panel className="xl:col-span-9 pb-12">
                                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                                    <Field name="name" type="text" as={SettingsTextInput} label="First Name" required />

                                                                    <Field name="surname" type="text" as={SettingsTextInput} label="Surname" required />

                                                                    <Field name="email" type="email" as={SettingsTextInput} label="Email Address" required />

                                                                    <SettingsSelect
                                                                        label="Gender"
                                                                        name="gender"
                                                                        required
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.gender}
                                                                        touched={touched.gender}
                                                                        error={errors.gender}
                                                                        options="gender"
                                                                    />

                                                                    <SettingsSelect
                                                                        label="Title"
                                                                        name="title"
                                                                        required
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.title}
                                                                        touched={touched.title}
                                                                        error={errors.title}
                                                                        options="titles"
                                                                    />

                                                                    <Field
                                                                        name="date_of_birth"
                                                                        type="text"
                                                                        as={SettingsDatePicker}
                                                                        label="Birthday"
                                                                        id_number={values.id_number}
                                                                        nationality={values.nationality}
                                                                        required
                                                                    />

                                                                    {/* <Field
                                                                        name="cell"
                                                                        type="text"
                                                                        as={ SettingsTextInput }
                                                                        prepend="+"
                                                                        label="Cellphone Number"
                                                                        required
                                                                    /> */}

                                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                        <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                            <span>Cellphone Number</span>
                                                                            {errors.cell && <p className="text-xs font-light text-red-400">required</p>}
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <PhoneInput
                                                                                defaultCountry="ZA"
                                                                                countryCallingCodeEditable={false}
                                                                                onChange={(val) => {
                                                                                    setFieldValue("cell", val);
                                                                                }}
                                                                                onBlur={setFieldTouched}
                                                                                name="cell"
                                                                                value={values.cell}
                                                                            />
                                                                            <p className="text-red-400 text-sm font-light">{errors.cell}</p>
                                                                        </div>
                                                                    </div>

                                                                    <Field name="student_number" type="text" as={SettingsTextInput} label="Student Number" />

                                                                    <SettingsSelect
                                                                        label="Ethnicity"
                                                                        name="ethnicity"
                                                                        required
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.ethnicity}
                                                                        touched={touched.ethnicity}
                                                                        error={errors.ethnicity}
                                                                        options={lookups ? lookups[2] : null}
                                                                    />

                                                                    <SettingsSelect
                                                                        label="Nationality"
                                                                        name="nationality"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.nationality}
                                                                        touched={touched.nationality}
                                                                        error={errors.nationality}
                                                                        options="nationality"
                                                                        required
                                                                    />
                                                                    <Field
                                                                        name="id_number"
                                                                        type="text"
                                                                        as={SettingsTextInput}
                                                                        label="ID or Passport Number"
                                                                        required
                                                                        nationality={values.nationality}
                                                                    />

                                                                    <SettingsSelect
                                                                        label="Institution of Study"
                                                                        name="institution_study"
                                                                        required
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.institution_study}
                                                                        touched={touched.institution_study}
                                                                        error={errors.institution_study}
                                                                        options={lookups ? lookups[4] : null}
                                                                    />
                                                                    <SettingsSelect
                                                                        label="Institution Campus"
                                                                        name="institution_campus"
                                                                        required
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.institution_campus}
                                                                        touched={touched.institution_campus}
                                                                        error={errors.institution_campus}
                                                                        options={lookups ? lookups[5] : null}
                                                                    />

                                                                    <Field name="faculty" required type="text" as={SettingsTextInput} label="Faculty" />

                                                                    {/* <Field
                                                                        name="password"
                                                                        type="password"
                                                                        as={ SettingsTextInput }
                                                                        label="Change Password"
                                                                    />

                                                                    <Field
                                                                        name="password_confirmation"
                                                                        type="password"
                                                                        as={ SettingsTextInput }
                                                                        label="Confirm Change of Password"
                                                                    /> */}
                                                                </div>

                                                                <div className="py-8 px-4 xl:px-6 uppercase">
                                                                    <Field
                                                                        name="popi"
                                                                        as={SettingsSwitch}
                                                                        required
                                                                        label="I Agree to the POPI ACT"
                                                                        description="QPG Holdings Proprietary Limited and its subsidiaries, which includes Urban Circle, is compliant with the Protection of Personal Information Act (POPIA), also known as the POPI Act, which is mandatory for all organisations in South Africa. POPI makes it illegal for us to collect, use or store the personal information of customers and businesses unless it is done in accordance with the laws and regulations prescribed in the POPI Act."
                                                                    />
                                                                </div>
                                                            </Tab.Panel>

                                                            <Tab.Panel className="xl:col-span-9 pb-12">
                                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                                    <SettingsSelect
                                                                        label="Title"
                                                                        name="kin_title"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.kin_title}
                                                                        touched={touched.kin_title}
                                                                        error={errors.kin_title}
                                                                        options="titles"
                                                                    />
                                                                    <Field name="kin_name" type="text" as={SettingsTextInput} label="Name" />
                                                                    <Field name="kin_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                                    <Field name="kin_id_number" type="text" as={SettingsTextInput} label="ID Number" />
                                                                    <Field name="kin_address" type="text" as={SettingsTextInput} label="Address" />
                                                                    <Field name="kin_suburb" type="text" as={SettingsTextInput} label="Suburb" />
                                                                    <Field name="kin_city" type="text" as={SettingsTextInput} label="City" />
                                                                    <Field name="kin_postal" type="text" as={SettingsTextInput} label="Postal Code" />
                                                                    <Field name="kin_relationship" type="text" as={SettingsTextInput} label="Relationship" />
                                                                    {/* <Field
                                                                        name="kin_tel_contact"
                                                                        type="text"
                                                                        as={ SettingsTextInput }
                                                                        label="Telephone Number"
                                                                        prepend="+"
                                                                    /> */}
                                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                        <label htmlFor="kin_tel_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                            <span>Telephone Number</span>
                                                                            {errors.kin_tel_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <PhoneInput
                                                                                defaultCountry="ZA"
                                                                                countryCallingCodeEditable={false}
                                                                                onChange={(val) => {
                                                                                    setFieldValue("kin_tel_contact", val);
                                                                                }}
                                                                                onBlur={setFieldTouched}
                                                                                name="kin_tel_contact"
                                                                                value={values.kin_tel_contact}
                                                                            />
                                                                            <p className="text-red-400 text-sm font-light">{errors.kin_tel_contact}</p>
                                                                        </div>
                                                                    </div>
                                                                    {/* <Field
                                                                        name="kin_contact"
                                                                        type="text"
                                                                        as={ SettingsTextInput }
                                                                        label="Cellphone Number"
                                                                        prepend="+"
                                                                    /> */}
                                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                        <label htmlFor="kin_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                            <span>Cellphone Number</span>
                                                                            {errors.kin_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <PhoneInput
                                                                                defaultCountry="ZA"
                                                                                countryCallingCodeEditable={false}
                                                                                onChange={(val) => {
                                                                                    setFieldValue("kin_contact", val);
                                                                                }}
                                                                                onBlur={setFieldTouched}
                                                                                name="kin_contact"
                                                                                value={values.kin_contact}
                                                                            />
                                                                            <p className="text-red-400 text-sm font-light">{errors.kin_contact}</p>
                                                                        </div>
                                                                    </div>
                                                                    {/* <Field
                                                                        name="kin_work_contact"
                                                                        type="text"
                                                                        as={ SettingsTextInput }
                                                                        label="Work Number"
                                                                        prepend="+"
                                                                    /> */}
                                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                        <label htmlFor="kin_work_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                            <span>Work Number</span>
                                                                            {errors.kin_work_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <PhoneInput
                                                                                defaultCountry="ZA"
                                                                                countryCallingCodeEditable={false}
                                                                                onChange={(val) => {
                                                                                    setFieldValue("kin_work_contact", val);
                                                                                }}
                                                                                onBlur={setFieldTouched}
                                                                                name="kin_work_contact"
                                                                                value={values.kin_work_contact}
                                                                            />
                                                                            <p className="text-red-400 text-sm font-light">{errors.kin_work_contact}</p>
                                                                        </div>
                                                                    </div>
                                                                    <Field name="kin_email" type="email" as={SettingsTextInput} label="Email" />
                                                                </div>
                                                            </Tab.Panel>

                                                            <Tab.Panel className="xl:col-span-9 pb-12">
                                                                <h2 className="pt-6 px-4 text-center leading-6 font-light text-dark_gray capitalize">PARENT / GUARDIANS / SPONSOR / BURSARY DETAILS</h2>

                                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                                    <Field name="parent_name" type="text" as={SettingsTextInput} label="First Name" />
                                                                    <Field name="parent_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                                    {/* <Field
                                                                        name="parent_cell"
                                                                        type="text"
                                                                        as={ SettingsTextInput }
                                                                        prepend="+"
                                                                        label="Cellphone Number"

                                                                    /> */}
                                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                        <label htmlFor="parent_cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                            <span>Cellphone Number</span>
                                                                            {errors.parent_cell && <p className="text-xs font-light text-red-400">required</p>}
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <PhoneInput
                                                                                defaultCountry="ZA"
                                                                                countryCallingCodeEditable={false}
                                                                                onChange={(val) => {
                                                                                    setFieldValue("parent_cell", val);
                                                                                }}
                                                                                onBlur={setFieldTouched}
                                                                                name="parent_cell"
                                                                                value={values.parent_cell}
                                                                            />
                                                                            <p className="text-red-400 text-sm font-light">{errors.parent_cell}</p>
                                                                        </div>
                                                                    </div>
                                                                    <Field name="parent_id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" />
                                                                </div>

                                                                <div className="pb-8 px-4 xl:px-6">
                                                                    <Field name="parent_email" type="email" as={SettingsTextInput} label="Email Address" />
                                                                </div>
                                                            </Tab.Panel>

                                                            <Tab.Panel className="xl:col-span-9 pb-12">
                                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                                    <Field name="referral_name" as={SettingsTextInput} label="First Name" />
                                                                    <Field name="referral_surname" as={SettingsTextInput} label="Surname" />
                                                                    {/* <Field
                                                                        name="referral_contact"
                                                                        as={ SettingsTextInput }
                                                                        label="Cellphone Number"
                                                                        prepend="+"
                                                                    /> */}
                                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                        <label htmlFor="referral_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                            <span>Cellphone Number</span>
                                                                            {errors.referral_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <PhoneInput
                                                                                defaultCountry="ZA"
                                                                                countryCallingCodeEditable={false}
                                                                                onChange={(val) => {
                                                                                    setFieldValue("referral_contact", val);
                                                                                }}
                                                                                onBlur={setFieldTouched}
                                                                                name="referral_contact"
                                                                                value={values.referral_contact}
                                                                            />
                                                                            <p className="text-red-400 text-sm font-light">{errors.referral_contact}</p>
                                                                        </div>
                                                                    </div>
                                                                    <Field name="referral_id_number" as={SettingsTextInput} label="ID. Number" />
                                                                </div>

                                                                <div className="pb-8 px-4 xl:px-6 uppercase">
                                                                    <SettingsSelect
                                                                        label="Referral Type"
                                                                        name="referral"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.referral}
                                                                        touched={touched.referral}
                                                                        error={errors.referral}
                                                                        options={lookups ? lookups[1] : null}
                                                                    />
                                                                    {values.referral === 50 && <Field name="referral_other" as={SettingsTextInput} label="What is the other Referral?" />}
                                                                </div>
                                                            </Tab.Panel>
                                                        </Tab.Panels>
                                                    </Tab.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
