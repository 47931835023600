import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextarea from "../Forms/SettingsTextarea";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";
import { getBooking } from "../../store/booking/bookingActions";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";

export default function ModalMergedApplicants(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [loading, setLoading] = useState(false);
  const [users, setUser] = useState([]);
  const [mainUser, setMainUser] = useState([]);
  const { current_staff, staff } = useSelector((state) => state.auth);
  const [loadingBtns, setLoadingBtns] = useState(false);

  useEffect(async () => {
    setLoading(true);

    if (!loading && props.current_booking.id > 0) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}get_merged_users`,
        data: { user_id: props.current_booking.user.id },
      })
        .then((response) => {
          setUser(response.data[0]);
          setMainUser(response.data[1]);

          console.log(response.data[0]);

          setLoading(false);
        })
        .catch((error) => {
          alert("merged users load failed");
        });
    }
  }, [mainUser]);

  async function updateNewPrimary(type, value, mainValue) {
    setLoading(true);
    setLoadingBtns(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}update_merged_user`,
      data: {
        booking_id: props.current_booking.id,
        user_id: props.current_booking.user.id,
        type: type,
        value: value,
        current_staff: current_staff,
        mainValue: mainValue,
      },
    })
      .then((response) => {
        setMainUser(response.data);
        dispatch(getBooking(props.current_booking.id));
        setLoading(false);
        setLoadingBtns(false);
        toast(
          <NotificationToast
            title={"Application Updated"}
            message="Application has succesfully been updated."
          />
        );
      })
      .catch((error) => {
        alert("update merged users failed");
      });

    setLoading(false);
  }

  async function unmergeApplicant(user_id, main_id, main_booking) {
    setLoading(true);
    setLoadingBtns(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}unmerge_applicants`,
      data: { user_id: user_id, main_id: main_id, main_booking: main_booking },
    })
      .then((response) => {
        dispatch(getBooking(props.current_booking.id));
        setLoading(false);
        setLoadingBtns(false);
        dispatch(closeModal());
        toast(
          <NotificationToast
            title={"Applicant Un-merged"}
            message="Un-merge has succesfully been completed."
          />
        );
      })
      .catch((error) => {
        alert("un-merge user failed");
      });

    setLoading(false);
  }

  // dispatch(getBooking)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <CustomButton
                    type="secondary"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    Close
                  </CustomButton>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900 mb-2"
                    >
                      Primary Contact Information
                    </Dialog.Title>

                    <div class="grid grid-cols-3 gap-4 mb-5">
                      <div className="sm:col-span-1 col-span-3">
                        <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                          <span>Cell</span>
                        </label>
                        <input
                          type="text"
                          name="cell"
                          value={mainUser.cell}
                          disabled
                          className="rounded-lg block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                        />
                      </div>

                      <div className="sm:col-span-1 col-span-3">
                        <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                          <span>Email</span>
                        </label>
                        <input
                          type="text"
                          name="email"
                          value={mainUser.email}
                          disabled
                          className="rounded-lg block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                        />
                      </div>

                      <div className="sm:col-span-1 col-span-3">
                        <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                          <span>ID Number</span>
                        </label>
                        <input
                          type="text"
                          name="email"
                          value={
                            mainUser.profile ? mainUser.profile.id_number : ""
                          }
                          disabled
                          className="rounded-lg block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                        />
                      </div>
                    </div>
                    <hr />

                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900 mb-2 mt-2"
                    >
                      Merged Contact Information
                    </Dialog.Title>

                    <div class="grid grid-cols-3 gap-4 mb-5">
                      {users &&
                        users.length > 0 &&
                        users.map((user) => (
                          <>
                            <div className="col-span-3">
                              {user.bookings && user.bookings.length > 0 && (
                                <>
                                  {
                                    user.bookings[user.bookings.length - 1]
                                      .booking_reference
                                  }{" "}
                                  - {user.name} {user.surname}
                                </>
                              )}
                            </div>
                            <div className="sm:col-span-1 col-span-3">
                              <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                <span>Cell</span>
                              </label>
                              <div className="flex">
                                <input
                                  type="text"
                                  name="cell"
                                  value={user.cell}
                                  disabled
                                  className="rounded-lg block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                                />
                                <CustomButton
                                  text="Make Primary"
                                  styling="w-44 py-2"
                                  onClick={() =>
                                    updateNewPrimary(
                                      "cell",
                                      user.cell,
                                      mainUser.cell
                                    )
                                  }
                                  loading={loadingBtns}
                                  disabled={loadingBtns}
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-1 col-span-3">
                              <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                <span>Email</span>
                              </label>
                              <div className="flex">
                                <input
                                  type="text"
                                  name="email"
                                  value={user.email}
                                  disabled
                                  className="rounded-lg block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                                />
                                <CustomButton
                                  text="Make Primary"
                                  styling="w-44 py-2"
                                  onClick={() =>
                                    updateNewPrimary(
                                      "email",
                                      user.email,
                                      mainUser.email
                                    )
                                  }
                                  loading={loadingBtns}
                                  disabled={loadingBtns}
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-1 col-span-3">
                              <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                <span>ID Number</span>
                              </label>
                              <div className="flex">
                                <input
                                  type="text"
                                  name="email"
                                  value={
                                    user.profile ? user.profile.id_number : ""
                                  }
                                  disabled
                                  className="rounded-lg block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                                />
                                <CustomButton
                                  text="Make Primary"
                                  styling="w-44 py-2"
                                  onClick={() =>
                                    updateNewPrimary(
                                      "id_number",
                                      user.profile.id_number,
                                      mainUser.profile
                                        ? mainUser.profile.id_number
                                        : ""
                                    )
                                  }
                                  loading={loadingBtns}
                                  disabled={loadingBtns}
                                />
                              </div>
                            </div>
                            <div className="col-span-3">
                              <CustomButton
                                type="secondary"
                                text="Un-Merge Applicant"
                                styling="w-full py-2"
                                onClick={() =>
                                  unmergeApplicant(
                                    user.id,
                                    mainUser.id,
                                    mainUser.active_booking
                                  )
                                }
                                loading={loadingBtns}
                                disabled={loadingBtns}
                              />
                            </div>
                            <div className="col-span-3">
                              <hr />
                            </div>
                          </>
                        ))}
                    </div>

                    <CustomButton
                      type="secondary"
                      text="Close"
                      onClick={() => dispatch(closeModal())}
                    />
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
