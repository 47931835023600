import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import axios from "axios";
import AppLoader from "../Helpers/AppLoader";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

export default function PaymentForm({ showbtn }) {
    const dispatch = useDispatch();
    const { url } = useParams();
    const [loading, setLoading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [booking, setBooking] = useState({});
    const [paymentMade, setPaymentMade] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [chosenPayment, setChosenPayment] = useState(1);
    const [paymentTotal, setPaymentTotal] = useState(0);

    // console.log(booking);
    // console.log(chosenPayment);

    useEffect(async () => {
        if (url !== "" && !booking?.id) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    setBooking(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    // window.location.href = "/404";
                });
        }
    }, [booking]);

    // console.log(booking);

    const onDrop = useCallback(async (acceptedFiles) => {
        setUploading(true);

        await Promise.all(
            acceptedFiles.map((file) => {
                uploadFile(file);
            })
        );
        //
    }, []);

    function uploadFile(file) {
        var form_data = new FormData();

        form_data.append("file", file);
        form_data.append("booking_id", booking?.id);
        form_data.append("user_id", booking?.user?.id);

        axios.post(`${process.env.REACT_APP_API_URL}upload_file`, form_data).finally(() => {
            setUploading(false);
            setFileUploaded(true);
        });
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    function confirmPayment() {
        if (window.confirm("Are you sure?")) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}pay_booking`,
                data: {
                    task: "online_payment",
                    booking_id: booking?.id,
                    rate_id: booking?.rates[0].id,
                },
            })
                .then((response) => {
                    if (response.data["success"] === true) {
                        setPaymentMade(1);
                    } else {
                        window.confirm("Oops! Something went wrong");
                    }
                })
                .catch((error) => {
                    // window.location.href = "/404";
                    console.log(error);
                })
                .finally(() => setLoading(false));
        }
    }

    function choosepayemnt(e, type) {
        if (e.target.checked) {
            setPaymentTotal(parseFloat(paymentTotal) + parseFloat(type));
        } else {
            setPaymentTotal(parseFloat(paymentTotal) - parseFloat(type));
        }
    }

    if (loading === true) return <AppLoader />;

    return (
        <div>
            {paymentMade === 0 && booking?.id ? (
                <div className="max-w-5xl m-auto">
                    {booking && booking?.user?.name && booking?.user?.surname && booking?.user?.cell && booking?.user?.email && booking?.user?.profile?.id_number ? (
                        <>
                            <div className="text-white h-14 rounded-full px-7 flex justify-start items-center" style={{ backgroundColor: "red" }}>
                                Payment will secure your bed once your lease is signed
                            </div>

                            <div className="md:flex md:justify-between">
                                <div className="md:w-1/2">
                                    <div className="mt-5 mb-6 flex justify-start">
                                        Pay VIA
                                        <label className="ml-4">
                                            <input type="radio" className="mr-1" name="pay_via" value="1" onClick={() => setChosenPayment(1)} />
                                            Online Payment
                                        </label>
                                        <label className="ml-4">
                                            <input type="radio" className="mr-1" name="pay_via" value="2" onClick={() => setChosenPayment(2)} />
                                            EFT
                                        </label>
                                    </div>

                                    <div className="rounded-3xl p-5" style={{ backgroundColor: "#e8e8e8" }}>
                                        <p className="text-xs text-gray-500">BOOKING SUMMARY</p>
                                        <p className="text-sm">{booking?.property?.name}</p>
                                        <p className="text-3xl my-2">
                                            {booking?.unit?.name} {booking?.bed?.name}
                                        </p>
                                        <p className="text-sm">{booking?.unit?.floor?.name}</p>
                                        <p className="text-sm">{booking?.unit_type?.name}</p>
                                    </div>

                                    <div className="mt-7">
                                        {chosenPayment === 1 ? (
                                            <div>
                                                <form
                                                    action={window.location.hostname === "localhost" ? "https://sandbox.payfast.co.za/eng/process" : "https://www.payfast.co.za/eng/process"}
                                                    method="POST"
                                                >
                                                    {window.location.hostname === "localhost" ? (
                                                        <>
                                                            <p>TEST MODE!</p>
                                                            <input type="hidden" name="merchant_id" value="10003863" />
                                                            <input type="hidden" name="merchant_key" value="mi4hkujnjc9j5" />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {/* SH */}
                                                            {booking?.property_id === 33 ? (
                                                                <>
                                                                    <input type="hidden" name="merchant_id" value="16529088" />
                                                                    <input type="hidden" name="merchant_key" value="cmbrnjrnr0rp3" />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <input type="hidden" name="merchant_id" value="16528399" />
                                                                    <input type="hidden" name="merchant_key" value="ox97yhyn6u2ps" />
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                    <input type="hidden" name="name_first" value={booking?.user?.name} />
                                                    <input type="hidden" name="name_last" value={booking?.user?.surname} />
                                                    <input type="hidden" name="email_address" value={booking?.user?.email} />
                                                    <input type="hidden" name="cell_number" value={booking?.user?.cell} />

                                                    <input type="hidden" name="m_payment_id" value={booking?.id} />
                                                    <input type="hidden" name="amount" value={paymentTotal} />
                                                    <input type="hidden" name="item_name" value="Deposit" />
                                                    <input type="hidden" name="item_description" value={booking?.property?.name + ", " + booking?.unit?.floor?.name + ", " + booking?.unit?.name} />

                                                    <input type="hidden" name="email_confirmation" value="1" />

                                                    {window.location.hostname === "localhost" ? (
                                                        <input type="hidden" name="confirmation_address" value="roger@whitehart.co.za" />
                                                    ) : (
                                                        <input type="hidden" name="confirmation_address" value="Mfundo@qholdings.co.za" />
                                                    )}

                                                    <input type="hidden" name="custom_int1" value={booking?.user?.id} />
                                                    <input type="hidden" name="custom_int2" value={booking?.id} />
                                                    <input type="hidden" name="custom_int3" value={booking?.rates && booking?.rates?.length > 0 ? booking?.rates[0].id : 0} />

                                                    <input type="hidden" name="return_url" value={window.location.href} />
                                                    <input type="hidden" name="cancel_url" value={window.location.href} />
                                                    <input type="hidden" name="notify_url" value={process.env.REACT_APP_API_URL + "payfast"} />

                                                    {paymentTotal > 0 ? (
                                                        <input
                                                            type="submit"
                                                            className="my-4 w-full relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                                            value="Make Payment via Payfast"
                                                        />
                                                    ) : (
                                                        <p>Please choose what you would like to pay on the right</p>
                                                    )}
                                                </form>
                                            </div>
                                        ) : (
                                            <div>
                                                <h1 className="text-3xl text-gray-400">Payment Details</h1>
                                                <h3 className="my-4 font-bold">Please ensure that the required payment is made to the following account</h3>
                                                <h5 className="mb-4 text-xs">Once done, please send your proof of payment to the respective chat channel.</h5>

                                                <p className="text-xs">Entity Name: Petraglo (Pty) Ltd</p>
                                                <p className="text-xs">Entity Reg No: {booking?.property?.invoice_registration_number}</p>
                                                <p className="text-xs">VAT No: {booking?.property?.vat_number}</p>
                                                <p className="text-xs">Account Number: {booking?.property?.banking_number}</p>
                                                <p className="text-xs">Bank: FNB/RMB</p>
                                                <p className="text-xs">Branch Code: {booking?.property?.banking_branch}</p>
                                                <p className="text-xs">Reference: {"#" + booking?.user?.id + "-" + booking?.property?.banking_reference}</p>

                                                <h4 className="my-8 uppercase text-primary text-2xl">Please upload your proof of payment</h4>
                                                <div className="flex text-sm text-gray-600" {...getRootProps()}>
                                                    <label
                                                        htmlFor="file-upload"
                                                        className="relative cursor-pointer bg-light_background rounded-md font-medium text-primary hover:text-primary_hover focus-within:outline-none"
                                                    >
                                                        {!uploading ? <span>Click here to upload</span> : <span>Files uploading</span>}
                                                        <input {...getInputProps()} />
                                                    </label>
                                                </div>
                                                {!uploading && <p className="text-xs text-gray-500">up to 10MB per file</p>}

                                                {fileUploaded === true && "File successfully uploaded"}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="md:w-1/3 md:pl-9">
                                    {booking?.invoice_url !== "" && booking?.invoice_url !== null ? (
                                        <a
                                            className=" my-4 w-full relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                            href={process.env.REACT_APP_LARAVEL_URL + "document/invoice/" + booking?.invoice_url}
                                            target="_blank"
                                        >
                                            Download Invoice
                                        </a>
                                    ) : (
                                        <div className="mt-10"></div>
                                    )}

                                    <div className="rounded-3xl p-5 mb-4" style={{ backgroundColor: "#e8e8e8" }}>
                                        {booking?.rates[0].monthly_rental > 0 && (
                                            <>
                                                <p className="text-xs text-gray-500">Monthly Rental</p>
                                                <p className="text-3xl">R {booking?.rates[0].monthly_rental} p/m</p>
                                                <p className="text-xs">
                                                    {booking?.student_type == 3
                                                        ? booking?.unit_type?.private_description
                                                        : booking?.student_type == 2
                                                        ? booking?.unit_type?.bursary_description
                                                        : booking?.unit_type?.nsfas_description}
                                                </p>
                                            </>
                                        )}

                                        {booking?.rates[0].deposit_price > 0 && (
                                            <>
                                                <p className="text-xs text-gray-500 mt-4">
                                                    {booking?.student_type == 3 || booking?.student_type == 2 ? "Deposit (Non Refundable)" : "NSFAS Upfront Non-Refundable Fee"}
                                                </p>

                                                <div className="flex justify-between">
                                                    <p className="text-3xl">R {booking?.rates[0].deposit_price}</p>
                                                    <input type="checkbox" name="deposit_payment" onClick={(e) => choosepayemnt(e, booking?.rates[0].deposit_price)} />
                                                </div>
                                                <p className="text-xs">
                                                    {booking?.student_type == 3
                                                        ? booking?.unit_type?.private_deposit_desc
                                                        : booking?.student_type == 2
                                                        ? booking?.unit_type?.bursary_deposit_desc
                                                        : booking?.unit_type?.nsfas_deposit_desc}
                                                </p>
                                            </>
                                        )}

                                        {booking?.rates[0].booking_fee_price > 0 && (
                                            <>
                                                <p className="text-xs text-gray-500 mt-4">Booking Fee (Refundable)</p>
                                                <div className="flex justify-between">
                                                    <p className="text-3xl">R {booking?.rates[0].booking_fee_price}</p>
                                                    <input type="checkbox" name="booking_fee_payment" onClick={(e) => choosepayemnt(e, booking?.rates[0].booking_fee_price)} />
                                                </div>
                                                <p className="text-xs">
                                                    {booking?.student_type == 3
                                                        ? booking?.unit_type?.private_booking_fee_desc
                                                        : booking?.student_type == 2
                                                        ? booking?.unit_type?.bursary_booking_fee_desc
                                                        : booking?.unit_type?.nsfas_booking_fee_desc}
                                                </p>
                                            </>
                                        )}

                                        {booking?.rates[0].parking_fee > 0 && (
                                            <>
                                                <p className="text-xs text-gray-500">Parking</p>
                                                <p className="text-3xl">R {booking?.rates[0].parking_fee}</p>
                                            </>
                                        )}

                                        {booking?.rates[0].admin_fee > 0 && (
                                            <>
                                                <p className="text-xs text-gray-500">Admin Fee</p>
                                                <p className="text-3xl">R {booking?.rates[0].admin_fee}</p>
                                            </>
                                        )}

                                        {booking?.rates[0].key_deposit > 0 && (
                                            <>
                                                <p className="text-xs text-gray-500">Key Deposit</p>
                                                <p className="text-3xl">R {booking?.rates[0].key_deposit}</p>
                                            </>
                                        )}

                                        {booking?.rates[0].effluent > 0 && (
                                            <>
                                                <p className="text-xs text-gray-500">Effluent</p>
                                                <p className="text-3xl">R {booking?.rates[0].effluent}</p>
                                            </>
                                        )}

                                        {booking?.rates[0].refuse > 0 && (
                                            <>
                                                <p className="text-xs text-gray-500">Refuse</p>
                                                <p className="text-3xl">R {booking?.rates[0].refuse}</p>
                                            </>
                                        )}

                                        {booking?.rates[0].special_wording && (
                                            <>
                                                <p className="text-xs text-gray-500">Special (if applicable)</p>
                                                <p className="text-3xl">{booking?.rates[0].special_wording}</p>
                                            </>
                                        )}

                                        <div className="text-3xl mt-4">
                                            Total: <strong>R {paymentTotal}</strong>
                                        </div>
                                    </div>

                                    <button
                                        onClick={() => confirmPayment()}
                                        className="w-full relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                    >
                                        Confirm Payment <i className="fas fa-tag"></i>
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {showbtn === "no" ? (
                                <p>Please fill in the Application Form first</p>
                            ) : (
                                <>
                                    <p>You are missing some information on your application.</p>
                                    <a
                                        href={"/edit_application/" + booking?.edit_link !== null ? booking?.edit_link?.edit_url : booking.user.edit_url}
                                        className="mt-2 relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                    >
                                        CLICK HERE TO EDIT YOUR PROFILE
                                    </a>
                                </>
                            )}
                        </>
                    )}
                </div>
            ) : (
                <p>Thank you for paying the deposit.</p>
            )}
        </div>
    );
}
