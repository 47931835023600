import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import NotificationToast from "../Helpers/NotificationToast";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faFile, faFileCsv, faFileExcel, faFilePdf, faGif, faImage, faMicrophone } from "@fortawesome/pro-solid-svg-icons";
import { getBooking } from "../../store/booking/bookingActions";
import { faFileZipper, faLoader, faVideo } from "@fortawesome/pro-regular-svg-icons";
import { closeModal, openModal } from "../../store/modal/modalReducer";
import { toast } from "react-toastify";

export default function AppointmentFileDisplay(props) {
    let file = props.file;
    let callback = props.callback;
    let currentPreview = props.currentPreview;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { current_booking } = useSelector((state) => state.booking);
    let icon = faFile;

    switch (file.extension) {
        case "pdf":
            icon = faFilePdf;
            break;
        case "csv":
            icon = faFileCsv;
            break;
        case "xsl":
        case "xlsx":
            icon = faFileExcel;
            break;
        case "jpg":
        case "png":
        case "jpeg":
            icon = faImage;
            break;
        case "gif":
            icon = faGif;
            break;
        case "zip":
        case "tar":
            icon = faFileZipper;
        case "mp4":
            icon = faVideo;
            break;
        case "mp3":
            icon = faMicrophone;
            break;
        default:
            icon = faFile;
            break;
    }

    async function download_file(file) {
        setLoading(true);
        await axios({
            method: "get",
            responseType: "blob",
            url: `${process.env.REACT_APP_API_URL}appointment_download_file/${file.id}`,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file.name + "." + file.extension);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                toast(<NotificationToast title="File Missing" message={"Something went wrong, we could not download this file!"} type="error" />);
            })
            .finally(() => setLoading(false));
    }

    async function delete_file(id) {
        setLoading(true);
        await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}attachment_delete_file/${id}`,
        })
            .then((response) => {
                // dispatch(getBooking(current_booking.id));
            })
            .finally(() => {
                setLoading(false);
                props.setLoadAppointment(true);
                // if (callback) {
                //     dispatch(closeModal());
                //     dispatch(
                //         openModal({
                //             modal_type: "ModalViewFiles",
                //             modal_props: {
                //                 files: current_booking.files,
                //             },
                //         })
                //     );
                // }
            });
    }

    return (
        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            {loading ? (
                <FontAwesomeIcon icon={faLoader} className="mx-auto text-gray-600 text-lg animate-spin" />
            ) : (
                <Formik
                    initialValues={{
                        name: file.name,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        setLoading(true);

                        await axios({
                            method: "put",
                            url: `${process.env.REACT_APP_API_URL}rename_file/${file.id}`,
                            data: {
                                name: values.name,
                            },
                        })
                            .then((response) => {
                                dispatch(getBooking(current_booking.id));
                                toast(<NotificationToast title="File Name Updated Succesfully" message={"Name changed to " + response.data} />);
                            })
                            .catch((error) => {
                                toast(<NotificationToast title="Something went wrong" message={error.response} type="error" />);
                            })
                            .finally(() => {
                                setTimeout(() => {
                                    setLoading(false);
                                }, 1000);

                                if (callback) {
                                    dispatch(closeModal());
                                    dispatch(
                                        openModal({
                                            modal_type: "ModalViewFiles",
                                            modal_props: {
                                                files: current_booking.files,
                                            },
                                        })
                                    );
                                }
                            });
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <Form onSubmit={handleSubmit} className="w-full flex">
                            <div className="w-0 flex-1 flex items-center">
                                <FontAwesomeIcon className="flex-shrink-0 h-5 w-5 text-gray-600" icon={icon} aria-hidden="true" />
                                <Field
                                    className={currentPreview === file.id ? "ml-2 flex-1 w-0 truncate bg-transparent text-primary" : "ml-2 flex-1 w-0 truncate text-gray-600 bg-transparent"}
                                    name="name"
                                    placeholder={file.name}
                                    title={file.name}
                                />
                            </div>
                            <div className="ml-4 flex-shrink-0 space-x-2">
                                <FontAwesomeIcon icon={regular("trash")} onClick={() => delete_file(file.id)} className="text-gray-400 hover:text-gray-800 cursor-pointer" title="Delete File" />
                                <FontAwesomeIcon
                                    icon={regular("download")}
                                    onClick={() => download_file(file)}
                                    className="font-medium text-gray-400 hover:text-gray-800 cursor-pointer"
                                    title="Download File"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </li>
    );
}
