import React, { useEffect, Fragment, useState, useRef } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";

import AppLoader from "../Helpers/AppLoader";

import moment from "moment";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import SettingsDateTimePicker from "../Forms/SettingsDateTimePicker";
import { getBooking } from "../../store/booking/bookingActions";
import { getStaff } from "../../store/auth/authActions";
import ProfilePicture from "../Auth/ProfilePicture";
import SettingsSwitch from "../Forms/SettingsSwitch";

export default function ModalMergeChat({ tag_id, type }) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { current_booking } = useSelector((state) => state.booking);
  const { current_staff } = useSelector((state) => state.auth);
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const searchInput = useRef(null);
  const [doMerge, setDoMerge] = useState(false);
  const [mergeBooking, setMergeBooking] = useState({});

  async function searchFrm(e) {
    if (e.code === "Enter" || e === false) {
      setSearching(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}searchBookings`, {
          search: searchInput.current.value,
          renewals: true,
        })
        .then((response) => {
          console.log(response.data);
          setSearchResults(response.data);
          setSearching(false);
        })
        .catch((error) => {
          setSearchResults([]);
          setSearching(false);
        });
    }
    //17445-10630
  }

  async function mergeChat(merge_booking) {
    if (merge_booking.id && current_booking.id) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}merge_chat`, {
          from_booking_id: current_booking.id,
          to_booking_id: merge_booking.id,
          type: type,
          tag_id: tag_id,
          current_staff_id: current_staff.id,
        })
        .then((response) => {
          console.log(response.data);
          setDoMerge(false);
        })
        .catch((error) => {
          console.log(error);
          setDoMerge(false);
        });
    } else {
      alert("error");
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => (doMerge === false ? dispatch(closeModal()) : "")}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() =>
                      doMerge === false ? dispatch(closeModal()) : ""
                    }
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={regular("times")}
                    />
                  </button>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      Merge {current_booking?.booking_reference} into another
                      Chat
                    </Dialog.Title>

                    <div className="text-sm">
                      <p>
                        Notes:
                        <br />
                        All chat items in this chat will move to the chat that
                        you choose below.
                        <br />
                        Any future messages to this chats details will also
                        appear in the below chat.
                        <br />
                        This chat will disappear from searches.
                      </p>
                    </div>

                    {doMerge === true &&
                    current_booking?.booking_reference &&
                    mergeBooking &&
                    mergeBooking.id ? (
                      <div className="mt-4">
                        Merging, this will take a while, please don't close or
                        refresh
                        <FontAwesomeIcon
                          icon={regular("spinner-third")}
                          className="text-black animate-spin text-5xl"
                        />
                      </div>
                    ) : (
                      <>
                        <div>
                          <label className="block text-sm font-medium mb-2 text-gray-700">
                            Search
                          </label>

                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              className="block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                              //   onChange={(e) => searchFrm(e.target.value)}
                              ref={searchInput}
                              placeholder="Type something to search"
                              onKeyUp={(e) => searchFrm(e)}
                            />

                            <button
                              className="ml-4 text-white uppercase px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 mt-1"
                              onClick={() => searchFrm(false)}
                            >
                              Search
                            </button>
                          </div>
                        </div>

                        <div className="mt-5">
                          {searching === true ? (
                            "Searching..."
                          ) : (
                            <>
                              {searchResults &&
                              searchResults.length > 0 &&
                              searchResults !== "blank" ? (
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                      >
                                        Booking
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                      >
                                        Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                      >
                                        Location
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                      >
                                        Bed
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                      >
                                        Status
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>

                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {searchResults.map((res) => {
                                      return (
                                        <>
                                          {res.location != null ? (
                                            <>
                                              <tr key={"search_" + res.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                                  {res.booking_reference}
                                                </td>
                                                <td className="whitespace-wrap px-3 py-4 text-xs text-gray-500">
                                                  <p>
                                                    {res.name} {res.surname}
                                                  </p>
                                                  <p>{res.email}</p>
                                                  <p>{res.cell}</p>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                                  {res.location}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                                  {res.bed}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                                  {res.archived &&
                                                  res.booking_merged_into ===
                                                    null ? (
                                                    <p>Archived</p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {res.closed &&
                                                  res.booking_merged_into ===
                                                    null ? (
                                                    <p>Closed</p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {res.declined &&
                                                  res.booking_merged_into ===
                                                    null ? (
                                                    <p>Declined</p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {!res.archived &&
                                                  !res.closed &&
                                                  !res.declined &&
                                                  res.booking_merged_into ===
                                                    null ? (
                                                    <p>Active</p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {res.booking_merged_into !==
                                                  null ? (
                                                    <p>Merged</p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </td>
                                                <td>
                                                  {res.booking_reference !=
                                                    current_booking?.booking_reference &&
                                                    res.booking_merged_into ===
                                                      null && (
                                                      <Link
                                                        to={{
                                                          pathname: res.declined
                                                            ? "/booking/declined"
                                                            : res.tag
                                                            ? "/booking/management?tag=" +
                                                              res.tag
                                                            : "/booking/" +
                                                              res.location
                                                                .toLowerCase()
                                                                .replace(
                                                                  /\s/g,
                                                                  ""
                                                                ),
                                                          search:
                                                            "?user_id=" +
                                                            res.user_id +
                                                            "&booking_id=" +
                                                            res.id,
                                                          state: {
                                                            from: "editUser",
                                                            userUid:
                                                              res.user_id,
                                                          },
                                                        }}
                                                        onClick={() => {
                                                          setDoMerge(true);
                                                          setMergeBooking(res);
                                                          mergeChat(res);
                                                          dispatch(
                                                            getBooking(res.id)
                                                          );
                                                        }}
                                                        className="text-white uppercase px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 mt-1"
                                                      >
                                                        Merge
                                                      </Link>
                                                    )}
                                                </td>
                                              </tr>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              ) : (
                                "No results found!"
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
