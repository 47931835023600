import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, useField } from "formik";
import NumberFormat from "react-number-format";
import InputMask from "react-input-mask";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function SettingsTextInput({ ...props }) {
    var label = props.label;
    var placeholder = props.placeholder;

    if (props.name === "id_number") {
        if (props.nationality) {
            if (props.nationality === "ZA") {
                label = placeholder = "ID Number";
            } else {
                label = placeholder = "Passport Number";
            }
        }
    }

    var styling = "col-span-2 sm:col-span-3 transition ease-linear delay-50";

    styling = props.styling ? props.styling : styling;

    return (
        <div className={styling}>
            <label htmlFor={props.name} className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">
                <span>
                    {label}
                    {props.tooltip && <FontAwesomeIcon className="ml-2 cursor-help" title={props.tooltip} icon={regular("circle-question")} />}
                </span>
                {props.required && !props.value && <p className="text-xs font-light text-red-400">required</p>}
            </label>
            <small className="mb-2">{props.small}</small>
            <div className="mt-1 flex rounded-md shadow-sm">
                {props.prepend && <span className="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-100  font-light text-sm">{props.prepend}</span>}
                {props.currency && (
                    <NumberFormat
                        {...props}
                        thousandSeparator={true}
                        allowNegative={true}
                        className={classNames(
                            props.prepend ? "rounded-r-lg" : "rounded-lg",
                            "block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm"
                        )}
                    />
                )}

                {props.business_registration && (
                    <InputMask
                        name={props.name}
                        value={props.value ? props.value : ""}
                        mask="9999/999999/99"
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        className={classNames(
                            props.prepend ? "rounded-r-lg" : "rounded-lg",
                            "block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                        )}
                    />
                )}

                {!props.currency && !props.business_registration && !props.phone_number && (
                    <Field
                        {...props}
                        as={props.field_type ? props.field_type : ""}
                        className={classNames(
                            props.prepend ? "rounded-r-lg" : "rounded-lg",
                            "block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                        )}
                        placeholder={placeholder}
                        value={props.value ? props.value : ""}
                    />
                )}

                <p className="text-red-400 text-sm font-light">{props.errors}</p>
            </div>
        </div>
    );
}
