import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import CustomButton from "../../components/Helpers/CustomButton";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function ReportsPage() {
    const { current_staff } = useSelector((state) => state.auth);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);
    const { properties } = useSelector((state) => state.properties);
    const { settings } = useSelector((state) => state.settings);
    const [chosenProp, setChosenProp] = useState("");
    const [pageIndex, setPageIndex] = useState(0);
    const [totalToDownload, setTotalToDownload] = useState(0);
    const [downloaded, setDownloaded] = useState(0);
    const [btnLoading, setBtnLoading] = useState(false);
    const [selBookingYear, setSelBookingYear] = useState(0);
    const [reportPropertyId, setReportPropertyId] = useState(0);

    useEffect(async () => {
        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }

        if (pageIndex > 0) {
            mdaSystemTenants();
        } else if (pageIndex == 2) {
            setPageIndex(0);
        }
    }, [pageIndex]);

    async function SummaryReport() {
        await axios({ method: "post", url: `${process.env.REACT_APP_API_URL}reports/export_summary`, responseType: "blob", data: { year_id: selBookingYear } })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "Summary.xlsx");
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function SummaryDuplicates() {
        await axios({ method: "post", url: `${process.env.REACT_APP_API_URL}reports/export_duplicates`, responseType: "blob" })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "Duplicates.xlsx");
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function occupancyReport() {
        await axios({ method: "post", url: `${process.env.REACT_APP_API_URL}reports/export_occupancy`, responseType: "blob", data: { year_id: selBookingYear } })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "Occupancy.xlsx");
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function mdaMisMatch() {
        await axios({ method: "post", url: `${process.env.REACT_APP_API_URL}reports/mda_mismatch`, responseType: "blob" })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "MDA Tenants.xlsx");
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function mdaSystemTenants() {
        setBtnLoading(true);
        await axios({ method: "post", url: `${process.env.REACT_APP_API_URL}reports/mda_vs_system`, responseType: "blob", data: { property_id: chosenProp, pageIndex: pageIndex } })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "MDA / System Tenants.xlsx");
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                console.log(error);
            });

        let newdownload = downloaded + 1;
        setDownloaded(newdownload);

        if (pageIndex < totalToDownload - 1) {
            let newindex = pageIndex + 1;
            setPageIndex(newindex);
        } else {
            window.location.reload();
        }
    }

    async function mdaUpdate() {
        await axios({ method: "post", url: `${process.env.REACT_APP_API_URL}reports/mda_mismatch_update`, responseType: "blob" })
            .then((response) => {
                console.log("Done");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function downloadCsv() {
        try {
            // Make API request using axios to get the CSV report
            const response = await axios({
                url: `${process.env.REACT_APP_API_URL}hikcentral/access-report-download?property_id=${reportPropertyId}`, // Replace with your API URL
                method: "POST",
                responseType: "blob", // Important to get the response as a Blob
                headers: {
                    "Content-Type": "text/csv",
                },
            });

            // Create a Blob from the response data
            const csvBlob = new Blob([response.data], { type: "text/csv" });

            // Create a download link for the Blob
            const url = window.URL.createObjectURL(csvBlob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report.csv"); // Specify the file name
            document.body.appendChild(link);
            link.click();
            link.remove(); // Clean up after download
        } catch (error) {
            console.error("Error with generating access report:", error);
            alert('Error generating report');
        }
    }

    return (
        <>
            <div className="h-full w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Reports</h1>

                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                    to="/reports/emaillogs"
                >
                    Email Logs
                </Link>
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                    to="/reports/exportall"
                >
                    Export All Students/Tenants
                </Link>
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                    to="/reports/exportbeds"
                >
                    Export All Beds
                </Link>

                {process.env.REACT_APP_COLOUR === "UC" && (
                    <Link
                        className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                        to="/reports/anomolies"
                    >
                        Export Anomolies Report
                    </Link>
                )}

                {process.env.REACT_APP_COLOUR === "UC" && (
                    <>
                        <br />
                        <br />
                        <hr />
                        <div classname="mt-2">
                            <p>What year do you want to download a report for (Export Summary, Occupancy Report)?</p>
                            <select
                                name="booking_year"
                                onChange={(e) => {
                                    setSelBookingYear(e.target.value);
                                }}
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                            >
                                <option value="">Choose One</option>
                                <option value="1">2023</option>
                                <option value="2">2024</option>
                            </select>
                        </div>
                        <br />
                        <CustomButton
                            className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                            text="Export Summary"
                            onClick={() => SummaryReport()}
                        />
                        <Link
                            className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                            to="/reports/takeup"
                        >
                            Take Up Reports
                        </Link>
                    </>
                )}
                <CustomButton
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    text="Export Duplicates"
                    onClick={() => SummaryDuplicates()}
                />
                {(current_staff.id == "1" || (process.env.REACT_APP_COLOUR === "UC" && (current_staff.id == "291" || current_staff.id == "24024"))) && (
                    // || (process.env.REACT_APP_COLOUR === "QL" && current_staff.id == "297")
                    <Link
                        className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                        to="/reports/user-tracking"
                    >
                        User Tracking Reports
                    </Link>
                )}

                {process.env.REACT_APP_COLOUR === "UC" && (
                    <>
                        <CustomButton
                            className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                            text="Occupancy report"
                            onClick={() => occupancyReport()}
                        />
                        <Link
                            className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                            to="/reports/vendor"
                        >
                            Vendor Reports
                        </Link>
                    </>
                )}

                <CustomButton
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    text="MDA Tenants"
                    onClick={() => mdaMisMatch()}
                />

                {/* {window.location.hostname === "localhost" ? */}
                {current_staff.id == "1" ? (
                    <>
                        {/* <CustomButton
                            className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                            text="MDA Tenants Update"
                            onClick={() => mdaUpdate()}
                        /> */}
                    </>
                ) : (
                    ""
                )}

                <br />
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    to="/reports/applications"
                >
                    Applications
                </Link>

                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    to="/reports/move"
                >
                    Move Out Links
                </Link>

                {(current_staff.id == "1" ||
                    (process.env.REACT_APP_COLOUR === "UC" && (current_staff.id == "23399" || current_staff.id == "9503" || current_staff.id == "24024")) ||
                    (process.env.REACT_APP_COLOUR === "QL" && (current_staff.id == "297" || current_staff.id == "294"))) && (
                    <Link
                        className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                        to="/reports/mentions"
                    >
                        Mentions Reports
                    </Link>
                )}
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                    to="/reports/viewings"
                >
                    Viewings Report
                </Link>
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                    to="/reports/keywords"
                >
                    Keywords Report
                </Link>

                <br />
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    to="/reports/leases"
                >
                    All Leases
                </Link>
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    to="/reports/leases_signed"
                >
                    Leases Signed
                </Link>

                <br />
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    to="/reports/cancellation"
                >
                    Cancellation
                </Link>

                <br />
                {process.env.REACT_APP_COLOUR === "UC" && (
                    <>
                        <br />
                        <hr />
                        <div classname="">
                            <p>What is your desired property for the MDA & System tenants download?</p>
                            <select
                                name="action_type"
                                onChange={(e) => {
                                    setChosenProp(e.target.value);
                                    if (e.target.value == 38 || e.target.value == 32 || e.target.value == 35) {
                                        setTotalToDownload(3);
                                    } else if (e.target.value == 37) {
                                        setTotalToDownload(2);
                                    } else if (e.target.value == 33) {
                                        setTotalToDownload(4);
                                    } else if (e.target.value == 36) {
                                        setTotalToDownload(5);
                                    } else if (e.target.value == 34) {
                                        setTotalToDownload(9);
                                    }
                                }}
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                            >
                                <option value="">Choose One</option>

                                {properties &&
                                    properties.length > 0 &&
                                    properties.map((p) => (
                                        <option key={p.value} value={p.value}>
                                            {p.label}
                                        </option>
                                    ))}
                            </select>
                        </div>

                        <CustomButton
                            className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                            text="MDA & System Tenants"
                            onClick={() => mdaSystemTenants()}
                            loading={btnLoading}
                        />
                        {chosenProp !== "" ? (
                            <>
                                <p>
                                    {" "}
                                    {downloaded} of {totalToDownload} downloaded
                                </p>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
                <br />
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                    to="/reports/additional_invoices"
                >
                    Additional Invoices
                </Link>
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                    to="/reports/bank_details_changes"
                >
                    Bank details changes
                </Link>
                <Link
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    to="/reports/chat_audit"
                >
                    Chat Audit
                </Link>
                <br />
                <div className="mt-10">
                    <p>Select the property you would like to download the last access report from</p>
                    <select
                        name="action_type"
                        className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                        onChange={(e) => {
                            setReportPropertyId(e.target.value);
                        }}
                    >
                        <option value="">Choose One</option>

                        {properties &&
                            properties.length > 0 &&
                            properties.map((p) => (
                                <option key={p.value} value={p.value}>
                                    {p.label}
                                </option>
                            ))}
                    </select>
                </div>
                <CustomButton
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    text="Download Hikcentral Access Report"
                    onClick={()=>downloadCsv()}
                />
            </div>
        </>
    );
}
