
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function SettingsAddButton({ ...props }) {
    return (
        <button
            type="button"
            className="inline-flex items-center p-4 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary_hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            {...props}
        >
            <FontAwesomeIcon aria-hidden="true" icon={regular('plus')} />
        </button>
    )
}