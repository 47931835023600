import React, { useEffect } from "react";
import { useField } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import moment from "moment";

export default function SettingsDateTimePicker({ ...props }) {
    const [field, meta, { setValue, setTouched }] = useField(props);

    return (
        <div className="col-span-6 sm:col-span-3">
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <DatePicker
                selected={field.value ? moment(field.value).toDate() : null}
                onChange={(value) => setValue(value)}
                className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                dateFormat={"EEEE, MMMM d, yyyy h:mm a"}
                peekNextMonth
                showTimeSelect
                dropdownMode="select"
                useWeekdaysShort={true}
            />

            <p className="text-red-400 text-sm">{props.errors}</p>
        </div>
    );
}
