import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import Picker from "emoji-picker-react";

export default function EmojiPicker({ setFieldValue, ...props }) {
    const onEmojiClick = (event, emojiObject) => {
        setFieldValue("message", props.value + " " + emojiObject.emoji);
    };

    var class_popover = "relative transition duration-150 ease-in-ease-out hover:-translate-y-0.5";
    var class_popover_button = "w-4 h-4";

    if (props.source === "booking") {
        class_popover += " w-12";
        class_popover_button += "  relative inline-block text-white bg-primary px-2 py-2 rounded-xl hover:bg-primary_hover";
    } else if (props.source === "slideover2") {
        class_popover += " pl-4 pb-2 bg-white";
    } else {
        class_popover += " pl-4 pb-2 bg-white rounded-b-md";
    }

    return (
        <Popover className={class_popover}>
            {({ open }) => (
                <>
                    <Popover.Button>
                        <FontAwesomeIcon icon={open ? regular("times") : regular("smile-beam")} className={`${open ? "text-primary" : "text-white"}` + `${class_popover_button}`} />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className=" z-10 mt-3 w-screen max-w-sm px-4 sm:px-0 xl:max-w-3xl">
                            <Picker
                                onEmojiClick={onEmojiClick}
                                // groupVisibility={{
                                //     animals_nature: false,
                                //     food_drink: false,
                                //     travel_places: false,
                                //     activities: false,
                                //     objects: false,
                                //     symbols: false,
                                //     flags: false,
                                //     recently_used: false
                                // }}
                            />
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
}
