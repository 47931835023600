import React, { Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form, Field, FieldArray } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";
import SettingsTextInput from "../Forms/SettingsTextInput";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import PhoneInput from "react-phone-number-input";
import { getManagers } from "../../store/managers/managersActions";
import SettingsSelect from "../Forms/SettingsSelect";
import CustomButton from "../Helpers/CustomButton";

export default function ModalOrderInfo(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { current_booking } = useSelector((state) => state.booking);
  const { current_staff } = useSelector((state) => state.auth);

  async function approveOrder(order_id) {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}approve_order`,
      data: { order_id: order_id, approver: current_staff.id },
    })
      .then((response) => {
        toast(
          <NotificationToast
            title={"Order Approved"}
            message="Order Approved Successfully"
            type="success"
          />
        );
        props.setReNewList(true);
        dispatch(closeModal());
      })
      .catch((error) => {
        toast(
          <NotificationToast
            title={"Something went wrong"}
            message={""}
            type="error"
          />
        );
      });
  }

  async function downloadOrder(order_id) {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}order/export_order`,
      responseType: "blob",
      data: { order_id: order_id },
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Order.xlsx");
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function cancelOrder(order_id) {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}cancel_order`,
      data: { order_id: order_id, approver: current_staff.id },
    })
      .then((response) => {
        toast(
          <NotificationToast
            title={"Order Cancelled"}
            message="Order Cancelled Successfully"
            type="success"
          />
        );
        props.setReNewList(true);
        dispatch(closeModal());
      })
      .catch((error) => {
        toast(
          <NotificationToast
            title={"Something went wrong"}
            message={""}
            type="error"
          />
        );
      });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={regular("times")}
                    />
                  </button>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      Maintenance Order #{props.order.id} &nbsp;{" "}
                      <span className="text-red-600 font-bold">
                        {props.order.cancelled === 1
                          ? " | ORDER CANCELLED"
                          : ""}
                      </span>
                    </Dialog.Title>

                    <br />
                    <p>
                      <b>Order Type:</b>{" "}
                      {props.order.order_type == 1
                        ? "General"
                        : "Unit Specific"}
                    </p>
                    <p>
                      <b>Order Reason:</b>{" "}
                      {props.order.order_reason == 1
                        ? "R & M"
                        : props.order.order_reason == 2
                        ? "Reinstatement"
                        : "Tenant Cost"}
                    </p>
                    <br />

                    <p>
                      <b>Property:</b> {props.order.property.name}
                    </p>

                    {props.order.order_type == 2 ? (
                      <>
                        <p>
                          <b>Unit:</b> {props.order.unit.name}
                        </p>
                        <p>
                          <b>Ordered on Account:</b> #{props.order.user_id}-
                          {props.order.booking_id}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                    <br />

                    <p>
                      <b>Ordered By:</b> {props.order.added.name}{" "}
                      {props.order.added.surname}
                    </p>

                    {props.order.order_reason === 1 ? (
                      <>
                        <p>
                          <b>Ordered Status:</b>{" "}
                          {props.order.status === 1 ? "Approved" : "Pending"}
                        </p>
                        {props.order.status === 1 ? (
                          <p>
                            <b>Approved By:</b> {props.order.approved.name}{" "}
                            {props.order.approved.surname} @{" "}
                            {props.order.approved_at}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    <br />
                    <p className="text-2xl">Order List:</p>
                    <br />
                    <table className="border-collapse table-auto w-full text-sm">
                      <thead>
                        <tr>
                          <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                            Category
                          </th>
                          <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                            Item
                          </th>
                          <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                            Price
                          </th>
                          <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                            Quantity
                          </th>
                          <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                            Total
                          </th>
                          {props.order.order_reason == 3 ? (
                            <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                              Status
                            </th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      <tbody className="bg-white dark:bg-slate-800">
                        {props.order.items &&
                          props.order.items.length > 0 &&
                          props.order.items.map((item) => {
                            return (
                              <tr>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  {item.category_info
                                    ? item.category_info.name
                                    : ""}
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  {item.item_info ? item.item_info.name : ""}
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  R {item.item_info ? item.item_info.price : ""}
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  {item.qty ? item.qty : ""}
                                </td>
                                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                  R{" "}
                                  {item.item_info
                                    ? item.item_info.price * item.qty
                                    : ""}
                                </td>
                                {props.order.order_reason == 3 ? (
                                  <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                                    {item.tenant_status == 2 ? (
                                      <span className="text-red-400">
                                        Rejected
                                      </span>
                                    ) : item.tenant_status == 1 ? (
                                      <span className="text-green-600">
                                        Approved
                                      </span>
                                    ) : (
                                      "Pending"
                                    )}
                                  </td>
                                ) : (
                                  ""
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    {props.order.status === 0 &&
                    props.order.order_reason === 1 &&
                    (current_staff.id === 1 || (process.env.REACT_APP_COLOUR === "QL" && (current_staff.id === 291 || current_staff.id === 297 || current_staff.id === 2860))) ? (
                      <>
                        <CustomButton
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                          text="Approve Order"
                          onClick={() => approveOrder(props.order.id)}
                        />
                      </>
                    ) : (
                      ""
                    )}

                    <CustomButton
                      className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                      text="Download Order"
                      onClick={() => downloadOrder(props.order.id)}
                    />

                    {props.order.status === 0 &&
                    props.order.cancelled === 0 &&
                    !current_staff.building_manager ? (
                      <>
                        <div className="flex flex-row-reverse -mt-16">
                          <CustomButton
                            className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-dark_background bg-dark_background hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                            text="Cancel Order"
                            onClick={() => cancelOrder(props.order.id)}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
