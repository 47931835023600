import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const LoadingComponent = () => {
    return (
        <span className="text-blue-comparemed top-1/2 my-4 mx-auto block relative w-0 h-0">
            <FontAwesomeIcon
                icon={regular("spinner-third")}
                className="text-black animate-spin text-xs"
            />
        </span>
    );
};

export default LoadingComponent;
