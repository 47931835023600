import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useHistory, Link } from "react-router-dom";

import { toast } from "react-toastify";
// import { doc } from "firebase/firestore";

import { openModal } from "../../store/modal/modalReducer";
import NotificationToast from "../Helpers/NotificationToast";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import { getBooking } from "../../store/booking/bookingActions";
import QuickFormText from "../Forms/QuickFormText";
import QuickFormSelect from "../Forms/QuickFormSelect";
import AppLoader from "../Helpers/AppLoader";

export default function GridBookingInformation(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [units, setUnits] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [bookingFiles, setBookingFiles] = useState([]);
    const { current_booking } = useSelector((state) => state.booking);
    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const [years, setYears] = useState(0);
    const [selectedYear, setSelectedYear] = useState(0);
    const [allowNewBooking, setAllowNewBooking] = useState(true);
    const filterProp = useRef();

    const [historicBookings, setHistoricBookings] = useState([]);

    const sendHistoricBookings = (data) => {
        props.bookingGridCallback(data);
    };

    useEffect(async () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get_years`,
        })
            .then((response) => {
                let dbYears = response.data;
                dbYears.sort((a, b) => a.value - b.value);
                setYears(dbYears);
            })
            .catch((error) => {
                alert("year load failed");
            });

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}booking_history`,
            data: { user_id: current_booking?.user?.id },
        })
            .then(async (response) => {
                setHistoricBookings(response.data);
                sendHistoricBookings(response.data);
                setBookingFiles(response.data[0].files);

                let bookingInfo = response.data;

                await Object.keys(bookingInfo).forEach((key) => {
                    if (bookingInfo[key].year_id > settings.year_id) {
                        setAllowNewBooking(false);
                    }
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    async function booking_add(yearSelected) {
        if (yearSelected === 0) {
            toast(<NotificationToast title={"New Booking Year"} message="You need to select a year for the new booking" type="error" />);
        } else {
            setLoading(true);

            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}add-new-booking`,
                data: { user_id: current_booking?.user?.id, current_staff: current_staff, yearSelected: yearSelected },
            })
                .then((response) => {
                    // setHistoricBookings(response.data);
                    setLoading(false);
                    dispatch(getBooking(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    return (
        <div className="overflow-y-scroll scroll-smooth">
            {/* {window.location.hostname === "localhost" ? */}
            {/* <> */}

            {/* {window.location.hostname === "localhost" && current_staff.id === 1 ? */}
            {allowNewBooking === true ? (
                <>
                    {!current_staff.building_manager ? (
                        <select
                            ref={filterProp}
                            className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary flex-none w-full"
                            onChange={(v) => setSelectedYear(v.target.value)}
                        >
                            <option value="0" selected="0" disabled>
                                Year
                            </option>
                            {years &&
                                years.length > 0 &&
                                years.map((u) => {
                                    if (u.value >= settings.year_id) {
                                        return (
                                            <option key={u.value} value={u.value}>
                                                {u.label}
                                            </option>
                                        );
                                    }
                                })}
                        </select>
                    ) : (
                        ""
                    )}

                    {!current_staff.building_manager ? (
                        <CustomButton text={"Add Booking"} styling="w-full mt-2" icon={regular("user-plus")} onClick={() => booking_add(selectedYear)} loading={loading} loadingtext="Loading..." />
                    ) : (
                        ""
                    )}

                    <br />
                </>
            ) : (
                ""
            )}

            <p>List of Bookings:</p>

            {historicBookings &&
                historicBookings.length > 0 &&
                historicBookings.map((booking) => (
                    <>
                        {current_booking?.booking_reference === booking?.booking_reference ? (
                            <>
                                <div className="border-2 w-full rounded px-1 py-1 my-2 text-center border-green-500">
                                    <p>
                                        {booking.booking_reference} | {booking.year.year}{" "}
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                {booking && (
                                    <Link
                                        to={
                                            booking?.upcoming === 2
                                                ? "/booking/renewals?renewal_tag=" + booking.renewals_tag + "&user_id=" + booking.user.id + "&booking_id=" + booking.id
                                                : booking.tag > 0
                                                ? "/booking/management?tag=" + booking.tag + "&user_id=" + booking.user.id + "&booking_id=" + booking.id
                                                : "/booking/" + booking?.location?.toLowerCase().replace(/\s/g, "") + "?user_id=" + booking?.user?.id + "&booking_id=" + booking?.id
                                        }
                                        onClick={() => dispatch(getBooking(0, booking?.user?.id, booking.id))}
                                    >
                                        <div className="border-2 w-full rounded px-1 py-1 my-2 text-center cursor-pointer bg-red-100">
                                            <p>
                                                {booking?.booking_reference} | {booking?.year?.year}
                                            </p>
                                        </div>
                                    </Link>
                                )}
                            </>
                        )}
                    </>
                ))}

            {/* </> */}
            {/* : "" */}
            {/* } */}
        </div>
    );
}
