import React from "react";
import { useSelector } from "react-redux";

import ModalReturningStudent from "../../components/Modals/ModalReturningStudent";
import ModalNotification from "../../components/Modals/ModalNotification";
import ModalSnooze from "../../components/Modals/ModalSnooze";
import ModalAssign from "../../components/Modals/ModalAssign";
import ModalCreateApplication from "../../components/Modals/ModalCreateApplication";
import ModalDecline from "../../components/Modals/ModalDecline";
import ModalConfirmation from "../../components/Modals/ModalConfirmation";
import ModalViewFiles from "../../components/Modals/ModalViewFiles";

import SlideoverLookupForm from "../../components/Modals/SlideoverLookupForm";
import SlideoverTemplateForm from "../../components/Modals/SlideoverTemplateForm";
import SlideoverAppointmentForm from "../../components/Modals/SlideoverAppointmentForm";
import SlideoverFiles from "../../components/Modals/SlideoverFiles";

import ModalSearch from "../../components/Modals/ModalSearch";
import ModalBulkMessages from "../../components/Modals/ModalBulkMessages";
import ModalBulkArchive from "../../components/Modals/ModalBulkArchive";
import ModalFilterMessages from "../../components/Modals/ModalFilterMessages";
import ModalMove from "../../components/Modals/ModalMove";
import ModalChangeUnit from "../../components/Modals/ModalChangeUnit";
import ModalMailRawInfo from "../../components/Modals/ModalMailRawInfo";
import ModalMergedApplicants from "../../components/Modals/ModalMergedApplicants";
import ModalCustomTba from "../../components/Modals/ModalCustomTba";
import ModalManagerSearch from "../../components/Modals/ModalManagerSearch";
import ModalNewManager from "../../components/Modals/ModalNewManager";
import SlideoverManagementForm from "../../components/Modals/SlideoverManagementForm";

import ModalProperty from "../../components/Modals/ModalProperty";
import ModalPropBed from "../../components/Modals/ModalPropBed";
import ModalPropUnit from "../../components/Modals/ModalPropUnit";
import ModalPropUnitType from "../../components/Modals/ModalPropUnitType";
import ModalPropFloor from "../../components/Modals/ModalPropFloor";
import ModalChooseBed from "../../components/Modals/ModalChooseBed";
import ModalBedAssignment from "../../components/Modals/ModalBedAssignment";
import ModalUnitAssignment from "../../components/Modals/ModalUnitAssignment";
import ModalMergeChat from "../../components/Modals/ModalMergeChat";
import ModalSinglePayment from "../../components/Modals/ModalSinglePayment";
import ModalOTPPayment from "../../components/Modals/ModalOTPPayment";
import ModalMDAChooseUnit from "../../components/Modals/ModalMDAChooseUnit";
import ModalViewPrevBooking from "../../components/Modals/ModalViewPrevBooking";

import ModalCustomInvoice from "../../components/Modals/ModalCustomInvoice";
import ModalJoinWaitlist from "../../components/Modals/ModalJoinWaitlist";
import ModalBM from "../../components/Modals/ModalBM";
import ModalBMCustom from "../../components/Modals/ModalBMCustom";
import ModalBMCustomUC from "../../components/Modals/ModalBMCustomUC";
import ModalBrokerApplicants from "../../components/Modals/ModalBrokerApplicants";

import ModalViewAppointment from "../../components/Modals/ModalViewAppointment";

import OrderModal from "../../components/Modals/OrderModal";
import ModalOrderInfo from "../../components/Modals/ModalOrderInfo";
import ModalBookAppointment from "../../components/Modals/ModalBookAppointment";
import ModalBMAppointment from "../../components/Modals/ModalBMAppointment";
import ModalCleaning from "../../components/Modals/ModalCleaning";
import ModalMoveFiles from "../../components/Modals/ModalMoveFiles";
import ModalRefund from "../../components/Modals/ModalRefund";

export default function ModalManager() {
    const modal_lookup = {
        ModalReturningStudent,
        ModalBMAppointment,

        ModalNotification,
        ModalSnooze,
        ModalAssign,
        ModalDecline,
        ModalConfirmation,
        ModalViewFiles,
        ModalCreateApplication,

        SlideoverLookupForm,
        SlideoverTemplateForm,
        SlideoverAppointmentForm,
        SlideoverFiles,
        SlideoverManagementForm,
        ModalBookAppointment,
        ModalCleaning,

        OrderModal,
        ModalOrderInfo,

        ModalSearch,
        ModalManagerSearch,
        ModalNewManager,
        ModalBulkMessages,
        ModalBulkArchive,
        ModalFilterMessages,
        ModalMailRawInfo,
        ModalMove,
        ModalChangeUnit,
        ModalMergedApplicants,
        ModalMoveFiles,

        ModalProperty,
        ModalPropBed,
        ModalPropUnit,
        ModalPropUnitType,
        ModalChooseBed,
        ModalCustomInvoice,
        ModalPropFloor,
        ModalBedAssignment,
        ModalUnitAssignment,
        ModalMergeChat,
        ModalMDAChooseUnit,

        ModalSinglePayment,
        ModalOTPPayment,
        ModalJoinWaitlist,
        ModalViewPrevBooking,

        ModalCustomTba,
        ModalBM,
        ModalBMCustom,
        ModalBMCustomUC,
        ModalViewAppointment,
        ModalBrokerApplicants,
        ModalRefund,
    };

    const currentModal = useSelector((state) => state.modals);
    let renderedModal;

    if (currentModal) {
        const { modal_type, modal_props } = currentModal;
        const ModalComponent = modal_lookup[modal_type];
        renderedModal = <ModalComponent {...modal_props} />;
    }

    return <span>{renderedModal}</span>;
}
