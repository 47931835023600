import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faTimes,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import NotificationToast from "../Helpers/NotificationToast";
import CustomButton from "../Helpers/CustomButton";
import SubmitButton from "../Forms/SubmitButton";
import { getTemplates } from "../../store/template/templateActions";
import { getTemplateCategories } from "../../store/template/templateActions";
import SettingsTextInput from "../Forms/SettingsTextInput";
import PhoneInput from "react-phone-number-input";

export default function ModalReturningStudent(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [showNoUser, setShowNoUser] = useState(false);

  let modalIcon = faQuestionCircle;
  let modalIconClass = "my-4 text-2xl text-blue-500";

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h1"
                    className="text-lg font-medium text-gray-900"
                  >
                    Are you a returning student?
                  </Dialog.Title>
                  <Formik
                    initialValues={{
                      student_number: "",
                      cell: "",
                      email: "",
                    }}
                    enableReinitialize="true"
                    validateOnChange={true}
                    validateOnBlur={true}
                    validationSchema={Yup.object().shape({
                      student_number: Yup.string().required("student number"),
                      cell: Yup.string().required("cell"),
                      email: Yup.string().required("email"),
                      id_number: Yup.string().required("ID Number"),
                    })}
                    onSubmit={async (
                      values,
                      { setSubmitting, setErrors, resetForm }
                    ) => {
                      setSubmitting(true);

                      await axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_API_URL}returning_student`,
                        data: values,
                      })
                        .then((response) => {
                          console.log(response.data.indexOf("success"));
                          if (response.data.indexOf("success") >= 0) {
                            let newresp = response.data.split("|");
                            window.location = "/step1/" + newresp[1];
                          } else {
                          }

                          // if (response.data == "success") {
                          //   toast(
                          //     <NotificationToast
                          //       title={"Thank you"}
                          //       message="We will notify you once we have processed your information"
                          //     />
                          //   );
                          // } else
                          if (response.data == "notified") {
                            toast(
                              <NotificationToast
                                title={"Your information is being processed"}
                                message={
                                  "We have already been notified about your account. Please wait for more feedback"
                                }
                                type="error"
                              />
                            );
                          } else if (response.data == "student") {
                            toast(
                              <NotificationToast
                                title={"Student number not found"}
                                message={
                                  "Your student number could not be found"
                                }
                                type="error"
                              />
                            );
                            setShowNoUser(true);
                          }
                          // resetForm();
                        })
                        .catch((response) => {
                          toast(
                            <NotificationToast
                              title={"Something went wrong"}
                              message={response.response.data.message}
                              type="error"
                            />
                          );
                        });
                    }}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      setFieldTouched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      dirty,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <p className="mt-2 text-gray-500">
                          If yes, please enter your details to book your room
                          for 2023!
                        </p>

                        <br />
                        <Field
                          name="student_number"
                          type="text"
                          as={SettingsTextInput}
                          label="Student Number"
                          required
                        />
                        <br />
                        {/* <Field
                                                    name="cell"
                                                    type="text"
                                                    as={SettingsTextInput}
                                                    prepend="+27"
                                                    label="Cellphone Number"
                                                    required
                                                /> */}

                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                          <label
                            htmlFor="cell"
                            className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between"
                          >
                            <span>Cellphone Number</span>
                            {errors.cell && (
                              <p className="text-xs font-light text-red-400">
                                required
                              </p>
                            )}
                          </label>
                          <div className="mt-1">
                            <PhoneInput
                              defaultCountry="ZA"
                              countryCallingCodeEditable={false}
                              onChange={(val) => {
                                setFieldValue("cell", val);
                              }}
                              onBlur={setFieldTouched}
                              name="cell"
                              value={values.cell}
                            />
                            <p className="text-red-400 text-sm font-light">
                              {errors.cell}
                            </p>
                          </div>
                        </div>

                        <br />
                        <Field
                          name="email"
                          type="email"
                          as={SettingsTextInput}
                          label="University Issued Email"
                          required
                        />
                        <Field
                          name="id_number"
                          type="text"
                          as={SettingsTextInput}
                          label="ID Number"
                          required
                        />

                        <div className="mt-8 sm:flex sm:justify-end sm:space-x-4">
                          <CustomButton
                            type="button"
                            onClick={() => dispatch(closeModal())}
                            text="Cancel"
                            style={{
                              fontWeight: 700,
                              borderRadius: 20,
                              marginBottom: 20,
                            }}
                          />
                          <SubmitButton
                            name="Save"
                            disabled={!isValid || isSubmitting || !dirty}
                            isSubmitting={isSubmitting}
                            text="Submit"
                            style={{
                              fontWeight: 700,
                              borderRadius: 20,
                              marginBottom: 20,
                            }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                {showNoUser === true && (
                  <div className="text-red-600 font-bold text-center my-4">
                    We cannot find your information on our system, if you need
                    help please send a Whatsapp message on{" "}
                    <a
                      target="_blank"
                      href="https://api.whatsapp.com/send?phone=270871632011"
                    >
                      0871632011
                    </a>{" "}
                    or send us an email at howzit@urbancircle.co.za
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
