import React, { Fragment, useState, useEffect } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import BookingPlatformButton from "../Booking/BookingPlatformButton";
import {
  brands,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import ErrorBoundary from "../../containers/ErrorBoundary";

import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

// import { collection, addDoc, setDoc, doc } from "firebase/firestore";

import BookingTemplate from "../Booking/BookingTemplate";
import BookingTextArea from "../Booking/BookingTextArea";
import BookingWysiwyg from "../Booking/BookingWysiwyg";
import EmojiPicker from "../Forms/EmojiPicker";
import BookingFileUpload from "../Forms/BookingFileUpload";
import BookingPlaceholder from "../Booking/BookingPlaceholder";
import SubmitButton from "../Forms/SubmitButton";

import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeSince from "../Helpers/TimeSince";
import moment from "moment/moment";
import QuickFormText from "../Forms/QuickFormText";
import NumberFormat from "react-number-format";
import CustomButton from "../Helpers/CustomButton";
import { consoleSandbox } from "@sentry/utils";
import { useParams } from "react-router";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ModalBulkMessages(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [listLoaded, setListLoaded] = useState(false);
  const [sendList, setSendList] = useState([]);
  const { properties } = useSelector((state) => state.properties);
  const [selProperty, setSelProperty] = useState("");
  const [selClosed, setSelClosed] = useState("");
  const [selUnitType, setSelUnitType] = useState("");
  const [selBookingYear, setSelBookingYear] = useState("");
  const [selStudentType, setSelStudentType] = useState("");
  const [selDocuments, setSelDocuments] = useState("");
  const [closeType, setCloseType] = useState([
    { label: "Open", value: 0 },
    { label: "Closed", value: 1 },
  ]);
  const [selNewerThan, setSelNewerThan] = useState(0);
  const [selOlderThan, setSelOlderThan] = useState(0);
  const [needOtp, setNeedOtp] = useState(true);
  const { current_staff } = useSelector((state) => state.auth);

  const [modalAction, setModalAction] = useState("Messages");
  const [unitTypes, setUnitTypes] = useState([]);
  const [studentTypes, setStudentTypes] = useState([
    { label: "NSFAS UJ", value: 4 },
    { label: "NSFAS WITS", value: 5 },
    { label: "CJC", value: 6 },
    { label: "NSFAS TVET", value: 1 },
    { label: "Bursary", value: 2 },
    { label: "Private", value: 3 },
  ]);

  let activeClass = "bg-dark_background flex-auto rounded-t-lg cursor-pointer";
  let inactiveClass = "bg-primary flex-auto rounded-t-lg cursor-pointer";

  // console.log(props.bookings)

  useEffect(() => {
    if (
      (modalAction === "Messages" && listLoaded === false) ||
      modalAction === "Payments"
    ) {
      console.log(selOlderThan, selNewerThan);

      let sendListNew = props.bookings.filter(function (sl) {
        // let sendListNew = props.allBookings.filter(function (sl) {

        if (modalAction === "Messages") {
          if (!sl.checked) {
            sl.checked = true;
          }
        }

        // add filter for the year (af)

        if (
          selProperty === "" ||
          parseInt(selProperty) === parseInt(sl.i) ||
          (parseInt(selProperty) === 1000 && sl.i === null)
        ) {
          if (selClosed === "" || parseInt(selClosed) === parseInt(sl.g)) {
            if (
              selUnitType === "" ||
              parseInt(selUnitType) === parseInt(sl.u)
            ) {
              if (
                selStudentType === "" ||
                parseInt(selStudentType) === parseInt(sl.v)
              ) {
                if (
                  selBookingYear == 0 ||
                  selBookingYear == "" ||
                  parseInt(sl.af) === parseInt(selBookingYear)
                ) {
                  if (
                    parseInt(selOlderThan) > 0 &&
                    parseInt(selNewerThan) == 0
                  ) {
                    if (
                      moment(
                        moment()
                          .subtract(parseInt(selOlderThan), "days")
                          .format("Y-MM-DD")
                      ).isSameOrBefore(
                        moment(moment(sl.n).format("Y-MM-DD"))
                      ) === false
                    ) {
                      return true;
                    }
                  }

                  if (
                    parseInt(selNewerThan) > 0 &&
                    parseInt(selOlderThan) == 0
                  ) {
                    if (
                      moment(
                        moment()
                          .subtract(parseInt(selNewerThan), "days")
                          .format("Y-MM-DD")
                      ).isSameOrAfter(
                        moment(moment(sl.n).format("Y-MM-DD"))
                      ) === false
                    ) {
                      return true;
                    }
                  }

                  if (
                    parseInt(selNewerThan) > 0 &&
                    parseInt(selOlderThan) > 0
                  ) {
                    if (
                      moment(
                        moment()
                          .subtract(parseInt(selOlderThan), "days")
                          .format("Y-MM-DD")
                      ).isSameOrBefore(
                        moment(moment(sl.n).format("Y-MM-DD"))
                      ) === false &&
                      moment(
                        moment()
                          .subtract(parseInt(selNewerThan), "days")
                          .format("Y-MM-DD")
                      ).isSameOrAfter(
                        moment(moment(sl.n).format("Y-MM-DD"))
                      ) === false
                    ) {
                      return true;
                    }
                  }

                  //catch for is both are 0
                  if (
                    parseInt(selNewerThan) == 0 &&
                    parseInt(selOlderThan) == 0
                  ) {
                    return true;
                  }
                }
              }
            }
          }
        }
      });

      setSendList(sendListNew);
      setListLoaded(true);
    }
  }, [listLoaded, modalAction]);

  function changeList(e, res) {
    let sendListNew = sendList.map((sl) => {
      if (!sl.checked) {
        sl.checked = false;
      }

      if (sl.a === res.a) {
        sl.checked = e.target.checked;
      }

      return sl;
    });

    setSendList(sendListNew);
  }

  function changeListAll(checked) {
    let sendListNew = sendList.map((sl) => {
      // if (!sl.checked) {
      //   sl.checked = false;
      // }

      // if (sl.id === res.id) {
      //   sl.checked = e.target.checked;
      // }

      sl.checked = checked;
      // console.log(sl)

      return sl;
    });

    setSendList(sendListNew);
  }

  async function setProp(e) {
    setSelProperty(e.target.value);
    setListLoaded(false);

    if (e.target.value === "") {
      setUnitTypes([]);
    } else {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}unit_types/${e.target.value}`,
      })
        .then((response) => {
          setUnitTypes(response.data);
        })
        .catch((error) => {
          alert("Unit Types failed");
        });
    }
  }

  function setUnit(e) {
    setSelUnitType(e.target.value);
    setListLoaded(false);
  }

  function setFunding(e) {
    setSelStudentType(e.target.value);
    setListLoaded(false);
  }

  function setClosed(e) {
    setSelClosed(e.target.value);
    setListLoaded(false);
  }

  function setYear(e) {
    setSelBookingYear(e.target.value);
    setListLoaded(false);
  }
  // set selected year

  // function setDoc(e){
  //   setSelDocuments(e.target.value)
  //   setListLoaded(false);
  // }

  if (modalAction === "Messages") {
    var validateSchema = Yup.object().shape({
      platform: Yup.string().required("Message platform is not selected"),
      message: Yup.string().when("platform", {
        is: (value) => value && value != "whatsapp",
        then: Yup.string().required("Message is empty"),
      }),
    });
  } else {
    var validateSchema = Yup.object().shape({
      amount: Yup.string().required("Payment Amount is not entered"),
      reason: Yup.string().required("Payment Reason is not entered"),
    });
  }

  async function batchOTP(amount, reason) {
    let sendListNew = sendList.filter(function (sl) {
      return sl.checked === true;
    });

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}create_batch_pay`,
      data: { sendListNew: sendListNew, amount: amount, reason: reason },
    })
      .then((response) => {
        if (response.data != "done") {
          alert(response.data[1]);
        } else {
          setNeedOtp(false);
        }
      })
      .catch((error) => {
        alert("OTP Request Failed");
      });
  }

  if (current_staff.status !== "Admin") return "Access Error";

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
              <div className="flex">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h1"
                    className="text-lg font-medium text-gray-900 capitalize"
                  >
                    Bulk {modalAction === "Messages" ? "Messaging" : "Payments"}{" "}
                    - {props.booking_list_name}
                  </Dialog.Title>
                </div>

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-right grow">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={regular("times")}
                    />
                  </button>
                </div>
              </div>
              <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                <Dialog.Panel>
                  <ErrorBoundary>
                    <Formik
                      initialValues={{
                        message: "",
                        platform: "",
                        from: props.current_staff?.id,
                        to: "",
                        files: [],
                        template: 0,
                        template_name: "",
                      }}
                      validateOnChange={false}
                      validateOnBlur={false}
                      validationSchema={validateSchema}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        //Filter out just ticked items

                        let sendListNew = sendList.filter(function (sl) {
                          if (modalAction === "Messages") {
                            if (values.platform == "note") {
                              return sl.checked === true;
                            } else if (
                              values.platform == "email" &&
                              sl.l !== ""
                            ) {
                              return sl.checked === true;
                            } else if (
                              values.platform != "email" &&
                              sl.k !== ""
                            ) {
                              return sl.checked === true;
                            }
                          } else if (modalAction === "Payments") {
                            return sl.checked === true;
                          }
                        });

                        if (sendListNew.length === 0) {
                          alert("Please choose at least 1 person to send to!");
                        } else {
                          setSubmitting(true);

                          if (modalAction === "Messages") {
                            let msgList = [];

                            async function processArray(array) {
                              for (const item of array) {
                                console.log("sending " + item.b + " " + item.c);

                                const the_message = values.message;
                                // var template = values.template;
                                let replaced_message = values.message;
                                let replaced_message_paste = values.message;

                                // if (template === 0) {
                                replaced_message = the_message.replace(
                                  "[name]",
                                  item.b
                                );
                                replaced_message = replaced_message.replace(
                                  "[surname]",
                                  item.c
                                );
                                replaced_message = replaced_message.replace(
                                  "[email]",
                                  item.l
                                );
                                replaced_message = replaced_message.replace(
                                  "[email]",
                                  item.l
                                );

                                replaced_message_paste =
                                  replaced_message_paste.replace(
                                    "[Name]",
                                    item.b
                                  );
                                replaced_message_paste =
                                  replaced_message_paste.replace(
                                    "[payfast_link]",
                                    window.location.origin +
                                      "/payfast/" +
                                      item.ag
                                  );

                                // }

                                var to = item.k;
                                if (values.platform === "email") {
                                  to = item.l;
                                }

                                msgList.push({
                                  booking_id: item.a,
                                  user_id: item.f,
                                  message: replaced_message,
                                  to_paste_msg: replaced_message_paste,
                                  to: to,
                                });

                                // var form_data = new FormData();

                                // var timestamp = new Date();
                                // var platform = values.platform;
                                // var from = props.current_staff?.id;
                                // var user_id = item.f;
                                // var files = [];

                                // form_data.append("timestamp", timestamp);
                                // form_data.append("message", replaced_message);
                                // // form_data.append("conversation_id", item.user.conversation_id); //TODO
                                // form_data.append("platform", platform);
                                // form_data.append("from", from);
                                // form_data.append("booking", item.a);
                                // form_data.append("to", to);
                                // form_data.append("user_id", user_id);
                                // form_data.append(
                                //   "staff_id",
                                //   props.current_staff?.id
                                // );
                                // form_data.append("template", template);

                                // if (props.location_type === "collections") {
                                //   form_data.append("collections", 1);
                                // }

                                // // will only be one file if WA
                                // if (values.files.length == 1) {
                                //   form_data.append("files", values.files[0]);
                                //   files = values.files[0].name;
                                // } else if (values.files.length > 1) {
                                //   values.files.map((file) => {
                                //     form_data.append("files[]", file);
                                //     files.push(file.name);
                                //   });
                                // }

                                // await axios
                                //   .post(
                                //     `${process.env.REACT_APP_API_URL}send`,
                                //     form_data
                                //   )
                                //   .then((response) => {
                                //     if (template) {
                                //       replaced_message = the_message.replace(
                                //         "[name]",
                                //         item.b
                                //       );
                                //       replaced_message =
                                //         replaced_message.replace(
                                //           "[surname]",
                                //           item.c
                                //         );
                                //       replaced_message =
                                //         replaced_message.replace(
                                //           "[email]",
                                //           item.l
                                //         );
                                //     }
                                //     toast(
                                //       <NotificationToast
                                //         title={values.platform + " successful"}
                                //         message={"Your message has been sent."}
                                //       />
                                //     );
                                //   })
                                //   .catch((error) => {
                                //     console.log(error);
                                //     toast(
                                //       <NotificationToast
                                //         title="Something went wrong"
                                //         message={JSON.stringify(
                                //           error.response.data
                                //         )}
                                //         type="error"
                                //       />
                                //     );
                                //   });
                              }

                              return true;
                            }

                            await processArray(sendListNew);

                            let collections = 0;
                            let renewals = 0;

                            if (props.location_type === "collections") {
                              collections = 1;
                            }

                            if (props.location_type === "renewals") {
                              renewals = 1;
                            }

                            console.log(msgList);

                            await axios({
                              method: "post",
                              url: `${process.env.REACT_APP_API_URL}send_bulk`,
                              data: {
                                infoList: msgList,
                                platform: values.platform,
                                from: props.current_staff?.id,
                                template: values.template,
                                template_name: values.template_name,
                                staff_id: props.current_staff?.id,
                                collections: collections,
                                renewals: renewals,
                              },
                            })
                              .then((response) => {
                                toast(
                                  <NotificationToast
                                    title={values.platform + " successful"}
                                    message={"Your messages has been queued."}
                                  />
                                );
                              })
                              .catch((error) => {
                                console.log(error);
                                toast(
                                  <NotificationToast
                                    title="Something went wrong"
                                    message={JSON.stringify(
                                      error.response.data
                                    )}
                                    type="error"
                                  />
                                );
                              });
                          } else {
                            async function processArrayPay(array) {
                              for (const item of array) {
                                console.log("paying " + item.b + " " + item.c);

                                let paymentValues = {
                                  user_id: item.f,
                                  otp: values.otp,
                                };

                                await axios({
                                  method: "post",
                                  url: `${process.env.REACT_APP_API_URL}payment_via_otp`,
                                  data: paymentValues,
                                })
                                  .then((response) => {
                                    if (response.data[0] === "PAYMENT FAILED") {
                                      toast(
                                        <NotificationToast
                                          title={"Payment Failed"}
                                          message={response.data[1]}
                                          list={true}
                                          type="error"
                                        />
                                      );
                                    } else {
                                      toast(
                                        <NotificationToast
                                          title={"Payment successful"}
                                          message={
                                            "Your Payment has been queued."
                                          }
                                        />
                                      );
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                    toast(
                                      <NotificationToast
                                        title="Something went wrong"
                                        message={JSON.stringify(
                                          error.response.data
                                        )}
                                        type="error"
                                      />
                                    );
                                  });
                              }

                              return true;
                            }

                            await processArrayPay(sendListNew);
                          }

                          setSubmitting(false);

                          resetForm();
                        }
                      }}
                    >
                      {({
                        values,
                        errors,
                        setFieldValue,
                        handleSubmit,
                        isValid,
                        isSubmitting,
                        dirty,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="rounded-3xl w-full"
                        >
                          {/* Buttons to which modal selection - bulk message or bulk pay */}
                          <div className="flex mt-3">
                            <button
                              type="button"
                              onClick={() => setModalAction("Messages")}
                              className={
                                modalAction === "Messages"
                                  ? activeClass
                                  : inactiveClass
                              }
                            >
                              <h2 className="text-center text-white px-2 py-2 uppercase ">
                                messages
                              </h2>
                            </button>
                            {current_staff.id == "291" ||
                            current_staff.id == "1" ||
                            current_staff.id == "23399" ||
                            current_staff.id == "14102" ? (
                              <button
                                type="button"
                                onClick={() => setModalAction("Payments")}
                                className={
                                  modalAction === "Payments"
                                    ? activeClass
                                    : inactiveClass
                                }
                              >
                                <h2 className="text-center text-white px-2 py-2 uppercase ">
                                  payments
                                </h2>
                              </button>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* For some reason this overflow isn't working, I want to make the table scrollable */}
                          <div className="max-h-96 overflow-auto">
                            <div className="flex justify-start p-2 bg-dark_background">
                              <select
                                name="property_id"
                                onChange={(e) => setProp(e)}
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                              >
                                <option value="">All Buildings</option>
                                <option value="1000">No Building</option>

                                {properties &&
                                  properties.length > 0 &&
                                  properties.map((p) => (
                                    <option key={p.value} value={p.value}>
                                      {p.label}
                                    </option>
                                  ))}
                              </select>

                              <select
                                name="unit_type"
                                onChange={(e) => setUnit(e)}
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                              >
                                <option value="">All Units</option>

                                {unitTypes &&
                                  unitTypes.length > 0 &&
                                  unitTypes.map((p) => (
                                    <option key={p.value} value={p.value}>
                                      {p.label}
                                    </option>
                                  ))}
                              </select>

                              {process.env.REACT_APP_COLOUR == "UC" && (
                                <select
                                  name="unit_type"
                                  onChange={(e) => setFunding(e)}
                                  className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                                >
                                  <option value="">All Funding Types</option>

                                  {studentTypes &&
                                    studentTypes.length > 0 &&
                                    studentTypes.map((p) => (
                                      <option key={p.value} value={p.value}>
                                        {p.label}
                                      </option>
                                    ))}
                                </select>
                              )}

                              <select
                                name="chat_type"
                                onChange={(e) => setClosed(e)}
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                              >
                                <option value="">Open and Closed</option>

                                {closeType &&
                                  closeType.length > 0 &&
                                  closeType.map((p) => (
                                    <option key={p.value} value={p.value}>
                                      {p.label}
                                    </option>
                                  ))}
                              </select>

                              <select
                                name="booking_year"
                                onChange={(e) => setYear(e)}
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                              >
                                <option value="">All Years</option>
                                <option value="1">2023</option>
                                <option value="2">2024</option>
                              </select>
                            </div>

                            <div className="flex justify-start p-2 bg-dark_background">
                              <input
                                type="number"
                                placeholder="Older than X days"
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                                onChange={(value) => {
                                  setSelOlderThan(value.target.value);
                                  setListLoaded(false);
                                }}
                                value={selOlderThan}
                              />

                              <input
                                type="number"
                                placeholder="Newer than X days"
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                                onChange={(value) => {
                                  setSelNewerThan(value.target.value);
                                  setListLoaded(false);
                                }}
                                value={selNewerThan}
                              />

                              {/* <select
                                name="documents"
                                onChange={(e) => setDoc(e)}
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-5"
                              >
                                <option value="0">All Document Status</option>
                                <option value="1">Documents Uploaded</option>
                                <option value="2">No Documents Uploaded</option>
                              </select> */}
                            </div>

                            {(modalAction === "Messages" &&
                              values.platform != "") ||
                            modalAction === "Payments" ? (
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      colSpan="5"
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-center font-bold text-gray-900 sm:pl-6"
                                    >
                                      Audience -{" "}
                                      {sendList &&
                                        sendList.filter(
                                          (sl) => sl.checked === true
                                        ).length}{" "}
                                      Selected
                                    </th>
                                    <th
                                      colSpan="2"
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-center font-bold text-gray-900 sm:pl-6"
                                    >
                                      Select All
                                      <input
                                        type="checkbox"
                                        className="ml-2"
                                        onChange={(e) =>
                                          changeListAll(e.target.checked)
                                        }
                                      />
                                    </th>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                      Building
                                    </th>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                      Booking
                                    </th>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                      Location
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Open
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Year
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                    >
                                      Send To
                                    </th>
                                  </tr>
                                </thead>

                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {sendList &&
                                    sendList.length > 0 &&
                                    props.bookings.length > 0 &&
                                    sendList.map((res) => {
                                      return (
                                        <tr key={res.id}>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {res.i ? (
                                              properties.map((p) => {
                                                return (
                                                  <>
                                                    {p.value === res.i
                                                      ? p.label
                                                      : ""}
                                                  </>
                                                );
                                              })
                                            ) : (
                                              <>Not Assigned</>
                                            )}
                                          </td>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {res.e}
                                          </td>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {res.p}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {res.b} {res.c} |{" "}
                                            {res.n ? (
                                              <TimeSince time={res.n} />
                                            ) : (
                                              " No Messages"
                                            )}
                                          </td>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {res.g === 1 ? "Closed" : "Open"}
                                          </td>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {res.af === 1 ? "2023" : "2024"}
                                          </td>
                                          <td className="text-center">
                                            {modalAction === "Messages" ? (
                                              <>
                                                {((values.platform ===
                                                  "whatsapp" &&
                                                  res.k) ||
                                                  (values.platform === "sms" &&
                                                    res.k) ||
                                                  values.platform === "note" ||
                                                  (values.platform ===
                                                    "email" &&
                                                    res.l)) && (
                                                  <input
                                                    type="checkbox"
                                                    onChange={(e) =>
                                                      changeList(e, res)
                                                    }
                                                    checked={res.checked}
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {res.t !== null ? (
                                                  <input
                                                    type="checkbox"
                                                    onChange={(e) =>
                                                      changeList(e, res)
                                                    }
                                                    checked={res.checked}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            ) : (
                              ""
                            )}
                          </div>

                          {modalAction === "Messages" ? (
                            <div className="pr-6 bg-dark_background pt-8">
                              {values.platform === "whatsapp" && (
                                <p className="text-white ml-2">
                                  *Please Note: Bulk messages will only process
                                  if credits are more than $50
                                </p>
                              )}
                              <div className="flex justify-between pr-6 bg-dark_background pt-2">
                                <div className="flex pl-6 space-x-4 h-8">
                                  <BookingPlatformButton
                                    valuePlatform={values.platform}
                                    buttonPlatform={"whatsapp"}
                                    icon={faWhatsapp}
                                    message={"WhatsApp"}
                                    from={
                                      process.env
                                        .REACT_APP_WHATSAPP_BUSINESS_NUMBER
                                    }
                                    to="bulk"
                                    setFieldValue={setFieldValue}
                                  />
                                  <BookingPlatformButton
                                    valuePlatform={values.platform}
                                    buttonPlatform={"sms"}
                                    icon={regular("message-lines")}
                                    message={"SMS"}
                                    from={
                                      process.env
                                        .REACT_APP_WHATSAPP_BUSINESS_NUMBER
                                    }
                                    to="bulk"
                                    setFieldValue={setFieldValue}
                                  />

                                  <BookingPlatformButton
                                    valuePlatform={values.platform}
                                    buttonPlatform={"email"}
                                    icon={regular("envelope")}
                                    message={"Email"}
                                    from={process.env.REACT_APP_FROM_EMAIL}
                                    to="bulk"
                                    setFieldValue={setFieldValue}
                                  />
                                  <BookingPlatformButton
                                    valuePlatform={values.platform}
                                    buttonPlatform={"note"}
                                    icon={regular("note")}
                                    message={"Note"}
                                    from={current_staff?.id}
                                    to={""}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                                <BookingTemplate
                                  platform={values.platform}
                                  setFieldValue={setFieldValue}
                                  handleSubmit={handleSubmit}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="flex justify-between pr-6 bg-dark_background pt-8 pb-5">
                                <label
                                  htmlFor="amount"
                                  className="text-white px-2 mt-1"
                                >
                                  Amount
                                </label>
                                <NumberFormat
                                  thousandSeparator={false}
                                  allowNegative={false}
                                  name="amount"
                                  onChange={(value) =>
                                    setFieldValue("amount", value.target.value)
                                  }
                                  placeholder="Amount In Rands"
                                  className="rounded-r-lg py-2 pl-1 truncate w-full xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border"
                                />
                              </div>
                              {needOtp === false ? (
                                <div className="flex justify-between pr-6 bg-dark_background pt-8 pb-5">
                                  <label
                                    htmlFor="otp"
                                    className="text-white px-2 mt-1"
                                  >
                                    OTP
                                  </label>
                                  <NumberFormat
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    name="otp"
                                    onChange={(value) =>
                                      setFieldValue("otp", value.target.value)
                                    }
                                    placeholder="OTP"
                                    className="rounded-r-lg py-2 pl-1 truncate w-full xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}

                          {modalAction === "Messages" ? (
                            <>
                              {values.platform === "email" ||
                              values.platform === "note" ? (
                                <BookingWysiwyg
                                  name="message"
                                  id="message"
                                  initialValues=""
                                  placeholder="Write your Message"
                                  setFieldValue={setFieldValue}
                                  staff={props.staff}
                                  value={values.message}
                                />
                              ) : (
                                <BookingTextArea
                                  as="textarea"
                                  rows={5}
                                  name="message"
                                  id="message"
                                  placeholder="Write your Message"
                                  errors={errors}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <BookingTextArea
                                as="textarea"
                                rows={5}
                                name="reason"
                                id="reason"
                                placeholder="Write your Reason"
                                errors={errors}
                              />
                              <p>Shortcode: [Tenant Code]</p>
                            </>
                          )}

                          <div className="px-6 inline-flex bg-light_background w-full">
                            <p>&nbsp;</p>
                            {errors.message && (
                              <p className="text-sm text-red-400 mr-4">
                                {errors.message}
                              </p>
                            )}
                            {errors.platform && (
                              <p className="text-sm text-red-400">
                                {errors.platform}
                              </p>
                            )}
                          </div>

                          {modalAction === "Messages" ? (
                            <div className="px-6 py-4 ml-auto flex justify-between items-center space-x-5 bg-dark_background rounded-b-3xl">
                              <div className="flex">
                                <EmojiPicker
                                  value={values.message}
                                  source="booking"
                                  setFieldValue={setFieldValue}
                                />
                                {/* {values.platform !== "sms" && (
                                  <BookingFileUpload
                                    setFieldValue={setFieldValue}
                                    values={values.files}
                                    max_files={
                                      values.platform === "whatsapp" ? 1 : ""
                                    }
                                  />
                                )} */}
                                <BookingPlaceholder
                                  setFieldValue={setFieldValue}
                                  value={values.message}
                                />
                              </div>
                              <div className="pr-4 ">
                                {values.platform !== "" && (
                                  <SubmitButton
                                    name="Submit"
                                    disabled={
                                      !isValid || isSubmitting || !dirty
                                    }
                                    isSubmitting={isSubmitting}
                                    text="Send"
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <>
                              {needOtp === true ? (
                                <div className="px-6 py-4 ml-auto flex flex-row-reverse justify-between items-center space-x-5 bg-dark_background rounded-b-3xl">
                                  <CustomButton
                                    type="button"
                                    name="requestOtp"
                                    onClick={() =>
                                      batchOTP(values.amount, values.reason)
                                    }
                                    text="Request OTP"
                                  />
                                </div>
                              ) : (
                                <div className="px-6 py-4 ml-auto flex flex-row-reverse justify-between items-center space-x-5 bg-dark_background rounded-b-3xl">
                                  <SubmitButton
                                    name="Submit"
                                    disabled={
                                      !isValid || isSubmitting || !dirty
                                    }
                                    isSubmitting={isSubmitting}
                                    text="Make Payment"
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </ErrorBoundary>
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
