import React, { useState, useEffect } from "react";
import FileUpload from "../Forms/FileUpload";
import { useParams } from "react-router-dom";
import axios from "axios";
import FileDisplaySimple from "../Forms/FileDisplaySimple";
import AppLoader from "../Helpers/AppLoader";

export default function DocumentsUC() {
    const { url } = useParams();
    const [current_booking, setCurrentBooking] = useState({});
    const [loading, setLoading] = useState(false);

    // console.log(current_booking);

    useEffect(async () => {
        if (url !== "" && url != "0" && !current_booking?.id) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    setCurrentBooking(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    window.location.href = "/404";
                });
        }
    });

    return (
        <div>
            <div className="uc">
                <div className="pb-32 elative bg-dark_background">
                    <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                        <div className="flex items-center">
                            <img className="h-24" src="/images/logo_uc.svg" alt="Urban Circle" />
                            <div className="py-6 lg:py-0 mx-auto md:w-96 text-center text-white text-4xl uppercase font-bold">
                                <h1>Documents</h1>
                            </div>
                            <div>
                                <a
                                    target="_blank"
                                    href="https://api.whatsapp.com/send?phone=270871632011"
                                    className="h-12 rounded-full text-white px-5 flex justify-between items-center font-bold"
                                    style={{ background: "#62D43F" }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.364" height="25.364" viewBox="0 0 25.364 25.364" className="mr-5">
                                        <path
                                            id="Icon_simple-whatsapp"
                                            data-name="Icon simple-whatsapp"
                                            d="M18.492,15.2c-.318-.159-1.867-.916-2.156-1.021s-.5-.159-.711.159-.815,1.019-1,1.228-.369.222-.683.079a8.577,8.577,0,0,1-2.54-1.569,9.588,9.588,0,0,1-1.754-2.188c-.184-.317-.02-.491.137-.65.144-.143.318-.365.477-.553a2.679,2.679,0,0,0,.314-.524.581.581,0,0,0-.026-.554c-.079-.159-.71-1.712-.974-2.331s-.515-.539-.71-.539c-.182-.016-.392-.016-.6-.016a1.191,1.191,0,0,0-.842.379,3.528,3.528,0,0,0-1.1,2.616,6.184,6.184,0,0,0,1.288,3.25A14.092,14.092,0,0,0,13,17.705c.755.317,1.342.507,1.8.665a4.372,4.372,0,0,0,1.987.128,3.252,3.252,0,0,0,2.131-1.507,2.616,2.616,0,0,0,.19-1.506c-.078-.143-.285-.222-.6-.365m-5.756,7.866H12.73a10.572,10.572,0,0,1-5.342-1.458l-.38-.226-3.963,1.03L4.106,18.48l-.253-.4A10.482,10.482,0,0,1,20.178,5.153a10.344,10.344,0,0,1,3.074,7.387,10.5,10.5,0,0,1-10.5,10.447M21.686,3.645A12.832,12.832,0,0,0,12.73,0,12.565,12.565,0,0,0,1.792,18.852L0,25.364l6.7-1.746a12.748,12.748,0,0,0,6.035,1.529h.006A12.619,12.619,0,0,0,25.364,12.575,12.465,12.465,0,0,0,21.67,3.686"
                                            fill="#fff"
                                        />
                                    </svg>
                                    <span className="mr-3">NEED HELP?</span>
                                </a>
                            </div>
                        </div>
                    </header>
                </div>
            </div>

            <main className="-mt-32">
                <div>
                    <div style={{ marginLeft: "-200px", marginRight: "200px", paddingLeft: "200px", borderTopRightRadius: "50px" }} className="bg-white min-h-screen shadow-md rounded-tl-3xl">
                        <div className="h-20 flex">
                            <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                <div className="text-white text-2xl font-bold uppercase">Please upload your documents</div>
                            </div>
                            <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                        </div>

                        {current_booking &&
                        current_booking?.user?.name &&
                        current_booking?.user?.surname &&
                        current_booking?.user?.cell &&
                        current_booking?.user?.email &&
                        current_booking?.user?.profile?.id_number ? (
                            <>
                                <div className="mx-auto bg-white max-w-7xl">
                                    <main className="mt-14">
                                        <div className="max-w-7xl mx-auto pb-2 px-4">
                                            <div className="w-full">
                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                    {" "}
                                                    <div className="col-span-6 sm:col-span-3">
                                                        <p>We require the following files from you:</p>
                                                        {current_booking.student_type == 3 && (
                                                            <ul className="text-sm ml-4">
                                                                <li>Sponsor: 3 months bank statement</li>
                                                                <li>Sponsor: Recent payslip</li>
                                                                <li>Sponsor: Proof of residence</li>
                                                                <li>Student ID</li>
                                                                <li>Student Card</li>
                                                                <li>Proof of Registration</li>
                                                            </ul>
                                                        )}

                                                        {current_booking.student_type == 2 && (
                                                            <ul className="text-sm ml-4">
                                                                <li>Student ID</li>
                                                                <li>Student Card</li>
                                                                <li>Proof of Registration</li>
                                                                <li>ID</li>
                                                            </ul>
                                                        )}

                                                        {current_booking.student_type != 2 && current_booking.student_type != 3 && (
                                                            <ul className="text-sm ml-4">
                                                                <li>Student Card</li>
                                                                <li>Proof of Registration</li>
                                                                <li>ID</li>
                                                                <li>Proof of Nsfas</li>
                                                            </ul>
                                                        )}
                                                        <ul className="text-sm ml-4">
                                                            <li>Copy of your ID</li>
                                                            <li>Copy of your Proof of Address</li>
                                                            <li>Copy of 3 Month Payslips</li>
                                                            <li>Copy of 3 Month Bank Statements</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-span-6 sm:col-span-3">
                                                        {current_booking?.id && (
                                                            <>
                                                                <FileUpload current_booking={current_booking} setCurrentBooking={setCurrentBooking} url={url} file_type="" />

                                                                {current_booking?.files && current_booking.files.length > 0 ? (
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                        <ul role="list" className="border border-dark_background rounded-md divide-y divide-dark_background">
                                                                            {current_booking.files.map((file) => (
                                                                                <FileDisplaySimple file={file} key={file.id} />
                                                                            ))}
                                                                        </ul>
                                                                    </dd>
                                                                ) : (
                                                                    <p>No files have been uploaded</p>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="mx-auto bg-white max-w-7xl">
                                    <main className="mt-14">
                                        <div className="max-w-7xl mx-auto pb-2 px-4">
                                            <div className="w-full">
                                                <p>Please fill in the Application Form first</p>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
}
