import { useEffect, useState } from "react";
import { useField } from "formik";
import { Switch } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function SettingsSwitch({ options, ...props }) {
    const [enabled, setEnabled] = useState(props.value);
    const [field, meta, { setValue }] = useField(props);

    const onChange = () => {
        if (enabled) {
            setValue(0);
            setEnabled(false);
        } else {
            setValue(1);
            setEnabled(true);
        }
    };

    return (
        <Switch.Group as="div" className="flex items-center justify-between col-span-6 sm:col-span-3">
            <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium mb-2 text-gray-700" passive htmlFor={props.name}>
                    {props.label}
                    {props.required && <FontAwesomeIcon className="w-2 mb-1 text-red-400" icon={regular("asterisk")} />}
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-400">
                    {props.description ? props.description : <span>Toggle the option to yes (&#x2713;) or no (x)</span>}
                </Switch.Description>
            </span>
            <Switch
                checked={enabled}
                name={props.name}
                value={props.value}
                onChange={onChange}
                disabled={props.disabled}
                className={classNames(
                    enabled ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                )}
            >
                <span
                    className={classNames(
                        enabled ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                >
                    <span
                        className={classNames(
                            enabled ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200",
                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true"
                    >
                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    <span
                        className={classNames(
                            enabled ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100",
                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true"
                    >
                        <svg className="h-3 w-3 text-primary" fill="currentColor" viewBox="0 0 12 12">
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                    </span>
                </span>
            </Switch>
        </Switch.Group>
    );
}
