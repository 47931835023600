import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import AppLoader from "../../components/Helpers/AppLoader";

// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
import { Tab } from "@headlessui/react";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import SubmitButton from "../../components/Forms/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faCirclePlus, faPenCircle, faTrashCircle } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import SettingsDatePicker from "../../components/Forms/SettingsDatePicker";
import SettingsSwitch from "../../components/Forms/SettingsSwitch";
import PhoneInput from "react-phone-number-input";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_apiKey,
//     authDomain: process.env.REACT_APP_authDomain,
//     projectId: process.env.REACT_APP_projectId,
//     storageBucket: process.env.REACT_APP_storageBucket,
//     messagingSenderId: process.env.REACT_APP_messagingSenderId,
//     appId: process.env.REACT_APP_appId,
// };

const navigation = [{ name: "Student Details" }, { name: "Next of Kin" }, { name: "Responsible for Funding" }, { name: "Referral Information" }];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function EditUserUC() {
    // const dispatch = useDispatch();
    const { user_id } = useParams();
    const { booking_id } = useParams();
    // const { current_staff } = useSelector((state) => state.auth);
    // const app = initializeApp(firebaseConfig);
    // const db = getFirestore(app);

    const [current_booking, setCurrentBooking] = useState([]);
    // const [url, setUrl] = useState("");
    const [log, setLog] = useState({});
    const [notes, setNotes] = useState({});
    // const { properties } = useSelector((state) => state.properties);
    const [lookups, setLookups] = useState([]);

    const [units, setUnits] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [initialValues, setInitialValues] = useState({});

    const [loading, setLoading] = useState(true);
    const [auditloading, setAuditLoading] = useState(false);
    const [notesLoading, setNotesLoading] = useState(false);

    const [bankList, setBankList] = useState([]);

    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);

    useEffect(async () => {
        setLoading(true);

        if (loading) {
            getUser();

            if (current_booking) {
                fetchNotes();
            }

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}uc_lookups`,
            })
                .then((response) => {
                    setLookups(response.data);
                })
                .catch((error) => {
                    alert("lookups failed");
                });

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}paystack_banks`,
            })
                .then((response) => {
                    let banks = response.data.data;
                    let bankList = [];

                    Object.keys(banks).forEach((key) => {
                        bankList.push({ key: banks[key].name, value: banks[key].name, label: banks[key].name, code: banks[key].code });
                    });

                    setBankList(bankList);
                })
                .catch((error) => {
                    alert("Bank failed");
                });
        }

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, []);

    async function getUser() {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}booking_user_id/${user_id}/${booking_id}`,
        }).then((response) => {
            const data = response.data;
            fetchAudit();
            setCurrentBooking(data);

            setInitialValues({
                url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
                name: data.user.name,
                surname: data.user.surname,
                email: data.user.email,
                cell: "+" + data.user.cell,

                study_year: data.user.profile ? data.user.profile.study_year : "",

                title: data.user.profile ? data.user.profile.title : "",
                gender: data.user.profile ? data.user.profile.gender : "",
                date_of_birth: data.user.profile ? data.user.profile.date_of_birth : "",
                nationality: data.user.profile ? data.user.profile.nationality : "",
                id_number: data.user.profile ? data.user.profile.id_number : "",
                student_number: data.user.profile ? data.user.profile.student_number : "",
                ethnicity: data.user.profile ? data.user.profile.ethnicity : "",
                nationality: data.user.profile ? data.user.profile.nationality : "",
                institution_study: data.user.profile ? data.user.profile.institution_study : "",
                institution_campus: data.user.profile ? data.user.profile.institution_campus : "",
                faculty: data.user.profile ? data.user.profile.faculty : "",
                student_type: data.student_type ? data.student_type : "",
                banking_bank: data.user.profile?.banking_bank ? data.user.profile.banking_bank : "",
                banking_account_type: data.user.profile?.banking_account_type ? data.user.profile.banking_account_type : "",
                banking_branch: data.user.profile?.banking_branch ? data.user.profile.banking_branch : "",
                banking_branch_code: data.user.profile?.banking_branch_code ? data.user.profile.banking_branch_code : "",
                banking_account_number: data.user.profile?.banking_account_number ? data.user.profile.banking_account_number : "",
                banking_account_holder: data.user.profile?.banking_account_holder ? data.user.profile.banking_account_holder : "",

                kin_title: data.user.next_of_kin ? data.user.next_of_kin.title : "",
                kin_name: data.user.next_of_kin ? data.user.next_of_kin.name : "",
                kin_surname: data.user.next_of_kin ? data.user.next_of_kin.surname : "",
                kin_relationship: data.user.next_of_kin ? data.user.next_of_kin.relationship : "",
                kin_id_number: data.user.next_of_kin ? data.user.next_of_kin.id_number : "",
                kin_contact: data.user.next_of_kin && data.user.next_of_kin.contact ? "+" + data.user.next_of_kin.contact : "",
                kin_email: data.user.next_of_kin ? data.user.next_of_kin.email : "",
                kin_address: data.user.next_of_kin ? data.user.next_of_kin.address : "",
                kin_suburb: data.user.next_of_kin ? data.user.next_of_kin.suburb : "",
                kin_city: data.user.next_of_kin ? data.user.next_of_kin.city : "",
                kin_postal: data.user.next_of_kin ? data.user.next_of_kin.postal : "",
                kin_tel_contact: data.user.next_of_kin && data.user.next_of_kin.tel_contact ? "+" + data.user.next_of_kin.tel_contact : "",
                kin_work_contact: data.user.next_of_kin && data.user.next_of_kin.work_contact ? "+" + data.user.next_of_kin.work_contact : "",

                parent_name: data.user.parent ? data.user.parent.name : "",
                parent_surname: data.user.parent ? data.user.parent.surname : "",
                parent_cell: data.user.parent && data.user.parent.cell ? "+" + data.user.parent.cell : "",
                parent_id_number: data.user.parent ? data.user.parent.id_number : "",
                parent_email: data.user.parent ? data.user.parent.email : "",

                referral_name: data.user.premises ? data.user.premises.name : "",
                referral_surname: data.user.premises ? data.user.premises.surname : "",
                referral_contact: data.user.premises && data.user.premises.contact ? "+" + data.user.premises.contact : "",
                referral_id_number: data.user.premises ? data.user.premises.id_number : "",
                referral: data.user.premises && data.user.premises.referral ? parseFloat(data.user.premises.referral) : "",
                referral_other: data.user.premises ? data.user.premises.referral_other : "",
                assisted_by: data.user.premises ? data.user.premises.assisted_by : "",
            });
            setLoading(false);
        });
    }

    async function getUnits(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}units/${id}`,
        }).then((response) => {
            setUnits(response.data);
        });
    }

    async function getUnitTypes(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
        }).then((response) => {
            setUnitTypes(response.data);
        });
    }

    if (loading === true) {
        return <AppLoader />;
    }

    async function fetchAudit() {
        setAuditLoading(true);
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}log/${user_id}`,
        })
            .then((response) => {
                setLog(response.data);
            })
            .finally(() => {
                setAuditLoading(false);
            });
    }

    async function fetchNotes() {
        setNotesLoading(true);
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}legacy_notes/${current_booking.id}`,
        })
            .then((response) => {
                setNotes(response.data);
                // console.log(response.data);
            })
            .finally(() => {
                setNotesLoading(false);
            });
    }

    function formatIcon(status) {
        let icon = faCirclePlus;

        switch (status) {
            case "deleted":
                icon = faTrashCircle;
                break;
            case "updated":
                icon = faPenCircle;
                break;
            default:
                break;
        }

        return icon;
    }

    function formatIconClass(status) {
        let icon_class = " text-green-600";

        switch (status) {
            case "deleted":
                icon_class = "text-red-600";
                break;
            case "updated":
                icon_class = "text-yellow-500";
                break;
            default:
                break;
        }

        return icon_class;
    }

    // make audit entry clear to read
    function formatLogBooleans(value) {
        switch (value) {
            case "0":
            case 0:
            case false:
                return "False";
            case "1":
            case 1:
            case true:
                return "True";
            case "":
            case null:
                return "N/A";
            default:
                return value;
        }
    }

    function formatLogModel(model) {
        let value = model.split("\\");
        return value[value.length - 1];
    }

    function formatLogTitle(log) {
        if (formatLogModel(log.auditable_type) === "File" && log.new_values["name"]) {
            return "(" + log.new_values["name"] + ")";
        }
    }

    return (
        <>
            <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen xl:flex w-full">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize="true"
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        setSubmitting(true);

                        console.log(values);

                        //format cell
                        values.cell = values.cell && values.cell.replace("+", "");
                        values.kin_contact = values.kin_contact && values.kin_contact.replace("+", "");
                        // values.kin_tel_contact = values.kin_tel_contact && values.kin_tel_contact.replace("+", "");
                        values.kin_work_contact = values.kin_work_contact && values.kin_work_contact.replace("+", "");
                        values.parent_cell = values.parent_cell && values.parent_cell.replace("+", "");
                        values.referral_contact = values.referral_contact && values.referral_contact.replace("+", "");

                        await Object.keys(bankList).forEach((key) => {
                            if (values.banking_bank == bankList[key].label) {
                                values.banking_bank_code = bankList[key].code;
                            }
                        });

                        await axios({
                            method: "put",
                            url: `${process.env.REACT_APP_API_URL}user`,
                            data: values,
                        })
                            .then((resp) => {
                                if (resp.data && resp.data.length > 0 && resp.data[0] == "error") {
                                    toast(<NotificationToast title="Something went wrong" message={resp.data[1]} type="error" />);
                                } else {
                                    toast(<NotificationToast title={current_booking.user.name + " " + current_booking.user.surname + " updated"} message={"Your changes have been saved."} />);
                                }
                                getUser();

                                //Add the + back for form prettiness
                                values.cell = "+" + values.cell;
                                values.kin_contact = "+" + values.kin_contact;
                                // values.kin_tel_contact = "+" + values.kin_tel_contact;
                                values.kin_work_contact = "+" + values.kin_work_contact;
                                values.parent_cell = "+" + values.parent_cell;
                                values.referral_contact = "+" + values.referral_contact;
                            })
                            .catch((error) => {
                                console.log(error);
                                console.log(error.response);
                            })
                            .finally(() => {
                                setSubmitting(false);
                                resetForm();
                            });
                    }}
                >
                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                            <div className="divide-y divide-gray-200 max-h-76 overflow-y-scroll xl:max-h-full xl:grid xl:grid-cols-12 xl:divide-y-0 xl:divide-x">
                                <Tab.Group>
                                    <Tab.List className="bg-light_background xl:col-span-2 rounded-3xl w-full xl:w-72 border-0">
                                        <div className="h-14 flex justify-evenly rounded-t-3xl items-center bg-dark_background">
                                            <Link
                                                className="text-md leading-6 text-white"
                                                to={{
                                                    pathname: "/booking/" + current_booking.location.toLowerCase().replace(/\s/g, ""),
                                                    search: "?user_id=" + current_booking.user?.id + "&booking_id=" + current_booking.id,
                                                    state: { from: "editUser", userUid: current_booking.user.id },
                                                }}
                                            >
                                                <FontAwesomeIcon className="mr-4" icon={regular("arrow-left-long")} />
                                            </Link>
                                            <h1 className="text-xl leading-6 font-light text-white capitalize">Editing {current_booking.user.name + " " + current_booking.user.surname}</h1>
                                            <FontAwesomeIcon className="mr-4 text-white" icon={regular("trash")} />
                                        </div>
                                        {navigation.map((item) => (
                                            <Tab
                                                key={item.name}
                                                className={({ selected }) =>
                                                    classNames(
                                                        "group w-full flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none transition ease-linear delay-10",
                                                        selected ? "border-l-primary bg-gray-100" : "bg-light_background border-l-light_background"
                                                    )
                                                }
                                            >
                                                <span className="truncate">{item.name}</span>
                                            </Tab>
                                        ))}

                                        <div className="mt-4 px-4">
                                            <SubmitButton
                                                name="Save"
                                                disabled={!isValid || isSubmitting || !dirty}
                                                isSubmitting={isSubmitting}
                                                icon={regular("save")}
                                                text="Save"
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                    </Tab.List>
                                    <Tab.Panels className="my-6 xl:my-0 xl:ml-28 2xl:ml-4 rounded-3xl bg-gray-100 xl:col-span-9">
                                        <Tab.Panel>
                                            <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background ">
                                                <h1 className="text-xl leading-6 font-light text-white capitalize">Applicant Information</h1>
                                            </div>

                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to the applicant and contains their details such as name and email address.</p>

                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                    <Field name="name" type="text" as={SettingsTextInput} label="First Name" required />

                                                    <Field name="surname" type="text" as={SettingsTextInput} label="Surname" required />

                                                    <Field name="email" type="email" as={SettingsTextInput} label="Email Address" />

                                                    <SettingsSelect
                                                        label="Gender"
                                                        name="gender"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.gender}
                                                        touched={touched.gender}
                                                        error={errors.gender}
                                                        options="gender"
                                                    />

                                                    <SettingsSelect
                                                        label="Title"
                                                        name="title"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.title}
                                                        touched={touched.title}
                                                        error={errors.title}
                                                        options="titles"
                                                    />

                                                    <Field name="date_of_birth" type="text" as={SettingsDatePicker} label="Birthday" id_number={values.id_number} nationality={values.nationality} />

                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Cellphone Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("cell", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="cell"
                                                                value={values.cell}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <Field name="cell" type="text" as={SettingsTextInput} prepend="+" label="Cellphone Number" required /> */}
                                                    <Field name="student_number" type="text" as={SettingsTextInput} label="Student Number" />

                                                    <SettingsSelect
                                                        label="Ethnicity"
                                                        name="ethnicity"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.ethnicity}
                                                        touched={touched.ethnicity}
                                                        error={errors.ethnicity}
                                                        options={lookups ? lookups[2] : null}
                                                    />

                                                    <SettingsSelect
                                                        label="Nationality"
                                                        name="nationality"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.nationality}
                                                        touched={touched.nationality}
                                                        error={errors.nationality}
                                                        options="nationality"
                                                    />
                                                    <Field name="id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" nationality={values.nationality} />

                                                    <SettingsSelect
                                                        label="Institution of Study"
                                                        name="institution_study"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.institution_study}
                                                        touched={touched.institution_study}
                                                        error={errors.institution_study}
                                                        options={lookups ? lookups[4] : null}
                                                    />
                                                    <SettingsSelect
                                                        label="Institution Campus"
                                                        name="institution_campus"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.institution_campus}
                                                        touched={touched.institution_campus}
                                                        error={errors.institution_campus}
                                                        options={lookups ? lookups[5] : null}
                                                    />

                                                    <Field name="faculty" type="text" as={SettingsTextInput} label="Faculty" />

                                                    {/* {values.student_type == 3 ? 
                                                        <> */}
                                                    <SettingsSelect
                                                        label="Bank"
                                                        name="banking_bank"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.banking_bank}
                                                        touched={touched.banking_bank}
                                                        error={errors.banking_bank}
                                                        options={bankList}
                                                    />
                                                    <Field name="banking_account_type" type="text" as={SettingsTextInput} label="Account Type" />
                                                    <Field name="banking_branch" type="text" as={SettingsTextInput} label="Branch" />
                                                    <Field name="banking_branch_code" type="text" as={SettingsTextInput} label="Branch Code" />
                                                    <Field name="banking_account_number" type="text" as={SettingsTextInput} label="Account Number" />
                                                    <Field name="banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" />
                                                    {/* </>
                                                    : ""} */}

                                                    {/* <Field
                                                                        name="password"
                                                                        type="password"
                                                                        as={ SettingsTextInput }
                                                                        label="Change Password"
                                                                    />

                                                                    <Field
                                                                        name="password_confirmation"
                                                                        type="password"
                                                                        as={ SettingsTextInput }
                                                                        label="Confirm Change of Password"
                                                                    /> */}
                                                </div>
                                            </div>
                                        </Tab.Panel>
                                        {/* Next of Kin */}
                                        <Tab.Panel>
                                            <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                                <h1 className="text-xl leading-6 font-light text-white capitalize">Next of Kin Information</h1>
                                            </div>
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">
                                                    This information is related to the Next of Kin for {current_booking.user.name} {current_booking.user.surname} (Applicant One)
                                                </p>

                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                    <SettingsSelect
                                                        label="Title"
                                                        name="kin_title"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.kin_title}
                                                        touched={touched.kin_title}
                                                        error={errors.kin_title}
                                                        options="titles"
                                                    />
                                                    <Field name="kin_name" type="text" as={SettingsTextInput} label="Name" />
                                                    <Field name="kin_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                    {/* <Field name="kin_id_number" type="text" as={SettingsTextInput} label="ID Number"  /> */}
                                                    <Field name="kin_address" type="text" as={SettingsTextInput} label="Address" />
                                                    <Field name="kin_suburb" type="text" as={SettingsTextInput} label="Suburb" />
                                                    <Field name="kin_city" type="text" as={SettingsTextInput} label="City" />
                                                    <Field name="kin_postal" type="text" as={SettingsTextInput} label="Postal Code" />
                                                    <Field name="kin_relationship" type="text" as={SettingsTextInput} label="Relationship" />
                                                    {/* <Field name="kin_tel_contact" type="text" as={SettingsTextInput} label="Telephone Number" prepend="+" required /> */}
                                                    {/* <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="kin_tel_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Telephone Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("kin_tel_contact", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="kin_tel_contact"
                                                                value={values.kin_tel_contact}
                                                            />
                                                        </div>
                                                    </div> */}

                                                    {/* <Field name="kin_contact" type="text" as={SettingsTextInput} label="Cellphone Number" prepend="+" required /> */}
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="kin_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Cellphone Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("kin_contact", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="kin_contact"
                                                                value={values.kin_contact}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <Field name="kin_work_contact" type="text" as={SettingsTextInput} label="Work Number" prepend="+" required /> */}
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="kin_work_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Work Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("kin_work_contact", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="kin_work_contact"
                                                                value={values.kin_work_contact}
                                                            />
                                                            <p className="text-red-400 text-sm font-light">{errors.kin_work_contact}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Panel>
                                        {/* Parent */}
                                        <Tab.Panel>
                                            <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                                <h1 className="text-xl leading-6 font-light text-white capitalize">Responsible for Funding</h1>
                                            </div>
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to Parent / Guarding / Sponsor or Bursary Details</p>

                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                    <Field name="parent_name" type="text" as={SettingsTextInput} label="First Name" />
                                                    <Field name="parent_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                    {/* <Field name="parent_cell" type="text" as={SettingsTextInput} prepend="+" label="Cellphone Number" required /> */}
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="parent_cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Cellphone Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("parent_cell", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="parent_cell"
                                                                value={values.parent_cell}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Field name="parent_id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" />
                                                </div>

                                                <div className="pb-8 px-4 xl:px-6 uppercase">
                                                    <Field name="parent_email" type="email" as={SettingsTextInput} label="Email Address" />
                                                </div>
                                            </div>
                                        </Tab.Panel>
                                        <Tab.Panel>
                                            <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                                <h1 className="text-xl leading-6 font-light text-white capitalize">Referral</h1>
                                            </div>
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to the Referral</p>

                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                    <Field name="referral_name" as={SettingsTextInput} label="First Name" />
                                                    <Field name="referral_surname" as={SettingsTextInput} label="Surname" />
                                                    {/* <Field name="referral_contact" as={SettingsTextInput} label="Cellphone Number" prepend="+" /> */}
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="referral_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Cellphone Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("referral_contact", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="referral_contact"
                                                                value={values.referral_contact}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Field name="referral_id_number" as={SettingsTextInput} label="ID. Number" />
                                                </div>

                                                <div className="pb-72 px-4 xl:px-6 uppercase">
                                                    <SettingsSelect
                                                        label="Referral Type"
                                                        name="referral"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.referral}
                                                        touched={touched.referral}
                                                        error={errors.referral}
                                                        options={lookups ? lookups[1] : null}
                                                    />
                                                    {values.referral === 50 && <Field name="referral_other" as={SettingsTextInput} label="What is the other Referral?" />}

                                                    <div className="py-8 space-y-6 sm:space-y-0 uppercase">
                                                        <Field name="assisted_by" as={SettingsTextInput} label="Where did you hear about us?" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Panel>
                                        {/* Audit Log */}
                                        <Tab.Panel>
                                            <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                                <h1 className="text-xl leading-6 font-light text-white capitalize">Audit Log</h1>
                                                <FontAwesomeIcon
                                                    icon={regular("sync")}
                                                    className={classNames(auditloading ? "animate-spin" : "", "ml-6 leading-6 text-white cursor-pointer")}
                                                    onClick={() => fetchAudit()}
                                                />
                                            </div>
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <ul role="list" className="space-y-6">
                                                    {log.length &&
                                                        log.map((l, index) => {
                                                            return (
                                                                <li key={l.id}>
                                                                    <div className="relative pb-8">
                                                                        {index !== log.length - 1 ? (
                                                                            <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                                                        ) : null}
                                                                        <div className="relative flex space-x-3">
                                                                            <FontAwesomeIcon icon={formatIcon(l.event)} className={`${formatIconClass(l.event)} + h-8 w-8`} />
                                                                            <div className="flex-1 space-y-1">
                                                                                <div className="flex items-center justify-between border-b-2 border-gray-200">
                                                                                    <p className="text-md text-gray-900 font-medium">
                                                                                        <span className="">
                                                                                            {l.user
                                                                                                ? `${l.user.name} ${l.user.surname}`
                                                                                                : `${current_booking.user.name} ${current_booking.user.surname}`}
                                                                                            {" " + l.event + " " + formatLogModel(l.auditable_type) + " "}-
                                                                                        </span>
                                                                                        <span className="text-md text-gray-600">
                                                                                            {" "}
                                                                                            {l.auditable_id} {formatLogTitle(l)}
                                                                                        </span>
                                                                                    </p>
                                                                                    <p>{moment(l.created_at).format("DD/MM/YYYY H:m:ss")}</p>
                                                                                </div>
                                                                                <div className="flex ">
                                                                                    <div className="w-1/2">
                                                                                        {l.old_values && l.event === "updated" && (
                                                                                            <>
                                                                                                <p className="font-medium">From</p>
                                                                                                <div className="flex">
                                                                                                    <ul>
                                                                                                        {Object.keys(l.old_values).map((key) => (
                                                                                                            <li key={key} className="font-light capitalize">
                                                                                                                {key} -
                                                                                                            </li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                    <ul>
                                                                                                        {Object.values(l.old_values).map((value) => (
                                                                                                            <li key={value} className="font-light ml-2 capitalize">
                                                                                                                {" "}
                                                                                                                {formatLogBooleans(value)}
                                                                                                            </li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="w-1/2">
                                                                                        {l.new_values && l.event === "updated" && (
                                                                                            <>
                                                                                                <p className="font-medium">To</p>
                                                                                                <div className="flex">
                                                                                                    <ul>
                                                                                                        {Object.keys(l.new_values).map((key) => (
                                                                                                            <li key={key} className="font-light capitalize">
                                                                                                                {key} -
                                                                                                            </li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                    <ul>
                                                                                                        {Object.values(l.new_values).map((value) => (
                                                                                                            <li key={value} className="font-light ml-2 capitalize">
                                                                                                                {" "}
                                                                                                                {formatLogBooleans(value)}
                                                                                                            </li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        </Tab.Panel>
                                        {/* Notes */}
                                        <Tab.Panel>
                                            <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                                <h1 className="text-xl leading-6 font-light text-white capitalize">Notes</h1>
                                                <FontAwesomeIcon
                                                    icon={regular("sync")}
                                                    className={classNames(notesLoading ? "animate-spin" : "", "ml-6 leading-6 text-white cursor-pointer")}
                                                    onClick={() => fetchNotes()}
                                                />
                                            </div>
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <ul role="list" className="space-y-6">
                                                    {notes.length &&
                                                        notes.map((n, index) => {
                                                            return (
                                                                <li key={n.id}>
                                                                    <div className="relative pb-8">
                                                                        <div className="relative flex space-x-3">
                                                                            <div className="flex-1 space-y-1">
                                                                                <div className="flex items-center justify-between border-b-2 border-gray-200">
                                                                                    <div className="text-md text-gray-900 font-medium">{n.user[0].name + " " + n.user[0].surname}</div>
                                                                                    <p>{moment(n.created_at).format("DD/MM/YYYY H:m:ss")}</p>
                                                                                </div>
                                                                                <div className="flex ">{n.message}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        </Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
