import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const AppLoader = () => {
    return (
        <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
            <span className="text-blue-comparemed top-1/2 my-0 mx-auto block relative w-0 h-0">
                <FontAwesomeIcon
                    icon={regular("spinner-third")}
                    className="text-black animate-spin text-5xl"
                />
            </span>
        </div>
    );
};

export default AppLoader;
