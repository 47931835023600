import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const LoadingIcon = () => {
    return (
        <FontAwesomeIcon icon={regular('spinner-third')} className="text-grey-600 animate-spin p-4" />
    );
};

export default LoadingIcon;
