import React from "react";
import PaymentForm from "./PaymentForm";
import ApplicationHeaderUC from "./ApplicationHeaderUC";

export default function ApplicationFormUCEdit({ chosenProperty, chosenUnit, chosenBed }) {
    return (
        <div className="uc">
            <ApplicationHeaderUC />

            <main className="-mt-32">
                <div>
                    <div className="bg-white min-h-screen shadow-md rounded-tl-3xl white_block_left">
                        <div className="h-20 flex">
                            <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                <div className="text-white text-2xl font-bold uppercase">How would you like to pay?</div>
                            </div>
                            <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                        </div>

                        <div className="mx-auto bg-white max-w-7xl">
                            <main className="mt-14">
                                <div className="max-w-7xl mx-auto pb-2 px-4">
                                    <div className="w-full">
                                        <PaymentForm showbtn="yes" />
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
