import React, { Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import QuickFormText from "../Forms/QuickFormText";
import QuickFormSelect from "../Forms/QuickFormSelect";
import QuickFormDate from "../Forms/QuickFormDate";
import SettingsSwitch from "../Forms/SettingsSwitch";
import StudentSignatureCanvas from "../Forms/StudentSignatureCanvas";
import * as Yup from "yup";

export default function ModalRefund(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          /* This element is to trick the browser into centering the modal
          contents. */
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={regular("times")}
                    />
                  </button>
                </div>

                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      TENANT DEPOSIT REFUND CHECKLIST
                    </Dialog.Title>

                    <div className="mt-4">
                      <div className="grid grid-cols-2">
                        <label
                          htmlFor="mdaTenantCode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          1. MDA Tenant Code
                        </label>
                        <div>
                          {props.current_booking?.user_mda?.mda_tenant_id}
                        </div>
                      </div>
                    </div>

                    <Formik
                      initialValues={{
                        user_id: props.current_booking?.user?.id,
                        booking_id: props.current_booking?.id,
                        moveOutDate: "",
                        depositCharged: 0,
                        depositAppropriated: 0,
                        reinstatementCharged: 0,
                        finalUtilities: 0,
                        earlyTerminationApply: 0,
                        penaltyCharged: 0,
                        correctNoticePeriodGiven: 0,
                        refundJournalProcessed: 0,
                        recurringChargesUnticked: 0,
                        accountingClosedOnMda: 0,
                        financeMentionedInRefundSeat: 0,
                        accept: 0,
                      }}
                      validateOnChange={true}
                      validateOnBlur={true}
                      validationSchema={Yup.object().shape({
                        depositCharged: Yup.boolean().oneOf(
                          [true],
                          "Deposit checkbox must be checked"
                        ),
                        depositAppropriated: Yup.boolean().oneOf(
                          [true],
                          "Deposit checkbox must be checked"
                        ),
                        reinstatementCharged: Yup.boolean().oneOf(
                          [true],
                          "Deposit checkbox must be checked"
                        ),
                        finalUtilities: Yup.boolean().oneOf(
                          [true],
                          "Deposit checkbox must be checked"
                        ),
                        earlyTerminationApply: Yup.boolean().oneOf(
                          [true],
                          "Deposit checkbox must be checked"
                        ),
                        correctNoticePeriodGiven: Yup.boolean().oneOf(
                          [true],
                          "Deposit checkbox must be checked"
                        ),
                        refundJournalProcessed: Yup.boolean().oneOf(
                          [true],
                          "Deposit checkbox must be checked"
                        ),
                        recurringChargesUnticked: Yup.boolean().oneOf(
                          [true],
                          "Deposit checkbox must be checked"
                        ),
                        accountingClosedOnMda: Yup.boolean().oneOf(
                          [true],
                          "Deposit checkbox must be checked"
                        ),
                        financeMentionedInRefundSeat: Yup.boolean().oneOf(
                          [true],
                          "Required"
                        ),
                        accept: Yup.boolean().oneOf([true], "Required"),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        // Handle form submission
                        axios({
                          method: "post",
                          url:
                            process.env.REACT_APP_API_URL +
                            "payment_refund_request",
                          data: values,
                        }).then((response) => {
                          window.location.reload();
                        });
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        isValid,
                        isSubmitting,
                        dirty,
                      }) => (
                        <Form>
                          <div className="mt-4">
                            <Field
                              name="moveOutDate"
                              as={QuickFormDate}
                              label="Move Out Date"
                              required
                            />
                          </div>

                          <div className="mt-4">
                            <Field
                              name="depositCharged"
                              as={SettingsSwitch}
                              label="1. Deposit Charged"
                              required
                            />
                            {errors.depositCharged && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <Field
                              name="depositAppropriated"
                              as={SettingsSwitch}
                              label="2. Deposit Appropriated"
                              required
                            />
                            {errors.depositAppropriated && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <Field
                              name="reinstatementCharged"
                              as={SettingsSwitch}
                              label="3. Reinstatement Charged"
                              required
                            />
                            {errors.reinstatementCharged && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <Field
                              name="finalUtilities"
                              as={SettingsSwitch}
                              label="4. Final Utilities"
                              required
                            />
                            {errors.finalUtilities && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <Field
                              name="earlyTerminationApply"
                              as={SettingsSwitch}
                              label="5. Does Early Termination Apply?"
                              required
                            />
                            {errors.earlyTerminationApply && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          {values.earlyTerminationApply && (
                            <div className="mt-4">
                              <Field
                                name="penaltyCharged"
                                as={SettingsSwitch}
                                label="Penalty Charged"
                              />
                            </div>
                          )}

                          <div className="mt-4">
                            <Field
                              name="correctNoticePeriodGiven"
                              as={SettingsSwitch}
                              label="6. Correct Notice Period Given"
                              required
                            />
                            {errors.correctNoticePeriodGiven && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <Field
                              name="refundJournalProcessed"
                              as={SettingsSwitch}
                              label="7. Refund Journal Processed"
                              required
                            />
                            {errors.refundJournalProcessed && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <Field
                              name="recurringChargesUnticked"
                              as={SettingsSwitch}
                              label="8. Recurring Charges Unticked"
                              required
                            />
                            {errors.recurringChargesUnticked && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <Field
                              name="accountingClosedOnMda"
                              as={SettingsSwitch}
                              label="9. Accounting Closed on MDA"
                              required
                            />
                            {errors.accountingClosedOnMda && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <Field
                              name="financeMentionedInRefundSeat"
                              as={SettingsSwitch}
                              label="10. Finance Mentioned in Refund Seat on Romi"
                              required
                            />
                            {errors.financeMentionedInRefundSeat && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <Field
                              name="accept"
                              as={SettingsSwitch}
                              label="I accept that everything is correct and the refund can be processed"
                              required
                            />
                            {errors.accept && (
                              <p className="text-red-500">Required</p>
                            )}
                          </div>

                          <div className="mt-4">
                            <SubmitButton
                              name="Save"
                              disabled={!isValid || isSubmitting || !dirty}
                              isSubmitting={isSubmitting}
                              icon={regular("save")}
                              text="Submit Refund Form"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
