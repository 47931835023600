import React, { Fragment, useEffect, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";

import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import FileDisplay from "../Forms/FileDisplay";
import ViewFile from "../Helpers/ViewFile";

export default function ModalViewFiles(props) {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const dispatch = useDispatch();
  const [open] = useState(true);
  let file_request = [];

  props.files.map((file) => {
    file_request.push(file.id);
  });

  useEffect(() => {
    if (file_request) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view_files`,
        data: file_request,
      })
        .then((response) => {
          setFiles(response.data);
          setLoading(false);
        })
        .catch(() => {
          setFiles([]);
          setLoading(false);
        });
    }
  }, [setFiles]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={regular("times")}
                    />
                  </button>
                </div>
                {loading && (
                  <FontAwesomeIcon
                    className="h-10 w-10 text-gray-900 animate-spin ml-52"
                    icon={regular("loader")}
                  />
                )}
                {!loading && files.empty && <p>No files found</p>}

                {!files.empty ? (
                  files.map((file) => (
                    <div
                      key={file.id}
                      className="py-4 text-center sm:mt-0 sm:ml-4 sm:text-left"
                    >
                      {!file.encrypted ? (
                        <div>
                          <FileDisplay
                            file={file}
                            key={file.id}
                            callback={true}
                          />
                          {file.type.includes("pdf") && (
                            <ViewFile file={file} />
                          )}
                          {file.type.includes("image") && (
                            <img
                              src={`data:image/jpg;base64,${file.content}`}
                              alt="image"
                            />
                          )}

                          {!file.type.includes("image") &&
                            !file.type.includes("pdf") && (
                              <div className="flex items-center bg-red-100 rounded-md p-4">
                                <FontAwesomeIcon
                                  className="h-4 w-4 text-red-800 mr-4"
                                  icon={solid("circle-xmark")}
                                />

                                {file.type.includes("null") ? (
                                  <>
                                    <p className="text-red-800">
                                      This file is corrupted.
                                    </p>
                                    <p className="text-red-800">
                                      {" "}
                                      Please request a new file and delete this
                                      one
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p className="text-red-800">
                                      Format cannot be translated.
                                    </p>
                                    <p className="text-red-800">
                                      {" "}
                                      Please download the file.
                                    </p>
                                  </>
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <>
                          <FileDisplay
                            file={file}
                            key={file.id}
                            callback={true}
                          />
                          <div className="flex items-center bg-red-100 rounded-md p-4">
                            <FontAwesomeIcon
                              className="h-4 w-4 text-red-800 mr-4"
                              icon={solid("circle-xmark")}
                            />
                            <p className="text-red-800">
                              The File is Encrypted. Please download the file
                              and use the password.
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <Dialog.Title
                    as="h1"
                    className="text-lg font-medium text-gray-900"
                  >
                    No Files has been uploaded
                  </Dialog.Title>
                )}
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
