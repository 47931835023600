import React, { useEffect, Fragment, useState } from "react";
import { closeModal, openModal } from "../../store/modal/modalReducer";
import axios from "axios";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextarea from "../Forms/SettingsTextarea";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import { toast } from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import { getBooking } from "../../store/booking/bookingActions";

export default function ModalSinglePayment(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [loading, setLoading] = useState(false);
  const { current_booking } = useSelector((state) => state.booking);

  useEffect(async () => {
    setLoading(true);
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <CustomButton
                    type="secondary"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    Close
                  </CustomButton>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      Make A Payment
                    </Dialog.Title>

                    <Formik
                      initialValues={{ reason: null, amount: null }}
                      enableReinitialize="true"
                      validate={(values) => {
                        const errors = {};
                        if (!values.reason) {
                          errors.reason = "Reason is Required";
                        }

                        if (!values.amount) {
                          errors.amount = "Amount is Required";
                        }

                        return errors;
                      }}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setSubmitting(true);

                        values.user_id = current_booking.user.id;

                        console.log(values);

                        await axios({
                          method: "post",
                          url: `${process.env.REACT_APP_API_URL}send_money`,
                          data: values,
                        })
                          .then((response) => {
                            if (response.data[0] === "PAYMENT FAILED") {
                              toast(
                                <NotificationToast
                                  title={"Payment Failed"}
                                  message={response.data[1]}
                                  list={true}
                                  type="error"
                                />
                              );
                            } else {
                              // toast(
                              //   <NotificationToast
                              //     title={"Payment successful"}
                              //     message={"Your Payment has been queued."}
                              //   />
                              // );
                              // props.setFetchPayments(true)
                              // dispatch(closeModal());
                              dispatch(
                                openModal({
                                  modal_type: "ModalOTPPayment",
                                  modal_props: {
                                    setFetchPayments: props.setFetchPayments,
                                  },
                                })
                              );
                            }
                          })
                          .catch((error) => {
                            alert("Payment failed");
                          });

                        setLoading(false);
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="space-y-8 m-4 w-full "
                        >
                          <div>
                            <Field
                              name="reason"
                              type="text"
                              as={SettingsTextarea}
                              placeholder="Reason"
                            />
                            {errors.reason && touched.reason && errors.reason}
                            <br />
                            <br />

                            <label
                              htmlFor="amount"
                              className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between"
                            >
                              Amount in Rands
                            </label>
                            <NumberFormat
                              thousandSeparator={false}
                              allowNegative={false}
                              name="amount"
                              onChange={(value) =>
                                setFieldValue("amount", value.target.value)
                              }
                              placeholder="Amount in Rands"
                              className="rounded-lg block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                            />
                            {errors.amount && touched.amount && errors.amount}
                          </div>

                          <div className="flex space-x-6">
                            <SubmitButton
                              name="Pay"
                              disabled={!isValid || isSubmitting || !dirty}
                              isSubmitting={isSubmitting}
                              text="Pay"
                            />

                            <CustomButton
                              type="secondary"
                              text="Close"
                              onClick={() => dispatch(closeModal())}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
