import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomButton from "../Helpers/CustomButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { openModal } from "../../store/modal/modalReducer";
export default function ModalViewPrevBooking({ booking, bookingFilesFirst }) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [loading, setLoading] = useState(false);
  const [bookingFiles, setBookingFiles] = useState(false);
  const { current_booking } = useSelector((state) => state.booking);

  useEffect(async () => {
    let filesList = [];
    Object.keys(bookingFilesFirst).forEach((key) => {
      if (bookingFilesFirst[key].booking_id_old === booking.id) {
        filesList.push(bookingFilesFirst[key]);
      }
    });

    setBookingFiles(filesList);
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={regular("times")}
                    />
                  </button>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      Previous Booking: {booking.booking_reference}
                    </Dialog.Title>

                    <br />
                    <p>
                      <span className="font-bold">Booking Location:</span>{" "}
                      {booking?.property?.name}, {booking?.unit_type?.name},
                      Unit {booking?.unit?.name} {booking?.bed?.name}
                    </p>
                    <br />

                    <p>
                      <span className="font-bold">Property:</span>{" "}
                      {booking?.property?.name}
                    </p>
                    <p>
                      <span className="font-bold">Unit Type:</span>{" "}
                      {booking?.unit_type?.name}
                    </p>
                    <p>
                      <span className="font-bold">Unit Name:</span>{" "}
                      {booking?.unit?.name}
                    </p>
                    <p>
                      <span className="font-bold">Bed:</span>{" "}
                      {booking?.bed?.name}
                    </p>

                    <br />

                    <p>
                      <span className="font-bold">Monthly Rental:</span> R
                      {
                        booking?.rates[booking?.rates.length - 1]
                          ?.monthly_rental
                      }
                    </p>

                    <br />

                    <div className="flex space-x-4">
                      <div className="w-1/2">
                        <p>
                          <span className="font-bold">Deposit:</span> R
                          {
                            booking?.rates[booking?.rates.length - 1]
                              ?.deposit_price
                          }
                        </p>
                        <p>
                          <span className="font-bold">Deposit Paid:</span>{" "}
                          {booking?.rates[booking?.rates.length - 1]
                            ?.deposit_paid !== 0
                            ? "Yes"
                            : "No"}
                        </p>
                        <p>
                          <span className="font-bold">Deposit Paid At:</span>{" "}
                          {
                            booking?.rates[booking?.rates.length - 1]
                              ?.deposit_paid_at
                          }
                        </p>

                        <br />

                        <p>
                          <span className="font-bold">Lease Signed:</span>{" "}
                          {booking?.rates[booking?.rates.length - 1]
                            ?.signed_at !== null
                            ? "Yes"
                            : "No"}
                        </p>
                        <p>
                          <span className="font-bold">Lease Signed At:</span>{" "}
                          {
                            booking?.rates[booking?.rates.length - 1]
                              ?.signed_lease_at
                          }
                        </p>

                        <br />

                        <p>
                          <span className="font-bold">MDA Lease ID:</span> R
                          {
                            booking?.rates[booking?.rates.length - 1]
                              ?.mda_lease_id
                          }
                        </p>
                        <p>
                          <span className="font-bold">
                            MDA Lease Linked At:
                          </span>{" "}
                          R
                          {
                            booking?.rates[booking?.rates.length - 1]
                              ?.mda_lease_linked_at
                          }
                        </p>

                        {process.env.REACT_APP_COLOUR === "QL" ? (
                          <>
                            <br />

                            <p>
                              <span className="font-bold">Admin Fee:</span> R
                              {
                                booking?.rates[booking?.rates.length - 1]
                                  ?.admin_fee
                              }
                            </p>
                            <p>
                              <span className="font-bold">Refuse:</span> R
                              {
                                booking?.rates[booking?.rates.length - 1]
                                  ?.refuse
                              }
                            </p>
                            <p>
                              <span className="font-bold">Effluent:</span> R
                              {
                                booking?.rates[booking?.rates.length - 1]
                                  ?.effluent
                              }
                            </p>
                            <p>
                              <span className="font-bold">Key Deposit:</span> R
                              {
                                booking?.rates[booking?.rates.length - 1]
                                  ?.key_deposit
                              }
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="w-1/2">
                        <p>
                          <span className="font-bold">Booking Fee:</span> R
                          {
                            booking?.rates[booking?.rates.length - 1]
                              ?.booking_fee_price
                          }
                        </p>
                        <p>
                          <span className="font-bold">Booking Fee Paid:</span>{" "}
                          {booking?.rates[booking?.rates.length - 1]?.paid !==
                          null
                            ? "Yes"
                            : "No"}
                        </p>
                        <p>
                          <span className="font-bold">
                            Booking Fee Paid At:
                          </span>{" "}
                          {booking?.rates[booking?.rates.length - 1]?.paid_at}
                        </p>

                        <br />

                        <p>
                          <span className="font-bold">Lease From:</span>{" "}
                          {
                            booking?.rates[booking?.rates.length - 1]
                              ?.lease_start
                          }
                        </p>
                        <p>
                          <span className="font-bold">Lease To:</span>{" "}
                          {booking?.rates[booking?.rates.length - 1]?.lease_end}
                        </p>

                        {process.env.REACT_APP_COLOUR === "QL" ? (
                          <>
                            <br />

                            <p>
                              <span className="font-bold">Deposit Waiver:</span>{" "}
                              {booking?.rates[booking?.rates.length - 1]
                                ?.lease_surance !== null
                                ? "Yes"
                                : "No"}
                            </p>
                            <p>
                              <span className="font-bold">
                                Deposit Waiver Fee:
                              </span>{" "}
                              R
                              {
                                booking?.rates[booking?.rates.length - 1]
                                  ?.surance_amount
                              }
                            </p>

                            <br />

                            <p>
                              <span className="font-bold">Deduction:</span> R
                              {
                                booking?.rates[booking?.rates.length - 1]
                                  ?.special_deduction
                              }
                            </p>
                            <p>
                              <span className="font-bold">Carport:</span>{" "}
                              {
                                booking?.rates[booking?.rates.length - 1]
                                  ?.carport
                              }
                            </p>
                            <p>
                              <span className="font-bold">Parking Fee:</span> R
                              {
                                booking?.rates[booking?.rates.length - 1]
                                  ?.parking_fee
                              }
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <br />
                    <p>
                      <span className="font-bold">Files:</span>
                    </p>

                    {bookingFiles.length > 0 ? (
                      <>
                        <CustomButton
                          text="View All Files"
                          styling="my-2"
                          onClick={() =>
                            dispatch(
                              openModal({
                                modal_type: "ModalViewFiles",
                                modal_props: {
                                  files: bookingFiles,
                                },
                              })
                            )
                          }
                        />
                      </>
                    ) : (
                      ""
                    )}

                    {bookingFiles &&
                      bookingFiles.length > 0 &&
                      bookingFiles.map((file) => (
                        <>
                          <p>{file.name}</p>
                        </>
                      ))}

                    <div className="flex flex-shrink-0 space-x-4 justify-end px-4 py-4">
                      <CustomButton
                        type="button"
                        onClick={() => dispatch(closeModal())}
                        text="Close"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
