import React, { useEffect, useState } from "react";
import PaymentForm from "./PaymentForm";
import ApplicationHeaderUC from "./ApplicationHeaderUC";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function PayFastPageUC() {
    const { url } = useParams();
    const [booking, setBooking] = useState({});
    const [paymentTotal, setPaymentTotal] = useState(0);
    const [paymentMade, setPaymentMade] = useState(0);

    useEffect(async () => {
        if (url !== "" && !booking?.id) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    setBooking(response.data);
                    setPaymentTotal(response.data.rates[0].booking_fee_price);
                    setPaymentMade(response.data.rates[0].paid == 1 ? 1 : 0);
                })
                .catch((error) => {
                    console.log(error);
                    // window.location.href = "/404";
                });
        }
    }, [booking]);

    return (
        <div className="uc">
            <ApplicationHeaderUC />

            <main className="-mt-32">
                <div>
                    <div className="bg-white min-h-screen shadow-md rounded-tl-3xl white_block_left">
                        <div className="h-20 flex">
                            <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                <div className="text-white text-2xl font-bold uppercase">Please Pay your booking Fee</div>
                            </div>
                            <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                        </div>

                        <div className="mx-auto bg-white max-w-7xl">
                            <main className="mt-14">
                                <div className="max-w-7xl mx-auto pb-2 px-4">
                                    <div className="w-full">
                                        {/* check if paid? */}

                                        {paymentMade === 0 ? (
                                            <>
                                                <div className="max-w-5xl m-auto">
                                                    <div className="text-white h-14 rounded-full px-7 flex justify-start items-center" style={{ backgroundColor: "red" }}>
                                                        Payment will secure your bed once your lease is signed
                                                    </div>

                                                    <div className="md:flex md:justify-between">
                                                        <div className="md:w-1/2">
                                                            <div className="rounded-3xl p-5 mt-7" style={{ backgroundColor: "#e8e8e8" }}>
                                                                <p className="text-xs text-gray-500">BOOKING SUMMARY</p>
                                                                <p className="text-sm">{booking?.property?.name}</p>
                                                                <p className="text-3xl my-2">
                                                                    {booking?.unit?.name} {booking?.bed?.name}
                                                                </p>
                                                                <p className="text-sm">{booking?.unit?.floor?.name}</p>
                                                                <p className="text-sm">{booking?.unit_type?.name}</p>
                                                            </div>
                                                        </div>

                                                        <div className="md:w-1/3 md:pl-9">
                                                            <div className="mt-10"></div>
                                                            <div className="rounded-3xl p-5 mb-4" style={{ backgroundColor: "#e8e8e8" }}>
                                                                {booking && booking.rates != undefined && booking?.rates[0].booking_fee_price > 0 && (
                                                                    <>
                                                                        <p className="text-xs text-gray-500 mt-4">Booking Fee (Refundable)</p>
                                                                        <div className="flex justify-between">
                                                                            <p className="text-3xl">R {booking?.rates[0].booking_fee_price}</p>
                                                                        </div>
                                                                        <p className="text-xs">
                                                                            {booking?.student_type == 3
                                                                                ? booking?.unit_type?.private_booking_fee_desc
                                                                                : booking?.student_type == 2
                                                                                ? booking?.unit_type?.bursary_booking_fee_desc
                                                                                : booking?.unit_type?.nsfas_booking_fee_desc}
                                                                        </p>
                                                                    </>
                                                                )}

                                                                <div className="text-3xl mt-4">
                                                                    Total: <strong>R {paymentTotal}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-7">
                                                        <div>
                                                            <form
                                                                action={
                                                                    window.location.hostname === "localhost" ? "https://sandbox.payfast.co.za/eng/process" : "https://www.payfast.co.za/eng/process"
                                                                }
                                                                method="POST"
                                                            >
                                                                {window.location.hostname === "localhost" ? (
                                                                    <>
                                                                        <p>TEST MODE!</p>
                                                                        <input type="hidden" name="merchant_id" value="10003863" />
                                                                        <input type="hidden" name="merchant_key" value="mi4hkujnjc9j5" />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {/* SH */}
                                                                        {booking?.property_id === 33 ? (
                                                                            <>
                                                                                <input type="hidden" name="merchant_id" value="16529088" />
                                                                                <input type="hidden" name="merchant_key" value="cmbrnjrnr0rp3" />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <input type="hidden" name="merchant_id" value="16528399" />
                                                                                <input type="hidden" name="merchant_key" value="ox97yhyn6u2ps" />
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}

                                                                <input type="hidden" name="name_first" value={booking?.user?.name} />
                                                                <input type="hidden" name="name_last" value={booking?.user?.surname} />
                                                                <input type="hidden" name="email_address" value={booking?.user?.email} />
                                                                <input type="hidden" name="cell_number" value={booking?.user?.cell} />

                                                                <input type="hidden" name="m_payment_id" value={booking?.id} />
                                                                <input type="hidden" name="amount" value={paymentTotal} />
                                                                <input type="hidden" name="item_name" value="Deposit" />
                                                                <input
                                                                    type="hidden"
                                                                    name="item_description"
                                                                    value={booking?.property?.name + ", " + booking?.unit?.floor?.name + ", " + booking?.unit?.name}
                                                                />

                                                                <input type="hidden" name="email_confirmation" value="1" />

                                                                {window.location.hostname === "localhost" ? (
                                                                    <input type="hidden" name="confirmation_address" value="roger@whitehart.co.za" />
                                                                ) : (
                                                                    <input type="hidden" name="confirmation_address" value="Mfundo@qholdings.co.za" />
                                                                )}

                                                                <input type="hidden" name="custom_int1" value={booking?.user?.id} />
                                                                <input type="hidden" name="custom_int2" value={booking?.id} />
                                                                <input type="hidden" name="custom_int3" value={booking?.rates && booking?.rates?.length > 0 ? booking?.rates[0].id : 0} />

                                                                <input type="hidden" name="return_url" value={window.location.href} />
                                                                <input type="hidden" name="cancel_url" value={window.location.href} />
                                                                <input type="hidden" name="notify_url" value={process.env.REACT_APP_API_URL + "payfast"} />

                                                                {paymentTotal > 0 ? (
                                                                    <input
                                                                        type="submit"
                                                                        className="my-4 w-full relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                                                        value="Make Payment via Payfast"
                                                                    />
                                                                ) : (
                                                                    <p>Please choose what you would like to pay on the right</p>
                                                                )}
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <p>Thank you for paying the Booking Fee.</p>
                                        )}
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
