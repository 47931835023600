import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
// import { doc } from "firebase/firestore";

import { openModal } from "../../store/modal/modalReducer";
import NotificationToast from "../Helpers/NotificationToast";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import { getBooking } from "../../store/booking/bookingActions";
import QuickFormText from "../Forms/QuickFormText";
import QuickFormSelect from "../Forms/QuickFormSelect";
import AppLoader from "../Helpers/AppLoader";
import moment from "moment/moment";

export default function GridPayments({ setDoc, db }) {
    const [loading, setLoading] = useState(true);
    const { current_staff } = useSelector((state) => state.auth);
    const { current_booking } = useSelector((state) => state.booking);
    const [banks, setBanks] = useState([]);
    const [allPayments, setAllPayments] = useState([]);
    const [fetchPayments, setFetchPayments] = useState(true);
    const dispatch = useDispatch();
    const [paymentFiles, setPaymentFiles] = useState([]);
    const [refunds, setRefunds] = useState([]);

    useEffect(async () => {
        if (banks.length === 0) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}paystack_banks`,
            }).then((response) => {
                let bank_data = response.data;

                if (bank_data.status === true) {
                    setBanks(bank_data.data);
                }
            });
        }

        if (fetchPayments === true) {
            setFetchPayments(false);

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}get_payments/${current_booking?.user.id}`,
            }).then((response) => {
                setAllPayments(response.data);
            });
        }

        if (refunds.length === 0) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}get_refunds/${current_booking?.user.id}`,
            }).then((response) => {
                setRefunds(response.data);
            });
        }

        let Payments = current_booking?.user?.user_payment;
        Payments.sort((a, b) => (moment(a.created_at) > moment(b.created_at) ? -1 : 1));

        setAllPayments(Payments);
    }, [fetchPayments]);

    async function new_recipient() {
        if (current_booking?.user.recipient_code === null) {
            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}create_recipient`,
                data: { user_id: current_booking.user.id },
            })
                .then((response) => {
                    if (response.data[0] === "PAYMENT FAILED") {
                        toast(<NotificationToast title={"Receipient Failed"} message={response.data[1]} list={true} type="error" />);
                    } else {
                        dispatch(openModal({ modal_type: "ModalSinglePayment", modal_props: { setFetchPayments: setFetchPayments } }));
                    }
                })
                .catch((error) => {
                    alert("Recipient failed");
                });
        } else {
            dispatch(openModal({ modal_type: "ModalSinglePayment", modal_props: { setFetchPayments: setFetchPayments } }));
        }
    }

    return (
        <div className="h-96 overflow-y-scroll scroll-smooth">
            <button
                className="relative justify-center inline-flex text-white uppercase items-center px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 "
                onClick={() =>
                    dispatch(
                        openModal({
                            modal_type: "ModalRefund",
                            modal_props: {
                                current_booking: current_booking,
                            },
                        })
                    )
                }
            >
                Internal Refund Checklist
            </button>

            <div className="mt-4">Refunds:</div>
            {refunds?.map((refund) => {
                return (
                    <>
                        <div
                            className="border-2 w-full rounded px-1 py-1 my-2 cursor-pointer hover:-translate-y-0.5 hover:bg-dark_background hover:text-white"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modal_type: "ModalViewFiles",
                                        modal_props: {
                                            files: [
                                                {
                                                    id: refund.file_id,
                                                },
                                            ],
                                        },
                                    })
                                )
                            }
                        >
                            <p>{moment(refund.created_at).format("yyyy-MM-DD HH:mm")}</p>
                        </div>
                    </>
                );
            })}

            {current_booking?.files && current_booking?.files.length > 0 ? (
                <>
                    {current_booking?.files.map((file) => (
                        <>
                            {file.file_type == "Payment" ? (
                                <>
                                    <div
                                        className="border-2 w-full rounded px-1 py-1 my-2 cursor-pointer hover:-translate-y-0.5 hover:bg-dark_background hover:text-white"
                                        onClick={() =>
                                            dispatch(
                                                openModal({
                                                    modal_type: "ModalViewFiles",
                                                    modal_props: {
                                                        files: [
                                                            {
                                                                id: file.id,
                                                            },
                                                        ],
                                                    },
                                                })
                                            )
                                        }
                                    >
                                        <p className="text-center">{file.name}</p>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </>
                    ))}
                </>
            ) : (
                ""
            )}
            <hr />
            {current_booking?.user?.profile?.banking_account_number && current_booking?.user?.profile?.banking_bank_code ? (
                <>
                    <br />
                    <p>
                        <b>Bank:</b> {current_booking?.user?.profile?.banking_bank}
                    </p>
                    <p>
                        <b>Account Type:</b> {current_booking?.user?.profile?.banking_account_type}
                    </p>
                    <p>
                        <b>Branch:</b> {current_booking?.user?.profile?.banking_branch}
                    </p>
                    <p>
                        <b>Branch Code:</b> {current_booking?.user?.profile?.banking_branch_code}
                    </p>
                    <p>
                        <b>Account Number:</b> {current_booking?.user?.profile?.banking_account_number}
                    </p>
                    <p>
                        <b>Account Holder:</b> {current_booking?.user?.profile?.banking_account_holder}
                    </p>
                    <br />
                    <hr />
                    <br />
                    <p>List of Payments</p>
                    {(process.env.REACT_APP_COLOUR === "UC" && (current_staff.id == "291" || current_staff.id == "1" || current_staff.id == "14102")) ||
                    (process.env.REACT_APP_COLOUR === "QL" && (current_staff.id == "294" || current_staff.id == "297" || current_staff.id == "291" || current_staff.id == "8684")) ? (
                        <>
                            <CustomButton text={"Add Payment"} styling="w-full mt-2" icon={regular("user-plus")} onClick={() => new_recipient()} loadingtext="Loading..." />
                        </>
                    ) : (
                        ""
                    )}
                </>
            ) : (
                "Bank Information Not Loaded"
            )}
            {allPayments &&
                allPayments.length > 0 &&
                allPayments.map((payment) => {
                    return (
                        <>
                            <div className="border-2 w-full rounded px-1 py-1 my-2">
                                {payment.transaction_result !== null ? (
                                    JSON.parse(payment.transaction_result).status === true ? (
                                        <p className="text-center text-green-600">Payment Successful</p>
                                    ) : (
                                        <p className="text-center text-red-600">Payment Failed - {JSON.parse(payment.transaction_result).message}</p>
                                    )
                                ) : (
                                    <p className="text-center text-red-600">Payment Failed - Payment Abandoned</p>
                                )}
                                <p className="text-center">{moment(payment.created_at).format("DD MMMM yyyy HH:mm")}</p>
                                <p className="text-center">{payment.name}</p>
                                <p className="text-center font-bold">R {(parseFloat(payment.amount) / 100).toFixed(2)}</p>
                                <p className="text-center">Reason: {payment.reason}</p>
                            </div>
                        </>
                    );
                })}
        </div>
    );
}
