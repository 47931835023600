import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import AppLoader from "../Helpers/AppLoader";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import ApplicationHeaderUC from "./ApplicationHeaderUC";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
import PhoneInput from "react-phone-number-input";
import SubmitButton from "../Forms/SubmitButton";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";

export default function NOKFormUC({ showbtn }) {
    const dispatch = useDispatch();
    const { url } = useParams();
    const [loading, setLoading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [booking, setBooking] = useState({});
    const [paymentMade, setPaymentMade] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [chosenPayment, setChosenPayment] = useState(1);
    const [paymentTotal, setPaymentTotal] = useState(0);

    const [initialValues, setInitialValues] = useState({
        kin_title: "",
        kin_name: "",
        kin_surname: "",
        kin_relationship: "",
        kin_contact: "",
        kin_email: "",
        kin_address: "",
        kin_id_number: "",
        kin_suburb: "",
        kin_postal: "",
        kin_city: "",
        kin_tel_contact: "",
        kin_work_contact: "",
        user_id: 0,
    });

    useEffect(async () => {
        if (url !== "" && !booking?.id) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    setBooking(response.data);

                    setInitialValues({
                        kin_title: response.data?.user?.next_of_kin?.title ?? "",
                        kin_name: response.data?.user?.next_of_kin?.name ?? "",
                        kin_surname: response.data?.user?.next_of_kin?.surname ?? "",
                        kin_relationship: response.data?.user?.next_of_kin?.relationship ?? "",
                        kin_contact: response.data?.user?.next_of_kin?.contact ?? "",
                        kin_email: response.data?.user?.next_of_kin?.email ?? "",
                        kin_address: response.data?.user?.next_of_kin?.address ?? "",
                        kin_id_number: response.data?.user?.next_of_kin?.id_number ?? "",
                        kin_suburb: response.data?.user?.next_of_kin?.suburb ?? "",
                        kin_postal: response.data?.user?.next_of_kin?.postal ?? "",
                        kin_city: response.data?.user?.next_of_kin?.city ?? "",
                        kin_tel_contact: response.data?.user?.next_of_kin?.tel_contact ?? "",
                        kin_work_contact: response.data?.user?.next_of_kin?.work_contact ?? "",
                        user_id: response.data?.user?.id ?? 0,
                        booking_id: response.data?.id ?? 0,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    // window.location.href = "/404";
                });
        }
    }, [booking]);

    console.log(booking?.user);

    if (loading === true) return <AppLoader />;

    return (
        <div className="uc">
            <ApplicationHeaderUC />

            <main className="-mt-32">
                <div>
                    <div className="bg-white min-h-screen shadow-md rounded-tl-3xl white_block_left">
                        <div className="h-20 flex">
                            <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                <div className="text-white text-2xl font-bold uppercase">Update your Next of Kin details</div>
                            </div>
                            <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                        </div>

                        <div className="mx-auto bg-white max-w-7xl">
                            <main className="mt-14">
                                <div className="max-w-7xl mx-auto pb-2 px-4">
                                    <div className="w-full">
                                        <div className="max-w-5xl m-auto">
                                            <Formik
                                                initialValues={initialValues}
                                                enableReinitialize="true"
                                                validateOnChange={true}
                                                validateOnBlur={true}
                                                validationSchema={Yup.object().shape({
                                                    kin_title: Yup.string().required("next of kin title"),
                                                    kin_name: Yup.string().required("next of kin name"),
                                                    kin_surname: Yup.string().required("next of kin surname"),
                                                    kin_address: Yup.string().required("next of kin address"),
                                                    kin_suburb: Yup.string().required("next of kin suburb"),
                                                    kin_city: Yup.string().required("next of kin city"),
                                                    kin_postal: Yup.string().required("next of kin postal code"),
                                                    kin_relationship: Yup.string().required("relationship with your next of kin "),

                                                    kin_contact: Yup.string()
                                                        .required("next of kin cell number")
                                                        .notOneOf([Yup.ref("cell"), null], "cell and the next of kin cell to not match"),

                                                    kin_work_contact: Yup.string().required("next of kin work number"),
                                                    kin_email: Yup.string().required("next of kin email"),
                                                })}
                                                validateOnMount
                                                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                    setSubmitting(true);

                                                    values.kin_contact = values.kin_contact && values.kin_contact.replace("+", "");
                                                    values.kin_work_contact = values.kin_work_contact && values.kin_work_contact.replace("+", "");

                                                    await axios({
                                                        method: "post",
                                                        url: `${process.env.REACT_APP_API_URL}nok_save`,
                                                        data: values,
                                                    })
                                                        .then((response) => {
                                                            console.log(response);
                                                            if (url && url != "0") {
                                                                if (response.data === "done") {
                                                                    toast(
                                                                        <NotificationToast
                                                                            title={"Application Updated"}
                                                                            message="Your application has succesfully been updated. Keep an eye out on your email and cellphone for future communication"
                                                                        />
                                                                    );
                                                                } else {
                                                                    <NotificationToast title={"Application Error"} message={response.data} />;
                                                                }
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            toast(
                                                                <NotificationToast
                                                                    title={"Application Error"}
                                                                    message="An error occurred while trying to save your application. Please try again."
                                                                    type="error"
                                                                />
                                                            );
                                                        });
                                                }}
                                            >
                                                {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                            <SettingsSelect
                                                                label="Title"
                                                                name="kin_title"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.kin_title}
                                                                touched={touched.kin_title}
                                                                error={errors.kin_title}
                                                                options="titles"
                                                                required
                                                            />
                                                            <Field name="kin_name" type="text" as={SettingsTextInput} label="Name" required />
                                                            <Field name="kin_surname" type="text" as={SettingsTextInput} label="Surname" required />
                                                            <Field name="kin_address" type="text" as={SettingsTextInput} label="Address" required />
                                                            <Field name="kin_suburb" type="text" as={SettingsTextInput} label="Suburb" required />
                                                            <Field name="kin_city" type="text" as={SettingsTextInput} label="City" required />
                                                            <Field name="kin_postal" type="text" as={SettingsTextInput} label="Postal Code" required />
                                                            <Field name="kin_relationship" type="text" as={SettingsTextInput} label="Relationship" required />
                                                            <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                <label htmlFor="kin_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Cellphone Number</span>
                                                                    {errors.kin_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                                </label>
                                                                <div className="mt-1">
                                                                    <PhoneInput
                                                                        defaultCountry="ZA"
                                                                        countryCallingCodeEditable={false}
                                                                        onChange={(val) => {
                                                                            setFieldValue("kin_contact", val);
                                                                        }}
                                                                        onBlur={setFieldTouched}
                                                                        name="kin_contact"
                                                                        value={values.kin_contact}
                                                                    />
                                                                    <p className="text-red-400 text-sm font-light">{errors.kin_contact}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                <label htmlFor="kin_work_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Work Number</span>
                                                                    {errors.kin_work_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                                </label>
                                                                <div className="mt-1">
                                                                    <PhoneInput
                                                                        defaultCountry="ZA"
                                                                        countryCallingCodeEditable={false}
                                                                        onChange={(val) => {
                                                                            setFieldValue("kin_work_contact", val);
                                                                        }}
                                                                        onBlur={setFieldTouched}
                                                                        name="kin_work_contact"
                                                                        value={values.kin_work_contact}
                                                                    />
                                                                    <p className="text-red-400 text-sm font-light">{errors.kin_work_contact}</p>
                                                                </div>
                                                            </div>
                                                            {/* <Field name="kin_contact" type="text" as={SettingsTextInput} label="Cellphone Number" prepend="+" required /> */}
                                                            {/* <Field name="kin_work_contact" type="text" as={SettingsTextInput} label="Work Number" prepend="+" required /> */}
                                                            <Field name="kin_email" type="email" as={SettingsTextInput} label="Email" required />
                                                        </div>

                                                        <SubmitButton
                                                            name="Save"
                                                            disabled={!isValid || isSubmitting || !dirty}
                                                            isSubmitting={isSubmitting}
                                                            text={url && url != "0" ? "Save" : "Submit Application"}
                                                            style={{
                                                                fontWeight: 700,
                                                                borderRadius: 20,
                                                                marginBottom: 20,
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
