import React from "react";
import { useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function SubmitButton({ type, isSubmitting, text, ...props }) {
    const [field, meta] = useField(props);

    return (
        <button
            {...field}
            {...props}
            type="submit"
            disabled={isSubmitting === true}
            className="relative justify-center inline-flex text-white uppercase items-center px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 "
        >
            {isSubmitting === true ? (
                <FontAwesomeIcon className="animate-spin" icon={regular("spinner-third")} />
            ) : (
                <>
                    {props.icon && (
                        <span className="w-4">
                            <FontAwesomeIcon className="w-4 h-4" icon={props.icon} />
                        </span>
                    )}
                    <span className="w-full">{text}</span>
                </>
            )}
        </button>
    );
}
